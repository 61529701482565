import React, { Component, createRef, useContext } from "react"
import ReactSlidingPane from "../../components/bottom-slider/ReactSlidingPane"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import "../../css/Home.scss"
import "../../css/newsCarousal.scss"
import Input from "react-phone-number-input/input"
import { Link } from "react-router-dom"
import { baseUrl, loginUrl } from "../../lib/constants"
import { checkdevice } from "../../utils/checkdevice"
import { ClientAccessId } from "../../components/popups/accessCode/ClientAccessId"
import Header from "../../components/navbar/Header"
import TestimonialsCarousel from "./TestimonialsCarousel"
import { withTranslation } from "react-i18next"
// import "../css/animate.scss";
import { NewsCarousel } from "./NewsCarousel"
import { HashLink } from "react-router-hash-link"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"
import { logEvent } from "../../index"
import axios from "axios"
import Footer from "../../components/footer/Footer"
import { getWhetherShowedMobileAppLinks } from "../../utils/displayMobileAppLinks"
import { isAndroid, isIOS } from "react-device-detect"
import MeridianPopup from '../../components/form/MeridianPopup';
import StartUpPopup from '../../components/form/StartUpPopup';
import AssessmentGap from "../../components/popups/accessCode/FollowUpGap"
import { StateContext } from "../../store/state/contexts"
import moment from 'moment';
import LoginPopup from "../../components/popups/accessCode/LoginPopup";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input"
import '../..'
var store = require("store")

const lngs = {
  en: { nativeName: "En" },
  fr: { nativeName: "Fr" },
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.modelRef = createRef()
    this.state = {
      auth: false,
      showClientAccessPopUp: false,
      inProgress: false,
      email: '',
      error: '',
      loading: false,
      success: false,
      hasClient: false,
      mainLoader: true,
      showMeridinPopup: false,
      showStartupPopup: false,
      showAssessmentGapPopup: false,
      name: "",
      phone: "",
      company: "",
      position: "",
      interest: "",
      message: "",
      showItems: 5,
      isContactValid: true,
    }

  }

  setPhone = (e) => {
    this.setState({ phone: e })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  setshowMeridinPopup = (flag) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ showMeridinPopup: flag });
  }

  handleCloseMeridianPopup = () => {
    this.setshowMeridinPopup(false);
  }

  setshowStartUpPopup = (flag) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ showStartupPopup: flag });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const { name, email, phone, company, position, interest, message } =
      this.state
    if (
      !name ||
      !email ||
      !phone ||
      !company ||
      !position ||
      !interest ||
      !message
    ) {
      return this.setState({ error: "All fields are required.", success: "" })
    }
    this.setState({ error: "", success: "" })

    if (!this.validateEmail(email)) {
      return this.setState({ error: "Please Enter a valid email address." })
    }
    if (!isValidPhoneNumber(phone)) {
      return this.setState({ isContactValid: false })
    }
    this.setState({ isContactValid: true })

    this.setState({ loading: true, error: "", success: "" })
    try {
      const response = await axios.post(`${baseUrl}/email/contact`, {
        name,
        email,
        phone,
        company,
        position,
        interest,
        message,
      })
      this.setState({
        success: response?.data?.message || "Message sent",
        loading: false,
        name: "",
        email: "",
        phone: "",
        company: "",
        position: "",
        interest: "",
        message: "",
        error: "",
      })
    } catch (error) {
      console.error(error)
      this.setState({
        error: error?.response?.data?.message || error.message,
        loading: false,
        success: "",
      })
    }
  }

  handleCloseStartUpPopup = () => {
    this.setshowStartUpPopup(false);
  }

  setshowAssessmentGapPopup = (flag) => {
    this.setState({
      showAssessmentGapPopup: flag
    })
  }

  closeAssessmentGapPopup = () => {
    this.setshowAssessmentGapPopup(false)
  }


  recordHits = () => {
    axios.post(`${baseUrl}/web-visit`)
  }

  componentDidMount() {
    if (!localStorage.getItem('show_startup_popup')) {
      this.setState({ showStartupPopup: true })
      localStorage.setItem('show_startup_popup', 'true')
    }
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const nonce = urlParams.get("code")
    if (nonce === "clinadvance")
      window.open("https://feelingbetternow.com/", "_self")
    checkdevice(this.props)
    let promises = [
      // this.checkIsUserAuthorized(),
      // this.checkAssessmentInProgress()
    ]

    this.recordHits()

    Promise.all(promises).then(() => {
      this.setState({ mainLoader: false })
    })
      .catch(error => {
        this.setState({ mainLoader: false })
      })
    this.props.i18n.changeLanguage(store.get('lang') || 'en')
    // if (!getWhetherShowedMobileAppLinks() && (isAndroid || isIOS)) {
    //   window.location.replace(`/DownloadApp`)
    // }
  }

  // useEffect(() => {
  //   if (!getWhetherShowedMobileAppLinks() && (isAndroid || isIOS)) {
  //     window.location.replace(`https://mensante-assessment.ca/DownloadApp`)
  //   }
  // }, [])

  logOut = () => {
    fetch(baseUrl + "/logout", { credentials: "include", mode: "cors" }).then(
      (response) => {
        if (response) {
          this.setState({ auth: false })
          this.props.history.push("/")
          logEvent("logOut /logOut successful")
        } else logEvent("logOut /logout failed")
      }
    ).catch((err) => {
      console.log("Home.jsx", err);
    })
  }

  checkAssessmentInProgress = async () => {
    fetch(baseUrl + "/assessment/inprogress", {
      credentials: "include",
      mode: "cors",
      method: "GET",
    }).then((res) => res.json())
      .then(res => {
        if (res == "") {
          this.setState({ inProgress: false })
          logEvent("checkAssessmentInProgress /assessment/inprogress failed")
        } else {
          this.setState({ inProgress: true })
          logEvent("checkAssessmentInProgress /assessment/inprogress successful")
        }
      })
      .catch((err) => {
        console.log("Home.jsx", err);
      })
  }

  checkIsUserAuthorized() {
    return fetch(baseUrl, { credentials: "include", mode: "cors" })
      .then((response) => {
        logEvent("checkIsUserAuthorized / successful")
        if (response.status === 200) {
          this.setState({ auth: false })
          // this.checkClientID()
        } else this.setState({ auth: false })
      })
      .catch((err) => {
        console.log("Home.jsx", err);
        logEvent("checkIsUserAuthorized / failed")
        this.setState({ auth: false })
      })
  }

  handleClosePopup = () => {
    this.setState({ showClientAccessPopUp: false })
  }

  checkClientID = async () => {
    try {
      const res = await fetch(baseUrl + "/user/accesscode", {
        credentials: "include",
        mode: "cors",
        method: "GET",
      })
      const assementCode = await res.json()
      if (!assementCode || (typeof assementCode === 'object' && Object.keys(assementCode).length === 0)) {
        logEvent("checkClientID /user/accesscode successful")
        this.setState({ showClientAccessPopUp: true, hasClient: false })
      } else {
        this.setState({ hasClient: true })
        logEvent("checkClientID /user/accesscode failed")
      }
    } catch (err) {
      console.log("Home.jsx", err);
    }

  }

  submitNewsletter = async (e) => {
    e.preventDefault()
    if (!this.state.email) {
      return this.setState({ error: 'Please Enter Email', success: '' })
    }
    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegexp.test(this.state.email)) {
      return this.setState({ error: 'Please Enter Valid Email' })
    }
    this.setState({ loading: true, error: '', success: '' });
    try {
      let response = await axios.post(`${baseUrl}/email/newsletter`, { email: this.state.email });
      response = response.data;
      this.setState({ success: response?.message || 'Successfully subscribed to our newsletter', loading: false, error: '', email: '' });
    } catch (error) {
      console.log(error, ' <=== newsletter error...');
      this.setState({ error: error?.response?.data?.message || error.message, loading: false, success: '', email: '' });
    }
  }

  showClientPopUp = () => {
    this.setState({ showClientAccessPopUp: true })
  }

  setshowLoginPopup = (flag) => {
    this.setState({ showLoginPopup: flag })
  }
  closeLoginPopup = () => {
    this.setshowLoginPopup(false)
  }

  render() {
    const { auth, showClientAccessPopUp, mainLoader, showMeridinPopup, showStartupPopup, showLoginPopup } = this.state;
    const { t, i18n } = this.props
    const { handleCloseMeridianPopup, setshowMeridinPopup, handleCloseStartUpPopup, setshowStartUpPopup, closeLoginPopup, setshowLoginPopup } = this;
    const { isContactValid } = this.state


    if (mainLoader)
      return <>
        <div className="text-center" style={{ display: 'flex', height: '100vh', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <div
            className="spinner-border assessmentSpinner"
            role="status"
            style={{
              width: '2rem',
              height: '2rem',
              border: '0.25em solid currentColor',
              borderRightColor: 'transparent'
            }}
          ></div>
          <span className="h1 ml-2">{t("summaryPage.loading")}</span>
        </div>
      </>

    const { detailsData, detailsDataLoading } = this.props.stateContext

    const { setshowAssessmentGapPopup, closeAssessmentGapPopup } = this;
    const { showAssessmentGapPopup } = this.state

    let allowAssessment = false

    // console.log('detailsData check ', this.props.stateContext, this.props.stateContext.auth.isLoggedIn, detailsData, detailsData?.user, detailsData?.user?.lastAssessment)
    // if ((detailsData && detailsData.user && detailsData.user._id) || this.props.stateContext.auth.isLoggedIn) {
    //     if (detailsData && detailsData.user && detailsData.user.lastAssessment) {
    //         if (moment().isAfter(moment(detailsData.user.lastAssessment).add(14, 'days'))) {
    //             allowAssessment = true;
    //         }
    //     } else {
    //         allowAssessment = true;
    //     }
    // }

    return (
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <div className="HomePG">
          <StartUpPopup
            show={showStartupPopup}
            t={t}
            handleClose={handleCloseStartUpPopup}
            onHide={handleCloseStartUpPopup}
          />
          <MeridianPopup
            show={showMeridinPopup}
            t={t}
            handleClose={handleCloseMeridianPopup}
            onHide={handleCloseMeridianPopup}
          />
          <LoginPopup
            i18n={i18n}
            t={t}
            show={showLoginPopup}
            handleClose={closeLoginPopup}
            onHide={closeLoginPopup}
            lastAssessment={detailsData?.user?.lastAssessment}
          />
          <AssessmentGap
            i18n={i18n}
            t={t}
            show={showAssessmentGapPopup}
            handleClose={closeAssessmentGapPopup}
            onHide={closeAssessmentGapPopup}
            lastAssessment={detailsData?.user?.lastAssessment}
          />
          <ClientAccessId
            i18n={i18n}
            t={t}
            show={showClientAccessPopUp}
            handleClose={this.handleClosePopup}
            onHide={this.handleClosePopup}
            setshowMeridinPopup={setshowMeridinPopup}
          />
          <div className="home-banner-section">
            <div className="container">
              {/* <img
              src={require("../../assets/images/banner1.png")}
              alt=""
              style={{ width: "100%" }}
            /> */}
              <Header fromHome={true} inProgress={this.state.inProgress} hasClient={this.state.hasClient} showClientPopUp={this.showClientPopUp} />
              <div className="fbnIntro-fbnIntro-home">
                <p className="homeHeadline wow fadeInUp">
                  <span> {t("fbnIntro.headline")}</span>
                </p>
                <p className="homeText">
                  <span className="dsblText">{t("fbnIntro.description")}</span>
                </p>
                {detailsDataLoading ?
                  <button className="btn homeButtons">
                    {t('summaryPage.loading')}
                  </button>
                  :
                  auth ? (
                    this.state.hasClient ?
                      (allowAssessment || this.state.inProgress ?
                        (<Link to="/assessment">
                          <button className="btn homeButtons">
                            {this.state.inProgress
                              ? t("fbnIntro.buttonText2Alt")
                              : t("fbnIntro.buttonText2")}
                          </button>
                        </Link>) :
                        <button className="btn homeButtons" onClick={(e) => {
                          e.preventDefault()
                          setshowAssessmentGapPopup(true)
                        }}>
                          {this.state.inProgress
                            ? t("fbnIntro.buttonText2Alt")
                            : t("fbnIntro.buttonText2")}
                        </button>)
                      : <button onClick={this.showClientPopUp} className="btn homeButtons">
                        {this.state.inProgress
                          ? t("fbnIntro.buttonText2Alt")
                          : t("fbnIntro.buttonText2")}
                      </button>
                  ) : (
                    // <a className="btn homeButtons wow fadeInUp" href="#personalized-demo">
                    <HashLink className="btn homeButtons" to="/#contact">
                      {t("fbnIntro.buttonText1")}
                    </HashLink>
                    // <button 
                    //   className="btn homeButtons"
                    //   onClick={() => setshowLoginPopup(true)}
                    //   >
                    //   {t("fbnIntro.buttonText2")}
                    // </button>
                  )
                }
              </div>
            </div>
          </div>
          <div className="how-we-work-block">
            <div className="container">
              <div className="title-top-section">
                {/* <h4>{t('homePage.sections.whatWeDo')}</h4> */}
                <h2 className="homeHeadline">
                  {t("fbnAboutUs2.headline1")}
                  {/* <br></br> */}
                  <span>FeelingBetterNow®</span>
                  {t("fbnAboutUs2.headline2")}
                </h2>
              </div>
              <div className="work-two-block">
                <div className="fbnAboutUs_2 wow fadeInUp"
                >
                  <div className="fbnAboutUs_3 wow fadeInUp" style={{ top: "77%" }}>
                    <div className="wwdtle title-center" >
                      <h3>{t("fbnAboutUs2.headline3")}</h3>
                    </div>
                    <p className="homeText">{t("fbnAboutUs2.description")}</p>
                    {/* <HashLink to="/about#advisoryBoard" target={"_blank"}>
                      <button
                        className="btn homeButtons"
                        style={{ marginTop: "6px" }}
                      >
                        {t("fbnAboutUs2.buttonText")}
                      </button>
                    </HashLink> */}
                  </div>
                </div>
                <div className="fbnVideo wow fadeInUp">
                  {/* <iframe
                    id="fbnVideo"
                    name="video"
                    title="homePageVideo"
                    src="https://player.vimeo.com/video/495541623"
                    width="640"
                    height="564"
                    allow="autoplay; fullscreen"
                  /> */}
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/-XuddNEAYiQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />
                  {/* {(store.get('lang') || 'en') === 'en' ?
                    <iframe
                      id="fbnVideo"
                      name="video"
                      title="homePageVideo"
                      src="https://www.youtube.com/watch?v=-XuddNEAYiQ"
                      width="640"
                      height="564"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen 
                    />
                  :
                    <iframe
                      id="fbnVideo"
                      name="video"
                      title="homePageVideo"
                      src="https://player.vimeo.com/video/495541623"
                      width="640"
                      height="564"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  } */}
                </div>
              </div>
            </div>

          </div>
          <div className="information wow fadeInUp">
            <div className="container">
              {/* <h4>{t("aboutUsPage.awardWinning.title")}</h4> */}
              <h2>{t("aboutUsPage.healthOutcomes.heading")}</h2>
              <div className="award-blovk-full" style={{ display: "flex" }}>
                <ul>
                  <li>{t("aboutUsPage.healthOutcomes.title1")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title2")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title3")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title4")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title5")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title6")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title7")}</li>
                  <li>{t("aboutUsPage.healthOutcomes.title8")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="information wow fadeInUp">
            <div className="container">
              <h4>{t("aboutUsPage.awardWinning.title")}</h4>
              <h2>{t("aboutUsPage.awardWinning.heading")}</h2>
              <div className="award-blovk-full">
                <div className="award-blovk-left">
                  <img
                    className="vector"
                    src={require("../../assets/images/award-img.png")}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="award-blovk-right">
                  <p>{t("aboutUsPage.awardWinning.data1")}</p>
                  <p>{t("aboutUsPage.awardWinning.data2")}<i>{t("aboutUsPage.awardWinning.data2Italic")}</i></p>
                  <p>{t("aboutUsPage.awardWinning.data3")}</p>
                  <ul>
                    <li>{t("aboutUsPage.awardWinning.title1")}</li>
                    <li>{t("aboutUsPage.awardWinning.title2")}</li>
                    <li>{t("aboutUsPage.awardWinning.title3")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="demo wow fadeInUp" id="contact">
            <div className="container demo-container">
              <div className="demo-row">
                <div className="col-left" id="border-line">
                  <h4>{t("aboutUsPage.contactUs.headline")}</h4>
                  <h2 className="demo-title">
                    {t("aboutUsPage.demoSection.headline")}
                  </h2>
                  <p className="demo-description">
                    {t("aboutUsPage.demoSection.description1")}
                  </p>
                  <p className="demo-description">
                    {t("aboutUsPage.demoSection.description2")}
                  </p>
                  <p className="contact-info mail-cnt" id="mail-contact">
                    <a href="mailto:sales@mensante.com">
                      {" "}
                      <img
                        className="contact-icon"
                        src={require("../../assets/images/icon-email.svg").default}
                        alt=""
                      />
                      sales@mensante.com
                    </a>{" "}
                  </p>
                  <p className="contact-info last">
                    <a href="tel:416-928-9195">
                      <img
                        className="contact-icon"
                        src={require("../../assets/images/telephone-icon.svg").default}
                        alt=""
                      />
                      (416) 928-9195 
                    </a>
                  </p>
                </div>
                <div className="col-right">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-box">
                      <div className="two-field">
                        <div className="input-field">
                          <label>
                            {t("aboutUsPage.formSection.nameLabel")} <em>*</em>
                          </label>
                          <input
                            type="text"
                            className="form-control demo-input resize"
                            id="exampleInputName"
                            aria-describedby="nameHelp"
                            placeholder={t("aboutUsPage.formSection.name")}
                            required
                            maxLength={50}
                            value={this.state.name}
                            onChange={this.handleChange}
                            name="name"
                            disabled={this.state.loading}
                          />
                        </div>
                        <div className="input-field">
                          <label>
                            {t("aboutUsPage.formSection.companyLabel")}{" "}
                            <em>*</em>
                          </label>
                          <input
                            type="company"
                            className="form-control demo-input resize"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={t("aboutUsPage.formSection.company")}
                            required
                            maxLength={50}
                            name="company"
                            value={this.state.company}
                            disabled={this.state.loading}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="two-field">
                        <div className="input-field">
                          <label>
                            {t("aboutUsPage.formSection.emailLabel")} <em>*</em>
                          </label>
                          <input
                            type="email"
                            className="form-control demo-input resize"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={t("aboutUsPage.formSection.email")}
                            required
                            maxLength={350}
                            name="email"
                            value={this.state.email}
                            disabled={this.state.loading}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="input-field">
                          <label>
                            {t("aboutUsPage.formSection.phoneLabel")} <em>*</em>
                          </label>
                          <Input
                            type={"tel"}
                            placeholder={t("aboutUsPage.formSection.phone")}
                            country="US"
                            value={this.state.phone}
                            maxLength={14}
                            onChange={(e) => this.setPhone(e)}
                            className="form-control demo-input resize"
                            disabled={this.state.loading}
                          />
                        </div>
                      </div>
                      <div className="two-field">
                        <div className="input-field">
                          <label>
                            {t("aboutUsPage.formSection.positionLabel")}{" "}
                            <em>*</em>
                          </label>
                          <input
                            type="position"
                            className="form-control demo-input resize"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={t("aboutUsPage.formSection.position")}
                            name="position"
                            maxLength={50}
                            value={this.state.position}
                            disabled={this.state.loading}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="input-field">
                          <label>
                            {t("aboutUsPage.formSection.interestedLabel")}
                          </label>
                          <select
                            disabled={this.state.loading}
                            className="form-select demo-input demo-input-multi-select resize form-control"
                            name="interest"
                            onChange={this.handleChange}
                            value={this.state.interest}
                          >
                            <option value="" disabled selected>
                              {t("aboutUsPage.formSection.interested")}
                            </option>
                            <option value="Scheduling a demo">
                              {t("aboutUsPage.formSection.option1")}
                            </option>
                            <option value="Receiving an Information Package">
                              {t("aboutUsPage.formSection.option2")}
                            </option>
                            <option value="Joining Email List">
                              {t("aboutUsPage.formSection.option3")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="input-field">
                        <label>
                          {t("aboutUsPage.formSection.messageLabel")}
                        </label>
                        <textarea
                          type="message"
                          className="form-control demo-input demo-input-message"
                          id="exampleInputPassword1"
                          placeholder={t("aboutUsPage.formSection.message")}
                          name="message"
                          maxLength={100}
                          value={this.state.message}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="about-submit-btn">
                        <button
                          type="submit"
                          className="btn submit-btn"
                          disabled={this.state.loading}
                        >
                          {t("aboutUsPage.formSection.submit")}
                        </button>
                        <p></p>
                      </div>
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          margin: "16px",
                        }}
                      >
                        {this.state.error}
                      </p>
                      {isContactValid === false ? (
                        <p
                          style={{
                            color: "red",
                            textAlign: "left",
                            margin: "16px",
                          }}
                        >
                          {t("profile.validContact")}
                        </p>
                      ) : (
                        <p />
                      )}
                      <p
                        style={{
                          color: "green",
                          textAlign: "left",
                          margin: "16px",
                        }}
                      >
                        {this.state.success}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <Footer />
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

function NewHome(props) {
  const { state } = useContext(StateContext)
  return <Home {...props} stateContext={state} />
}

export default withTranslation()(NewHome)
