import React, { useContext, useLayoutEffect, useEffect, useState } from "react"
import { scroller } from "react-scroll"
import _get from "lodash/get"
import _flatten from "lodash/flatten"
import _flattenDepth from "lodash/flattenDepth"
import "../../css/App.css"
import "react-circular-progressbar/dist/styles.css"
import Footer from "../../components/footer/Footer"
import NextPrevBtn from "../../components/buttons/NextPrevBtn"
import Navbar from "../../components/navbar/AssessmentHeader"
import { baseUrl } from "../../lib/constants"
import { AssessmentSidebar } from "./AssessmentSidebar"
import { EndSessionNotification } from "../../components/popups/notificationScheduler/EndSessionNotification"
import { FirstUnansweredQuestion } from "./FirstUnansweredQuestion"
import { StateContext } from "../../store/state/contexts"
/** @jsx jsx */
import { css, Global, jsx } from "@emotion/react"
import styled from "@emotion/styled"
import FollowUpQuestionsTab from "./tabs/FollowUpQuestionsTab"
import HealthHistoryTab from "./tabs/HealthHistoryTab"
import ScreeningTab from "./tabs/ScreeningTab"
import InDepthTab from "./tabs/InDepthTab"
import LifeFunctionsTab from "./tabs/LifeFunctionsTab"
import bgImg from "../../assets/images/top-bg-bottom-gradient.png"
import { SharedDivider } from "../../utils/shared"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"
import SafetyNet from "../../components/popups/safetyNet/SafetyNet"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"
import $ from 'jquery'
import { Link } from "react-router-dom"

export const EAssessmentWrapper = styled.div`
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

export const BgImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
`

export const EAssessmentProgressBlock = styled.div`
  padding-left: 0;
  @media (min-width: 991px) {
    width: 33%;
  }
`

var store = require("store")

const Component = ({
  onFetchAttempt,
  history,
  onQuestionIsNotPending,
  onQuestionIsPending,
  firstUnansweredQuestion,
  onScrollToFirstUnansweredQuestion,
  highlightFirstUnansweredQuestion,
  t,
  i18n
}) => {
  const { state, dispatch } = useContext(StateContext)
  const [indepthTab, setIndepthTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const [loadingOut, setLoadingOut] = useState(false)
  const [lang, setLang] = useState(store.get("lang") ? store.get("lang") : "en")
  const [tab, setTab] = useState(store.get("tab") ? store.get("tab") : 1)
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [insertDate, setInsertDate] = useState(
    store.get("insertDate") ? store.get("insertDate") : null
  )

  const [
    successfullyScrolledToFirstUnansweredQuestion,
    setSuccessfullyScrolledToFirstUnansweredQuestion,
  ] = useState(false)

  const handleSuccessfullScrollToFirstUnansweredQuestion = () => {
    setSuccessfullyScrolledToFirstUnansweredQuestion(true)
  }

  const changeLanguage = () => {
    i18n.changeLanguage(lang === "en" ? "fr" : "en")
    setLang(lang === "en" ? "fr" : "en")
    store.set("lang", lang === "en" ? "fr" : "en")
  }

  useEffect(() => {
    setLang(store.get('lang') ? store.get('lang') : "en");
  }, [store, store.get('lang')])

  const progressBarMethod = () => {
    dispatch({
      type: "STOP_HIGHLIGHT_FISRT_UNANSWERED_QUESTION",
    })
    // onFetchAttempt()
  }

  useEffect(() => {
    onFetchAttempt()
    progressBarMethod()

    i18n.changeLanguage(store.get('lang') || 'en')

    let elem = $('#importantStickyBoundary')[0]

    let resizeObserver = new ResizeObserver(() => {
      $(window).scrollTop($(window).scrollTop() + 1);
    });

    resizeObserver.observe(elem);

  }, [])

  const handleTabSet = (tab) => {
    setTab(tab)
    dispatch({ type: "SET_STEP", payload: tab })
  }

  const nextPrevBtnMethod = (tab) => {
    handleTabSet(tab)
  }

  const nextPrevIndepthBtnMethod = (tab) => {
    setIndepthTab(tab)
  }

  const handleSetIndepthTab = (indepthTab) => {
    setIndepthTab(indepthTab)
  }

  const CompleteAssessment = async () => {
    var myHeaders = new Headers()

    var requestOptions = {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: myHeaders,
    }

    try {
      var res = await fetch(`${baseUrl}/assessment/complete`, requestOptions)
      var response = await res.json()
      if (response) {
        logEvent("CompleteAssessment /assessment/complete successful")
        setTab(1)
        dispatch({ type: "SET_STEP", payload: 1 })
        store.remove("progressBar")
        store.remove("toTab")
        store.remove("screeningQuestionsHasDisorder")
        store.remove("progress")
        store.set("tab", 1)
        window.location.replace(`/summary/${response._id}`)
      } else {
        logEvent("CompleteAssessment /assessment/complete failed")
      }
    } catch (error) {
      logEvent("CompleteAssessment /assessment/complete failed")
      console.log(error);
    }
  }

  const closeEndSessionNotification = () => {
    var myHeaders = new Headers()

    var requestOptions = {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers: myHeaders,
    }

    fetch(`${baseUrl}/assessment/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        logEvent("closeEndSessionNotification /assessment successful")
        console.log(result)
      })
      .catch((error) => {
        logEvent("assessment failed")
        console.log("error", error)
      })

    setIsPopupVisible(false)
  }

  var indepthCount = 0

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <>
        <div
          css={css`
            font-family: "CircularStdBook", sans-serif;
            background-color: #ecf8f8;
          `}
        >
          <img
            src={bgImg}
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            `}
          />

          {/* <BgImg src={bgImg} /> */}

          {!successfullyScrolledToFirstUnansweredQuestion && (
            <FirstUnansweredQuestion
              onSetTab={handleTabSet}
              onSetIndepthTab={handleSetIndepthTab}
              onSuccessfullScrollToFirstUnansweredQuestion={
                handleSuccessfullScrollToFirstUnansweredQuestion
              }
            />
          )}

          <Navbar
            lang={lang}
            changeLanguage={changeLanguage}
            history={history}
            from="assessment"
          />
          <div
            className="main-container"
            css={css`
              z-index: 1;
              position: relative;
            `}
          >
            <div className="card shadow assessmentCard">
              {!state.historyData || _get(
                state,
                "assessment.isLoading"
              ) && (
                  <div className="text-center my-5">
                    <div
                      className="spinner-border assessmentSpinner"
                      role="status"
                      style={{
                        width: '2rem',
                        height: '2rem',
                        border: '0.25em solid currentColor',
                        borderRightColor: 'transparent'
                      }}
                    ></div>
                    <span className="h1 ml-2">Loading...</span>
                  </div>
                )}

              {_get(
                state,
                "assessment.error"
              ) && (
                  <>
                    <div className="text-center my-5 p-3">
                      <span className="h4 ml-2" style={{ fontWeight: '400' }}>{ _get(state,"assessment.error") }</span>
                    </div>
                    <div className="text-center mb-5 ">
                      <Link to="/">
                        <button className="btn prevBtn">
                          Go back to homepage
                        </button>
                      </Link>
                    </div>
                  </>
                )}

              <EAssessmentWrapper>
                <EAssessmentProgressBlock id="importantStickyBoundary">
                  {!loadingOut && (
                    <AssessmentSidebar
                      history={state.historyData}
                      nextPrevBtnMethod={nextPrevBtnMethod}
                      tab={tab}
                      indepthTab={indepthTab}
                      t={t}
                      i18n={i18n}
                    />
                  )}
                </EAssessmentProgressBlock>

                {!loadingOut && (
                  <div className="col-lg-8 col-md-12 col-sm-12 pr-zero wow fadeInUp">
                    {/* BELOW COMMENTED DIV COUSE ISSUE THAT SCREENING TAB REMAINS SHOW IN EVERY TAB */}
                    {/* <div className="force-overflow"> */}
                    {tab === 1 && (
                      <div className={"tab-pane show active"}>
                        <ScreeningTab
                          lang={lang}
                          progressBarMethod={progressBarMethod}
                          onQuestionIsNotPending={onQuestionIsNotPending}
                          onQuestionIsPending={onQuestionIsPending}
                          onFetchAttempt={onFetchAttempt}
                        />
                        <NextPrevBtn
                          history={state.historyData}
                          onFetchAttempt={onFetchAttempt}
                          tab={tab}
                          loading={loading}
                          nextPrevBtnMethod={nextPrevBtnMethod}
                          CompleteAssessment={CompleteAssessment}
                          indepthTab={indepthTab}
                          loading={loading}
                          nextPrevIndepthBtnMethod={nextPrevIndepthBtnMethod}
                          handleTabSet={handleTabSet}
                          onScrollToFirstUnansweredQuestion={
                            onScrollToFirstUnansweredQuestion
                          }
                          onSetIndepthTab={handleSetIndepthTab}
                        />
                      </div>
                    )}
                    {/* </div> */}

                    {tab === 2 && (
                      <div className={"tab-pane show active"}>
                        <InDepthTab
                          indepthTab={indepthTab}
                          lang={lang}
                          progressBarMethod={progressBarMethod}
                          onQuestionIsNotPending={onQuestionIsNotPending}
                          onQuestionIsPending={onQuestionIsPending}
                        />
                        <NextPrevBtn
                          history={state.historyData}
                          onFetchAttempt={null}
                          tab={tab}
                          nextPrevBtnMethod={nextPrevBtnMethod}
                          CompleteAssessment={CompleteAssessment}
                          indepthTab={indepthTab}
                          loading={loading}
                          nextPrevIndepthBtnMethod={nextPrevIndepthBtnMethod}
                          indepthCount={indepthCount}
                          handleTabSet={handleTabSet}
                          onScrollToFirstUnansweredQuestion={
                            onScrollToFirstUnansweredQuestion
                          }
                          onSetIndepthTab={handleSetIndepthTab}
                        />
                      </div>
                    )}

                    {tab === 3 && (
                      <div className="tab-pane show active">
                        <HealthHistoryTab
                          lang={lang}
                          progressBarMethod={progressBarMethod}
                          onQuestionIsNotPending={onQuestionIsNotPending}
                          onQuestionIsPending={onQuestionIsPending}
                        />
                        <NextPrevBtn
                          history={state.historyData}
                          onFetchAttempt={null}
                          tab={tab}
                          loading={loading}
                          nextPrevBtnMethod={nextPrevBtnMethod}
                          CompleteAssessment={CompleteAssessment}
                          indepthTab={indepthTab}
                          fromHealthHistory
                          handleTabSet={handleTabSet}
                          onScrollToFirstUnansweredQuestion={
                            onScrollToFirstUnansweredQuestion
                          }
                          onSetIndepthTab={handleSetIndepthTab}
                        />
                      </div>
                    )}
                    {tab === 4 && (
                      <div className="tab-pane show active">
                        <LifeFunctionsTab
                          lang={lang}
                          progressBarMethod={progressBarMethod}
                          onQuestionIsNotPending={onQuestionIsNotPending}
                          onQuestionIsPending={onQuestionIsPending}
                        />

                        <NextPrevBtn
                          history={state.historyData}
                          onFetchAttempt={null}
                          tab={tab}
                          loading={loading}
                          nextPrevBtnMethod={nextPrevBtnMethod}
                          CompleteAssessment={CompleteAssessment}
                          indepthTab={indepthTab}
                          handleTabSet={handleTabSet}
                          onScrollToFirstUnansweredQuestion={
                            onScrollToFirstUnansweredQuestion
                          }
                          onSetIndepthTab={handleSetIndepthTab}
                        />
                      </div>
                    )}

                    {state.historyData && state.historyData.length > 0 && tab === 5 && (
                      <div className="tab-pane show active">
                        <FollowUpQuestionsTab
                          lang={lang}
                          progressBarMethod={progressBarMethod}
                          onQuestionIsNotPending={onQuestionIsNotPending}
                          onQuestionIsPending={onQuestionIsPending}
                        />
                        <NextPrevBtn
                          fromFollowUp
                          onFetchAttempt={null}
                          tab={tab}
                          loading={loading}
                          nextPrevBtnMethod={nextPrevBtnMethod}
                          CompleteAssessment={CompleteAssessment}
                          indepthTab={indepthTab}
                          handleTabSet={handleTabSet}
                          onScrollToFirstUnansweredQuestion={
                            onScrollToFirstUnansweredQuestion
                          }
                          onSetIndepthTab={handleSetIndepthTab}
                        />
                      </div>
                    )}
                  </div>
                )}
              </EAssessmentWrapper>
            </div>
          </div>

          <SharedDivider count={2} />
        </div>
        {isPopupVisible && (
          <EndSessionNotification
            closeEndSessionNotification={closeEndSessionNotification}
          />
        )}
        <div className="footer">
          <Footer />
        </div>
      </>
    </ErrorBoundary>
  )
}

const Assessment = (props) => {
  const { state, dispatch } = useContext(StateContext)

  const { t, i18n } = props;

  const handleQuestionIsPending = () => {
    dispatch({
      type: "SET_QUESTION_IS_PENDING",
    })
  }

  const handleQuestionIsNotPending = () => {
    dispatch({
      type: "SET_QUESTION_IS_NOT_PENDING",
    })
  }

  useLayoutEffect(() => {
    handleQuestionIsPending()

    dispatch({
      type: "FETCH_ASSESSMENT_REQUEST",
    })

    fetch(baseUrl + "/assessment", {
      credentials: "include",
      mode: "cors",
    })
      .then((res) => {
        let response = res;
        response.json()
        .then(newRes => {
          if (res && res.status && res.status === 200) {
            logEvent("useLayoutEffect /assessment successful")
            dispatch({
              type: "FETCH_ASSESSMENT_SUCCESS",
              payload: newRes,
            })
            if (newRes.attempt !== undefined)
              dispatch({
                type: "FETCH_ASSESSMENT_ATTEMPT_SUCCESS",
                payload: newRes.attempt,
              })
            else handleFetchAttempt()
          } else {
            // res.json().then((err) => {
            logEvent("useLayoutEffect /assessment failed")
            throw newRes
            // })
            // .catch(err => {
              // throw err
            // })
          }
          handleQuestionIsNotPending()
        })
        .catch(err => {
          if (
            err &&
            err.message
            // &&
            // err.response.data &&
            // err.response.data.message
          ) {
            logEvent("useLayoutEffect /assessment failed")
            dispatch({
              type: "FETCH_ASSESSMENT_ERROR",
              payload: err.message,
            })
          } else {
            dispatch({
              type: "FETCH_ASSESSMENT_ERROR",
              payload: "Some Error",
            })
          }
          handleQuestionIsNotPending()
        })
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          logEvent("useLayoutEffect /assessment failed")
          dispatch({
            type: "FETCH_ASSESSMENT_ERROR",
            payload: err.response.data.message,
          })
        } else {
          dispatch({
            type: "FETCH_ASSESSMENT_ERROR",
            payload: "Some Error",
          })
        }
        handleQuestionIsNotPending()
      })
  }, [])

  const handleFetchAttempt = () => {
    handleQuestionIsPending()

    dispatch({
      type: "FETCH_ASSESSMENT_ATTEMPT_REQUEST",
    })

    fetch(baseUrl + "/assessment/attempt", {
      credentials: "include",
      mode: "cors",
    })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          logEvent("handleFetchAttempt /assessment/attempt successful")
          res.json().then((res) => {
            dispatch({
              type: "FETCH_ASSESSMENT_ATTEMPT_SUCCESS",
              payload: res,
            })
          })
        } else {
          logEvent("handleFetchAttempt /assessment/attempt failed")
          throw "Error"
        }
        handleQuestionIsNotPending()
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          logEvent("handleFetchAttempt /assessment/attempt failed")
          dispatch({
            type: "FETCH_ASSESSMENT_ATTEMPT_ERROR",
            payload: err.response.data.message,
          })
        } else {
          dispatch({
            type: "FETCH_ASSESSMENT_ATTEMPT_ERROR",
            payload: "Some Error",
          })
        }
        handleQuestionIsNotPending()
      })
  }

  const highlightFirstUnansweredQuestion = () => {
    dispatch({
      type: "HIGHLIGHT_FIRST_UNANSWERED_QUESTION",
      payload: true,
    })

    setTimeout(() => {
      dispatch({
        type: "STOP_HIGHLIGHT_FISRT_UNANSWERED_QUESTION",
      })
    }, 2000)
  }

  const handleScrollToFirstUnansweredQuestion = () => {
    highlightFirstUnansweredQuestion()

    try {
      scroller.scrollTo(state.firstUnansweredQuestion.id, {
        smooth: true,
        offset: -100,
        duration: 300,
        delay: 0,
      })
    } catch (err) {
      console.log({ err })
    }
  }

  return (
    <>
      <SafetyNet />
      {state.answerIsPending && (
        <Global
          styles={css`
            * {
              cursor: progress !important;
              pointer-events: none !important;
            }
          `}
        />
      )}
      <Component
        history={props.history}
        onFetchAttempt={handleFetchAttempt}
        onQuestionIsNotPending={handleQuestionIsNotPending}
        onQuestionIsPending={handleQuestionIsPending}
        onScrollToFirstUnansweredQuestion={
          handleScrollToFirstUnansweredQuestion
        }
        t={t}
        i18n={i18n}
      />
    </>
  )
}

export default withTranslation()(Assessment)