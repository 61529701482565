import React, { useState, useEffect } from "react"
// import "../css/ClientAccessCode.css"
import "../../../css/privacyPolicy.css"
import { SharedModalBackground } from "../../../utils/shared"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import LogoSVG from "../../../assets/images/fbn-logo.svg"
import CloseImg from "../../../assets/images/close.svg"
import OutsideClickHandler from "react-outside-click-handler"
import { baseUrl, loginUrl, getLoginURL } from "../../../lib/constants"
import styled from "@emotion/styled"
import moment from 'moment';
const store = require("store")

const EModal = styled.div`
  min-width: 15em;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 7px 20px 2px rgba(0, 0, 0, 0.4)"};
  background-color: #fff;
  border-radius: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 82vh;
  width: 600px;
  position: relative;
  font-family: "CircularStdBook", sans-serif;

  @media (max-width: 640px) {
    width: 90vw;
  }
`

const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const GreyLine = styled.div`
  border: 1px solid #ececec;
`

const EModalText = styled.p`
  font-family: "CircularStdBook", sans-serif;
  font-size: 1.4em;
  color: #505050;
  margin: 0;
`

const EInputWrapper = styled.div`
  position: relative;
  padding: 0;
  height: 3.2em;
`

const EInput = styled.input`
  padding-left: 2.4em;
  border: 1px solid #d1d1d1;
  border-radius: 0.4em;
  outline: none;
  font-size: 1.2em;
  z-index: 2;
  background-color: transparent;
  height: 100%;
  width: 100%;

  ::placeholder {
    color: #c8c8c8;
  }
`
const EInputImg = styled.img`
  width: 1.3em;
  justify-self: center;
  position: absolute;
  left: 0.7em;
  font-size: 1.1em;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
`

const EButton = styled.button`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  color: ${(props) => (props.selected ? "#383940" : "#afafb1")};
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  position: relative;
  text-align: initial;
  transition: all 0.2s;
  min-height: 3.2em;
  width: 100%;
  :focus {
    outline: 0;
  }
`

export default ({ show, handleClose, onHide, t, i18n, lastAssessment }) => {
  const [openClientDetails, setOpenClientDetails] = useState(false)

  useEffect(() => {
    if(i18n)
      i18n.changeLanguage(store.get('lang') || 'en')
  }, [])

  const getLoginURL = (nonce) => {
    return `https://mensanteid.b2clogin.com/mensanteid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_fbn_signup_signin&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=${encodeURIComponent(nonce) +
        "&redirect_uri=" +
        encodeURIComponent(baseUrl + "/callback")
        }&scope=openid&response_type=id_token&prompt=login&response_mode=form_post`
  }

  const getNonce = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const nonce = urlParams.get("code")
    if (nonce != null) return nonce
    else return "defaultNonce"
  }

  return (
    <>
      {show && (
        <>
          <SharedModalBackground />

          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 1100;

              @media (max-width: 640px) {
                font-size: 0.8em;
              }

              @media (max-height: 1000px) {
                font-size: 0.7em;
              }
            `}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpenClientDetails(false)
              }}
            >
              <EModal>
                <>
                  <EModalContent>
                    <div
                      css={css`
                        padding: 1.5em 2em;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      `}
                    >
                      <img
                        src={LogoSVG}
                        alt="logo"
                        css={css`
                          width: 15em;
                        `}
                      />
                      <img
                        src={CloseImg}
                        onClick={() => {
                          onHide()
                        }}
                        css={css`
                          height: 1.2em;
                          cursor: pointer;
                        `}
                      />
                    </div>

                    <GreyLine />

                    {!openClientDetails && (
                      <>
                        <EModalText
                            className="mb-3"
                          css={css`
                            margin: 1.5em 2em 0 2em;
                          `}
                        >
                            
                            {t('loginPopup.description1')} <a href="https://temp-mail.org" target="_blank" style={{ color: 'var(--green)'}}>temp-mail.org</a> {t('loginPopup.description2')} <a href="mailto:help@mensante.com" style={{ color: 'var(--green)'}}>help@mensante.com</a> {t('loginPopup.description3')}
                        </EModalText>


                        <EModalText
                            className="mb-3"
                          css={css`
                            margin: 1.5em 2em 0 2em;
                          `}
                        >
                            
                            For your security and to protect your privacy we require users who sign up with Facebook to have an email associated with their Facebook account.  Please add an email address to your Facebook profile and then try again; or alternatively you can sign up using another method.
                          </EModalText>
                          <EmodalCheckboxPart>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="customCheck" name="example1"  checked={checked} onChange={() => setChecked(!checked)} />
                              <label className="custom-control-label" for="customCheck">{t('loginPopup.terms1')} <Link to="/termcondition" style={{ color: 'var(--green)' }}>{t('loginPopup.terms2')}</Link>{t('loginPopup.terms3')}<Link to="/privacypolicy" style={{ color: 'var(--green)' }}>{t('loginPopup.terms4')}</Link> {t('loginPopup.terms5')}</label>
                            </div>
                          </EmodalCheckboxPart>
                          <div className="row">
                            <div className="col-md-12 mb-3 text-center">
                              <button
                                className="btn homeNavBtn"
                                // onClick={() => setshowLoginPopup(true)}
                                onClick={() => window.open(getLoginURL(getNonce()), "_self")}
                            >
                                Sign In/Up
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </EModalContent>
                </>
              </EModal>
            </OutsideClickHandler>
          </div>
        </>
      )}
    </>
  )
}
