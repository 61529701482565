import React, { useState, useEffect } from 'react';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import '../../css/ResourcesCard.scss';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import phoneIcon from '../../assets/images/phone-icon.png';
import { baseUrl } from '../../lib/constants';

const leftCard = styled.div`
  height: 250px;
  width: 600px;
  font-family: "CircularStdBook";
  font-weight: 100;
  color: black;
  positiion: relative;
`;

const disorders = {
  "Depression":"Depression",
  "Bipolar Disorder":"Bipolar Disorder",
  "Alcohol":"Alcohol and Substance Abuse",
  "Panic Disorder": "Panic Disorder",
  "Social Anxiety": "Social Anxiety",
  "PTSD":"PTSD",
  "OCD": "Obsessive Compulsive Disorder",
  "GAD": "General Anxiety Disorder",
  "Eating Disorder":"Eating Disorder",
  "adhd": "ADHD",
  "Anger Problems": "Anger Problems",
  "Adjustment Disorder":"Adjustment Disorder",
  "Insomnia": "Sleep Disorder (Insomnia)",
  "Covid": "Covid"
}

const ResourcesCard = ({
  resource,
  disorder,
  bookmarks,
  setBookmarks,
  setBookmarksData,
  postBookmarkIds,
  bookmarksIds,
  setBookmarksIds,
  // lang,
  fromBookmark,
  disorderBookmark,
  isVideo,
  t,
  i18n,
  setLoading
}) => {
  const [isBookMarked, setIsBookMarked] = useState(false);

  const lang = i18n.language === "en" ? "en" : 'french';

  useEffect(() => {
    setBookmarks([]);
    // if(bookmarksIds && bookmarksIds.includes(resource.id))
    console.log('bookmarks', bookmarksIds && bookmarksIds.includes(resource.id), bookmarksIds, resource.id)
    setIsBookMarked(bookmarksIds && bookmarksIds.includes(resource.id));
    // }, [disorder])
  }, [bookmarksIds, disorder, resource]);

  // useEffect(() => {
  //   console.log("bookmarks", bookmarks)
  // }, [bookmarks])

  const truncate = (str) => (str.length > 40 ? `${str.substring(0, 30)}...` : str);

  const changeBookmarkState = async (arr) => {
    setLoading(true);
    await fetch(`${baseUrl}/user-profile/bookmarks`, {
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ bookmarks: arr }),
    })
      .then((res) => res.json())
      .then((res) => { setBookmarksIds(arr); postBookmarkIds(); setLoading(false); })
      .catch((err) => {console.log(err); setLoading(false);});
  };
  return (
    <div className="mainCard button-bottom-grid">
      <div className="topCard button-bottom-grid">
        {/* hover-content on card */}
        {!isVideo && 
          <div className="hover-content-block">
            <div className="hover-bookmark">
              <button
                onClick={() => {
                  if (isBookMarked) {
                    let arr = [...bookmarksIds];
                    arr = arr.filter((a) => a !== resource.id);
                    changeBookmarkState(arr);
                  } else {
                    let arr = [...(bookmarksIds || [])];
                    arr = arr.filter((a) => a !== resource.id);
                    changeBookmarkState([...arr, resource.id]);
                    // setBookmarks([...bookmarks, resource.id])
                  }
                  setIsBookMarked(!isBookMarked);
                }}
                className="bookmark-btn"
              >
                <img
                  height="24"
                  src={
                    isBookMarked // || bookmarksIds && bookmarksIds.includes(resource.id)
                      ? require('../../assets/images/bookmark_active.png')
                      : require('../../assets/images/bookmark.png')
                  }
                />
              </button>
            </div>
            <div className="content-link">
            <h4 className="cardTitle card-title text-center">
                {resource[`title-${lang}`]}
              </h4>
              <p className="hover-visible-content">
                {resource[`description-${lang}`]}
              </p>
              <div className="bottom-part">
                <div className="icon-section">
                  <ul>
                    {resource['number-address'].number && (
                      <li>
                        <img src={require('../../assets/images/phone_icon.png')} />
                        <a href={`tel:${resource['number-address'].number}`}>{resource['number-address'].number}</a>
                      </li>
                    )}
                    {resource.email && (
                      <li>
                        <img src={require('../../assets/images/email_icon.png')} />
                        <a href={`mailto:${resource.email}`}>{resource.email}</a>
                      </li>
                    )}
                    {resource.pdf && (
                      <li>
                        <img src={require('../../assets/images/pdf_icon.png')} />
                        <a href={resource.pdf}>Download PDF</a>
                      </li>
                    )}
                    {resource.website && (
                      <li>
                        <img src={require('../../assets/images/web_icon.png')} />
                        <a target="_blank" href={resource.website}>{resource.videoUrl ? i18n.t("education.watchVideo") : i18n.t("education.visitWebsite")}</a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="link-button-section">
                  <a className="link-btn" href="#">
                    {resource.qaulifiers.type !== undefined
                      && resource.qaulifiers.type.resourceType !== undefined
                      ? resource.qaulifiers.type.resourceType == 'academicPapers' ? 'Article' : resource.qaulifiers.type.resourceType.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
                      : ''}

                  </a>
                  <a className="link-btn" href="#">{fromBookmark ? (resource?.qaulifiers?.type?.disorderType || '') : (resource?.qaulifiers?.type?.disorderType || '')}</a>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="formal-content button-bottom-grid" style={isVideo ? { display: 'flex' } : {}} >
          <div className={`image-part ${resource?.qaulifiers?.type?.resourceType == "VideoResources" ? 'available-image' : ''}`}>
            <div className="bookmark-with-image">
              <button
                onClick={() => {
                  if (isBookMarked) {
                    let arr = [...bookmarksIds];
                    arr = arr.filter((a) => a !== resource.id);
                    changeBookmarkState(arr);
                  } else {
                    let arr = [...(bookmarksIds || [])];
                    arr = arr.filter((a) => a !== resource.id);
                    changeBookmarkState([...arr, resource.id]);
                    // setBookmarks([...bookmarks, resource.id])
                  }
                  setIsBookMarked(!isBookMarked);
                }}
                className="bookmark-btn bookmark-radius"
              >
                <img
                  height="24"
                  src={
                    isBookMarked // || bookmarksIds && bookmarksIds.includes(resource.id)
                      ? require('../../assets/images/bookmark_active.png')
                      : require('../../assets/images/bookmark-dark.png')
                  }
                />
              </button>
            </div>
            {resource?.qaulifiers?.type?.resourceType == "VideoResources" && (
              <a href={resource.videoUrl} target="_blank">
                <div className="video-thumbnail-div">
                  <img src={resource.imageUrl}></img>
                </div>
              </a>
            )}
          </div>
          <div className='content-part button-bottom-grid'>
            <div className={`headerCard ${resource?.qaulifiers?.type?.resourceType == "VideoResources" ? 'with-image-header' : ''}`}>
              <h4 className="cardTitle card-title">
                {resource[`title-${lang}`]}
              </h4>
              <div>
                <button
                  onClick={() => {
                    if (isBookMarked) {
                      let arr = [...bookmarksIds];
                      arr = arr.filter((a) => a !== resource.id);
                      changeBookmarkState(arr);
                    } else {
                      let arr = [...(bookmarksIds || [])];
                      arr = arr.filter((a) => a !== resource.id);
                      changeBookmarkState([...arr, resource.id]);
                      // setBookmarks([...bookmarks, resource.id])
                    }
                    setIsBookMarked(!isBookMarked);
                  }}
                  className="bookmark-btn"
                >
                  <img
                    height="24"
                    src={
                      isBookMarked // || bookmarksIds && bookmarksIds.includes(resource.id)
                        ? require('../../assets/images/bookmark_active.png')
                        : require('../../assets/images/bookmark.png')
                    }
                  />
                </button>
              </div>
            </div>
            <div className="card-Description-wrapper">
              <p className="cardDescription card-description">
                {resource[`description-${lang}`]}
              </p>
            </div>
            {/* <div className="">
                    <div className="d-flex flex-column">
                      {resource["number-address"].number && (
                        <a
                          href={`tel:${resource["number-address"].number}`}
                          target="_blank"
                        >
                          <span className="">
                            <img src={require("../../assets/images/call.svg")} />
                            <span className="pl-2 card-links">
                              {resource["number-address"].number}
                            </span>
                          </span>
                        </a>
                      )}
                      {resource.website && (
                        <a
                          href={resource.website}
                          target="_blank"
                        >
                          <span className="">
                            <img src={require("../../assets/images/Frame.svg")} />
                            {/* <span className="pl-2 card-links">{resource.website}</span>}
                            <span className="pl-2 card-links">
                              {truncate(resource.website)}
                            </span>
                          </span>
                        </a>
                      )}
                      {resource.email && (
                        <a
                          href={`mailto:${resource.email}`}
                          target="_blank"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span className="px-3">
                            <img
                              src={require("../../assets/images/Mail_green.svg")}
                            />
                            <span className="pl-2 card-links">
                              {resource.email}
                            </span>
                          </span>
                        </a>
                      )}
                    </div>
                    <div className="">
                      <span
                        className="cardTag card-tags"
                      >
                        {console.log(resource)}
                        {console.log('resource')}
                        {resource.qaulifiers.type !== undefined &&
                        resource.qaulifiers.type.resourceType !== undefined
                          ? resource.qaulifiers.type.resourceType == 'academicPapers' ? 'Article': resource.qaulifiers.type.resourceType.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
                          : ""}
                      </span>
                      <span
                        className="cardTag card-tags"
                      >
                        {disorder}
                      </span>
                    </div>
                  </div> */}
            <div className="bottom-part">
              <div className="icon-section">
                <ul>
                  {resource['number-address'].number && (
                    <li>
                      <img src={require('../../assets/images/phone_icon.png')} />
                      <a href={`tel:${resource['number-address'].number}`}>{resource['number-address'].number}</a>
                    </li>
                  )}
                  {resource.email && (
                    <li>
                      <img src={require('../../assets/images/email_icon.png')} />
                      <a href={`mailto:${resource.email}`}>{resource.email}</a>
                    </li>
                  )}
                  {resource.pdf && (
                    <li>
                      <img src={require('../../assets/images/pdf_icon.png')} />
                      <a href={resource.pdf}>Download PDF</a>
                    </li>
                  )}
                  {resource.website && (
                    <li>
                      <img src={require('../../assets/images/web_icon.png')} />
                      <a target='_blank' href={resource.website}>{resource.videoUrl ? i18n.t("education.watchVideo") : i18n.t("education.visitWebsite")}</a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="link-button-section">
                <a className="link-btn" href="#">
                  {resource.qaulifiers.type !== undefined
                    && resource.qaulifiers.type.resourceType !== undefined
                    ? resource.qaulifiers.type.resourceType == 'academicPapers' ? 'Article' : resource.qaulifiers.type.resourceType.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
                    : ''}

                </a>
                <a className="link-btn" href="#">{fromBookmark ? (resource?.qaulifiers?.type?.disorderType || '') : (resource?.qaulifiers?.type?.disorderType || disorder)}</a>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default ResourcesCard;
