import React, { useContext, useState, useEffect, useCallback } from "react"
import "../../css/App.css"
import { DefaultButton } from "../../components/buttons/Button"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { baseUrl } from "../../lib/constants"
import { Element } from "react-scroll"
import _get from "lodash/get"
import axios from "axios"
import { StateContext } from "../../store/state/contexts"
/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"
import styled from "@emotion/styled"
import { set } from "store"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import AssessmentCard from "./AssessmentCard"
import { scroller } from "react-scroll"
import ScrollToID from "./ScrollToCard"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"

const axiosoptions = {
  withCredentials: true,
  mode: "cors",
}

const Component = React.memo(
  ({
    caption,
    id,
    questionType,
    response,
    firstUnansweredQuestionId,
    onQuestionIsNotPending,
    progressBarMethod,
    onQuestionIsPending,
    answerLife,
    t,
    i18n,
    highlightFirstUnansweredQuestion,
  }) => {
    const { state, dispatch } = useContext(StateContext)
    const language = i18n.language === "en" ? "en" : 'french';
    console.log("language=========>",i18n)
    const [loadSubmitBtn, setLoadSubmitBtn] = useState(false)
    const [openCard, setOpenCard] = useState(false)
    const [yesClicked, setYesClicked] = useState(false)
    const [sliderValue, setSliderValue] = useState(0)
    useEffect(() => {
      setYesClicked(response > 0)
      if (firstUnansweredQuestionId === id) {
        setOpenCard(true)
        // ScrollToID(id)
      }
      setSliderValue(response > 0 ? response : 0)
    }, [
      JSON.stringify(response),
      firstUnansweredQuestionId,
      JSON.stringify(state.questions),
    ])

    const yesClick = (e) => {
      e.stopPropagation()
      setOpenCard(true)
      setYesClicked(true)
    }

    const noClick = (e) => {
      e.stopPropagation()
      onQuestionIsPending()
      setYesClicked(false)
      setLoadSubmitBtn(true)

      axios
        .post(
          `${baseUrl}/assessment/life-functions`,
          {
            id: id,
            response: -1,
          },
          axiosoptions
        )
        .then((res) => {
          logEvent("noClick /assessment/life-functions successful")
          answerLife(-1)
          onQuestionIsNotPending()
          setOpenCard(false)
          setLoadSubmitBtn(false)
          progressBarMethod()
        })
        .catch(() => {
          logEvent("noClick /assessment/health-history failed")
          alert(
            "Something Went Wrong, Please resubmit the question or check your Internet Connection"
          )
          onQuestionIsNotPending()
          setOpenCard(false)
          setLoadSubmitBtn(false)
          progressBarMethod()
        })
    }

    const handleSubmitClick = () => {
      onQuestionIsPending()

      setLoadSubmitBtn(true)

      axios
        .post(
          `${baseUrl}/assessment/life-functions`,
          {
            id: id,
            response: sliderValue,
          },
          axiosoptions
        )
        .then((res) => {
          logEvent("handleSubmitClick /assessment/life-functions successful")
          if (res) {
            answerLife(sliderValue)
            setLoadSubmitBtn(false)
          }
          onQuestionIsNotPending()
          progressBarMethod()
          setOpenCard(false)
        })
        .catch((err) => {
          logEvent("handleSubmitClick /assessment/life-functions failed")
          console.log({ err })
          alert(
            "Something Went Wrong, Please resubmit the question or check your Internet Connection"
          )
          onQuestionIsNotPending()
          progressBarMethod()
          setOpenCard(false)
        })
    }

    const redHighlight =
      firstUnansweredQuestionId === id && highlightFirstUnansweredQuestion

    return (
      <AssessmentCard
        question={caption}
        redHighlight={redHighlight}
        loading={loadSubmitBtn}
        answer={
          response === -1
            ? "No"
            : response + (questionType === "percent" ? "%" : " Days")
        }
        open={openCard}
        setOpen={setOpenCard}
        answered={response !== null}
        id={id}
      >
        <div>
          <div
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            <div className="slider">
              <Slider
                min={0}
                max={questionType === "percent" ? 100 : 60}
                value={sliderValue}
                onChange={(e) => {
                  setSliderValue(e)
                }}
              />
              <div className="value pb-2">
                {t("lifeFunction.yourResponse")}: {sliderValue}{" "}
                {questionType == "percent" ? "%" : "Day(s)"}
              </div>
            </div>
          </div>
          <button className="btn SubmitAnswerBtn"
            style={(language === "french") ? { width: "165px" } : {}}
            onClick={handleSubmitClick}>
            {t("lifeFunction.submit")}
          </button>
        </div>
      </AssessmentCard>
    )
  },
  (prev, next) =>
    JSON.stringify(prev.response) === JSON.stringify(next.response) &&
    prev.firstUnansweredQuestionId === next.firstUnansweredQuestionId &&
    prev.lang === next.lang
)

const LifeFunction = (props) => {
  const { state } = useContext(StateContext)

  const lf = _get(state, ["questions", props.id])
  return (
    <Component
      {...props}
      caption={lf[props.lang].text}
      id={lf.id}
      questionType={lf.question_type}
      response={lf.response}
      answerIsPending={state.answerIsPending}
      firstUnansweredQuestionId={
        state.firstUnansweredQuestion && state.firstUnansweredQuestion.id
      }
      answerLife={props.answerLife}
      progressBarMethod={props.progressBarMethod}
      onQuestionIsPending={props.onQuestionIsPending}
      onQuestionIsNotPending={props.onQuestionIsNotPending}
      highlightFirstUnansweredQuestion={state.highlightFirstUnansweredQuestion}
    />
  )
}
export default withTranslation()(LifeFunction)
