/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"

export const SharedModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#000000e8"};
  opacity: 0.5;
  z-index: 1000;
  max-width: 100%;
  left: 0;
  top: 0;
`

export const DefaultButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "CircularStdBook", sans-serif;
  background-color: ${(props) => (props.active ? "#33ca8f" : "#eeeef3")};
  border-radius: 25px;
  font-weight: bold;
  height: 46px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-align: center;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: ${(props) => (props.active ? "#fafafa" : "#000")};
  outline: none !important;
  box-shadow: 0 0 0 0rem #ffffff00;

  :focus {
    outline: none !important;
    box-shadow: 0 0 0 0.2rem #bfdeff;
  }
`

export const SharedDivider = styled.div`
  width: 100%;
  height: ${(props) => (props.count ? `${props.count * 5}em` : "5em")};
  @media (max-width: 1300px) {
    height: ${(props) => (props.count ? `${props.count * 3.75}em` : "3.75em")};
  }
  @media (max-width: 900px) {
    height: ${(props) => (props.count ? `${props.count * 2.5}em` : "2.5em")};
  }

  min-height: ${(props) => (props.count ? `${props.count * 25}px` : "25px")};
`

export const SharedListElement = styled.div`
  // display: grid;
  // grid-template-columns: 1em 1fr;
  // grid-column-gap: 0.6em;
  display: flex;
  align-items: flex-start;
  img {
    margin-top: 0.2em;
    max-width: 20px;
  }
`

export const SharedDisorderSimpleText = styled.p`
  margin: 0;
  color: #505050;
  margin-left: .6em;
`

export const SharedDisorderHeaderText = styled.h4`
  margin: 0;
  color: #505050;
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0.5em;
`

export const SharedDisorderBackground = styled.div`
  background-color: #f7f7f7;
  padding: 1.1em;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 48.3%;

  @media (max-width: 1200px) {
    width: 95%;
  }
`

export const SharedAccordionBackground = styled.div`
  background-color: #f7f7f7;
  padding: 1.1em;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 97.6%;
  margin: auto;
`

export const SharedSummaryResponsesList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;

  > div:nth-of-type(even) {
    background-color: #f8fafa;
  }

  > div:nth-of-type(odd) {
    background-color: #f2fafa;
  }
`

export const SharedSummaryResponsesBlock = styled.div`
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;

  h3 {
    color: #383940;
    font-weight: 500;
    font-size: 1.1em;
    margin: 0;
  }

  p {
    font-size: 1.1em;
    line-height: 24px;
    color: #505050;
    margin: 0;
  }
`

export const SharedSummaryProgressBar = styled.div`
  background: #e7e7e7;
  border-radius: 6px;
  height: 12px;
`

export const SharedSummaryProgressBarInnerLine = styled.div`
  height: 100%;
  background: #33ca8f;
  border-radius: 6px;
  width: ${(props) => `${props.width}%`};
`

export const SharedDisorderMatrix = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  border-radius: 4px;
  color: #505050;
  border: 1px solid #dfdfdf;

  > div {
    border-top: 1px solid #dfdfdf;
    display: grid;
    grid-template-columns: 2fr 1fr;
    min-height: 2.2em;
    align-items: center;
    padding: 10px 14px;
    font-size: 1.15em;

    p {
      margin: 0;
    }
  }

  > div:nth-of-type(even) {
    background-color: #fff;
  }

  > div:nth-of-type(odd) {
    background-color: #f6f7f7;
  }

  > div:nth-of-type(1) {
    border-top: 0;
    background-color: #dfdfdf;

    p {
      font-weight: 600;
      font-size: 0.8em;
      text-transform: uppercase;
      color: #69696f;
    }
  }
`

export const ComorbidityMatrix = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  border-radius: 4px;
  color: #505050;
  border: 1px solid #dfdfdf;

  > div {
    border-top: 1px solid #dfdfdf;
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 2.2em;
    align-items: center;
    padding: 10px 14px;
    font-size: 1.15em;

    p {
      margin: 0;
    }
  }

  > div:nth-of-type(even) {
    background-color: #fff;
  }

  > div:nth-of-type(odd) {
    background-color: #fff9f5;
  }

  > div:nth-of-type(1) {
    border-top: 0;
    background-color: #dcf0f0;

    p {
      font-weight: 600;
      font-size: 0.8em;
      text-transform: uppercase;
      color: #69696f;
    }
  }
`