import React from "react"
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer"
import { sendAssessmentPDFEmail } from "../../api/email"
import date from "date-and-time"
import { makeassessmentpdf } from "../../Modules/PAP/PAPMakeAssessment"
import generatePassword from '../../utils/generatePassword';

// Font.register({ family: 'CircularStd-Book',src:font, fontStyle: 'normal', fontWeight: 'heavy'});

const styles = StyleSheet.create({
  page: {
    flexDirection: "",
    backgroundColor: "white",
    padding: 20,
    // fontFamily: "Roboto"
  },
  header: {
    fontSize: 18,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 15,
  },
  small: {
    fontSize: 12,
  },
  sectionWrapper: {
    padding: 5,
    borderWidth: 1,
    borderColor: "gainsboro",
    flexDirection: "column",
    width: "100%" - 20,
    borderRadius: 10,
    marginBottom: 10,
  },
  questionText: {
    fontSize: 11,
    color: "grey",
    marginBottom: 5,
  },
  answerText: {
    fontSize: 11,
    color: "black",
    fontWeight: "bold",
  },
  sectionHeader: {
    fontSize: 18,
    marginBottom: 5,
  },
})

const MyDocument = ({ userData, lang }) => (
  <Document>
    {userData ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.headerContainer}>
          <Text style={styles.header}>
            {"Assessment Details for "}
            {date.format(new Date(userData.completed_at), "MMM DD, YYYY")}
            <Text style={styles.small}>
              {" at "}
              {date.format(new Date(userData?.completed_at), "hh:mm A")}
            </Text>
          </Text>
        </View>

        {/* SCREENING RESPONSES */}
        <View wrap={false} style={styles.sectionWrapper}>
          <Text style={styles.sectionHeader}>General Questions</Text>
          {userData.screening_question_responses.map((question, i) => {
            return (
              <View key={i} style={{ flexDirection: "row" }}>
                <Text style={styles.questionText}>
                  {question.question[lang].text}
                  <Text style={styles.answerText}>
                    {question.response ? " Yes" : " No"}
                  </Text>
                </Text>
              </View>
            )
          })}
        </View>

        {/* IN DEPTH RESPONSES */}
        {userData.in_deplth_response.map((indepth, i) => {
          return (
            <View key={i} wrap={false} style={styles.sectionWrapper}>
              <Text style={styles.sectionHeader}>Disorder: {indepth.name === 'Mania/Hypomania' ? 'Bipolar Disorder' : indepth.name}</Text>
              {indepth[indepth.name].map((question, i) => {
                const responses = [
                  "Never",
                  "Rarely",
                  "Sometimes",
                  "Frequently",
                  "All the time",
                ]
                return (
                  <View key={i} style={{ flexDirection: "row" }}>
                    <Text style={styles.questionText}>
                      {question.question[lang].text + " "}
                      <Text style={styles.answerText}>
                        {responses[question.response]}
                      </Text>
                    </Text>
                  </View>
                )
              })}
            </View>
          )
        })}

        {/* HEALTH HISTORY RESPONSES */}
        <View wrap={false} style={styles.sectionWrapper}>
          <Text style={styles.sectionHeader}>Health History</Text>
          {userData.health_history_responses.map((question, i) => {
            return (
              <View key={i} style={{ flexDirection: "row" }}>
                <Text style={styles.questionText}>
                  {question.question[lang].text + " "}
                  <Text style={styles.answerText}>
                    {question.response.length > 0
                      ? question.response.map(
                          (hhResponse, index) =>
                            (index != 0 ? ", " : "") + hhResponse[lang]
                        )
                      : "No"}
                  </Text>
                </Text>
              </View>
            )
          })}
        </View>

        {/* LIFE FUNCITON RESPONSES */}
        <View style={styles.sectionWrapper}>
          <Text style={styles.sectionHeader}>Life Functions</Text>
          {userData.life_functions_responses.map((data, i) => {
            return (
              <View key={i} style={{ flexDirection: "row" }}>
                <Text style={styles.questionText}>
                  {data.question[lang].text + " "}
                  <Text style={styles.answerText}>
                    {data.question.question_type === "disablity"
                      ? data.response === -1
                        ? "No"
                        : data.response.text[lang]
                      : data.question.question_type === "percent"
                      ? data.response === -1
                        ? "0/100"
                        : `${data.response}/100`
                      : data.response === -1
                      ? "0/60"
                      : `${data.response}/60`}
                  </Text>
                </Text>
              </View>
            )
          })}
        </View>

          {/*  FOLLOW UP RESPONSES */}
        <View wrap={false} style={styles.sectionWrapper}>
          <Text style={styles.sectionHeader}>Follow Up</Text>
          {userData.follow_up_responses.map((question, i) => {
            return (
              <View key={i} style={{ flexDirection: "row" }}>
                <Text style={styles.questionText}>
                  {question.question[lang].text + " "}
                  <Text style={styles.answerText}>
                    {!Array.isArray(question.response) ? question.response : question.response.length > 0
                      ? question.response.map(
                          (hhResponse, index) =>
                            (index != 0 ? ", " : "") + hhResponse[lang]
                        )
                      : "No"}
                  </Text>
                </Text>
              </View>
            )
          })}
        </View>

      </Page>
    ) : null}
  </Document>
)

export const DownloadDetails = ({
  userData,
  children,
  lang = "en",
  assessmentDetailsVisited,
  onClick = () => {},
}) => {
  const downloadDetails = async () => {
    // const blob = await pdf(
    //   <MyDocument userData={userData} lang={lang} />
    // ).toBlob()
    let passwordChanged = false;
    if(!userData.user || !userData.user.pdfPassword) {
      let autoGeneratedPassword = generatePassword();
      userData.user.pdfPassword = autoGeneratedPassword;
      passwordChanged = true;
    }
    const blob = await makeassessmentpdf(userData, // needed
      'assessmentDisorders', // <=== not needed
      'fullHistory', // <=== not needed
      "getBlob",  // <=== needed
      'state', // <== not needed
      passwordChanged, // <=== needed
      false // needed but optional
    );
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "AssessmentDetails.pdf"
    link.click()
  }
  const myOnClick = () => {
    downloadDetails()
    onClick()
  }
  return (
    <div style={{ color: assessmentDetailsVisited ? '#28996c' : '#33ca8f' }} onClick={myOnClick}>
      {children}
    </div>
  )
}
export const PrintMyDetails = ({ userData, children, lang = "en" }) => (
  <PDFDownloadLink
    style={{ color: "#33ca8f" }}
    document={<MyDocument userData={userData} lang={lang} />}
    fileName="AssessmentDetails.pdf"
  >
    {children}
  </PDFDownloadLink>
)

export const emailDetails = async ({
  userData,
  email,
  lang = "en",
  assessment_id,
}) => {
  const blob = await pdf(
    <MyDocument userData={userData} lang={lang} />
  ).toBlob()
  var reader = new FileReader()
  reader.onloadend = async function () {
    var base64data = reader.result.split(",")[1]
    sendAssessmentPDFEmail(email, base64data, assessment_id)
  }
  reader.readAsDataURL(blob)
}
