import React, { Component, useContext, useEffect, useState }  from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { DisorderList } from "../summary/DisorderList"
import MagicGrid from "magic-grid-react"
import _get from "lodash/get"
import _map from "lodash/map"
import _filter from "lodash/filter"
import { Description2 } from "../summary/Description2"
import DisorderTherapyModal from "./../summary/DisorderTherapyModal"
import { baseUrl } from "../../lib/constants"
import axios from 'axios'

export const PapAssessmentDisordersMagicGrid = ({
  description,
  symptoms,
  clinical_steps,
  medicationadvice,
  talk_therapy,
  talk_therapy_point_ids,
  support,
  lang,
  tips,
  t,
  selectedDisorder,
  i18n
}) => {
  let gridItems = []

  const [ therapyData, setTherapyData ] = useState({
    loading: true, 
    data: {},
    error: '',
    completed: false
  })

  useEffect(() => {
    axios.post(`${baseUrl}/education/get/all`,{}, {
      withCredentials: true,
      mode: "cors",
    })
    .then(res => {
      const { data } = res
      setTherapyData({
        loading: false, 
        data,
        error: '',
        completed: true
      })
    })
    .catch(error => {
      console.error(error)
      setTherapyData({
        loading: false, 
        data: {},
        error: error.message,
        completed: true
      })
    })
  }, [])

  if (description) {
    gridItems = [
      ...gridItems,
      () => {
        return (
          <Description2
            selectedDisorder={selectedDisorder}
            texts={[_get(description, `out.high`)]}
            caption={t("papPage.desc")}
            language={lang}
          />
        )
      },
    ]
  }

  if (support) {
    gridItems = [
      ...gridItems,
      () => {
        return <DisorderList list={support} caption={t("papPage.support")} lang={lang} />
      },
    ]
  }

  if (symptoms) {
    gridItems = [
      ...gridItems,
      () => {
        return <DisorderList list={symptoms} caption={t("papPage.symptoms")} lang={lang} />
      },
    ]
  }

  if (clinical_steps) {
    gridItems = [
      ...gridItems,
      () => {
        return (
          <DisorderList
            list={clinical_steps}
            caption={t("papPage.clinicianSteps")}
            lang={lang}
          />
        )
      },
    ]
  }
  // if (medicationadvice) {
  //   gridItems = [
  //     ...gridItems,
  //     () => {
  //       return (
  //         <DisorderList
  //           list={medicationadvice}
  //           caption={t("papPage.medicationAdvice")}
  //           lang={lang}
  //         />
  //       )
  //     },
  //   ]
  // }
  const [currentTherapyModal, setCurrentTherapyModal] = useState('Cognitive Behavioural Therapy (CBT)');
  if ((Array.isArray(talk_therapy) && talk_therapy.length > 0) || (Array.isArray(talk_therapy_point_ids) && talk_therapy_point_ids.length > 0)) {
    gridItems = [
      ...gridItems,
      () => {
        return (
          <>
          <DisorderList
            caption={t("papPage.talk")}
            list={talk_therapy}
            lang={lang}
            handleTherapyModalClick={
              setCurrentTherapyModal
            }
          />
          <DisorderTherapyModal lang={lang } therapyName={currentTherapyModal} data={therapyData} />
          </>
        )
      },
    ]
  }
  if (tips) {
    gridItems = [
      ...gridItems,
      () => {
        return (
          <DisorderList
            list={tips}
            caption={t("papPage.selfCare")}
            lang={lang}
          />
        )
      },
    ]
  }
  return (
    <MagicGrid
      maxColumns={2}
      gutter={16}
      items={gridItems.length}
      useMin={true}
      animate={false}
    >
      {gridItems.map((i) => {
        return i()
      })}
    </MagicGrid>
  )
}
