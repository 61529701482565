import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import GreenCheckmarkSVG from "../../assets/images/green-checkmark.svg"
import { Link } from "react-router-dom"
import {
  SharedListElement,
  SharedDisorderBackground,
  SharedDisorderSimpleText,
  SharedDisorderHeaderText,
} from "../../utils/shared"

export const DisorderList = ({
  lang,
  list,
  caption,
  handleTherapyModalClick,
  setDefaultTasks,
  defaultTasks = []
}) => {

  const [addTaskLoading, setAddTaskLoading] = useState(null)

  const handleAddTask = async (i, index) => {
    console.log(i);
    setAddTaskLoading(index)
    try {
      const response = await axios.post(`${baseUrl}/plans-goals/goal-default`, {
        title: i.text
      })
      setDefaultTasks(response.data)
    } catch (e) {
      console.log(e)
    }finally{
      setAddTaskLoading(null)
    }

  }

  return (
    <SharedDisorderBackground style={{ position: "absolute" }}>
      <SharedDisorderHeaderText>{caption}</SharedDisorderHeaderText>
      <div
        css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0.6em;
        word-break: break-word;
      `}
    >
      
      {list.map((i, index) => {
        if (caption === "Therapy" || caption === "Thérapie") {
          return (
            <Link data-toggle="modal" data-target="#therapyModal">
              <SharedListElement key={`${caption}${i.text ? i.text[lang] : i.title[lang]}${index}`}>
                <img src={GreenCheckmarkSVG} />
                <a
                  style={{marginLeft: '.6em'}}
                  className="therapy-education-links"
                  onClick={() => {
                    handleTherapyModalClick(i.text ? i.text['en'] : i.title['en'])
                  }}
                >
                  {i.text ? i.text[lang] : i.title[lang]}
                </a>
              </SharedListElement>
            </Link>
          )
        } else if(caption == 'Soutien' || caption == 'Support'){
          return <SharedListElement key={`${caption}${i['title'] ? i['title'][lang] : (i['text'] ? i['text'][lang] : '')}${index}`}>
                  <img src={GreenCheckmarkSVG} />
                  <SharedDisorderSimpleText>
                  {i.link ? 
                    <a className="therapy-education-links" target="_blank" href={i.link}>
                      {i.text ? i.text[lang] : i.title[lang]}
                    </a>
                  : 
                    i.text ? i.text[lang] : i.title[lang]
                  }
                </SharedDisorderSimpleText>
                </SharedListElement>
                // <strong>
                //   {i['title'][lang]}
                // </strong>
        } else if(caption === 'Talk Therapy' || caption === 'Thérapie par la parole'){
            var therapyData = {'therapy': i.text ? i.text['en'] : i.title['en']};
            return (
              <Link data-toggle="modal" data-target="#therapyModal" onClick={e => e.preventDefault()}>
                <SharedListElement key={`${caption}${i.text ? i.text[lang] : i.title[lang]}${index}`}>
                  <img src={GreenCheckmarkSVG} />
                  <a
                    style={{marginLeft: '.6em'}}
                    className="therapy-education-links"
                    onClick={() => {
                      handleTherapyModalClick(therapyData)
                    }}
                  >
                    {i.text ? i.text[lang] : i.title[lang]}
                  </a>
                </SharedListElement>
              </Link>
            )
          }

          else{
            // console.log('iiiii', i)
            if(i['object']) {
              return <>

                  <div style={{backgroundColor: "white"}}>
                    <div className="descriptionList">
                      <strong>{i['object']['title'][lang]}</strong>
                      {i['object']['list'].map((innerList) => {
                        return <SharedListElement key={`${caption}${innerList.text ? innerList.text[lang] : innerList.title[lang]}${index}`}>
                          <img src={GreenCheckmarkSVG} />
                          <SharedDisorderSimpleText>  
                          {innerList.link ? 
                              <a className="therapy-education-links" target="_blank" href={innerList.link}>
                                {innerList.text ? innerList.text[lang] : innerList.title[lang]}
                              </a>
                            : 
                            innerList.text ? innerList.text[lang] : innerList.title[lang]
                            }
                          </SharedDisorderSimpleText>
                        </SharedListElement>
                      })}
                    </div>
                  </div>
              </>
            } 
            else if(i['text']) {
              return <SharedListElement key={`${caption}${i.text ? i.text[lang] : i.title[lang]}${index}`}>
                  <img src={GreenCheckmarkSVG} />
                    <SharedDisorderSimpleText>
                      {i.link ? 
                        <a className="therapy-education-links" target="_blank" href={i.link}>
                          {i.text ? i.text[lang] : i.title[lang]}
                        </a>
                      : 
                        i.text ? i.text[lang] : i.title[lang]
                      }
                    </SharedDisorderSimpleText>
                </SharedListElement>
            }
          }
      })}
    </div>
  </SharedDisorderBackground>
)
}
