import React, { useEffect } from "react"
// import "../css/ClientAccessCode.css"
import "../../css/privacyPolicy.css"
import { SharedModalBackground } from "../../utils/shared"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import LogoSVG from "../../assets/images/fbn-logo.svg"
import CloseImg from "../../assets/images/close.svg"
import OutsideClickHandler from "react-outside-click-handler"
import styled from "@emotion/styled"
const store = require("store")

const EModal = styled.div`
  min-width: 15em;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 7px 20px 2px rgba(0, 0, 0, 0.4)"};
  background-color: #fff;
  border-radius: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 82vh;
  width: 700px;
  position: relative;
  font-family: "CircularStdBook", sans-serif;
  transform: scale(1.3);
  @media (max-width: 640px) {
    width: 70vw;
  }
`

const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const GreyLine = styled.div`
  border: 1px solid #ececec;
`

const EModalText = styled.p`
  font-family: "CircularStdBook", sans-serif;
  font-size: 1.3em;
  font-weight: 400;
  color: #505050;
  margin: 0;
`

const EInputWrapper = styled.div`
  position: relative;
  padding: 0;
  height: 3.2em;
`

const EInput = styled.input`
  padding-left: 2.4em;
  border: 1px solid #d1d1d1;
  border-radius: 0.4em;
  outline: none;
  font-size: 1.2em;
  z-index: 2;
  background-color: transparent;
  height: 100%;
  width: 100%;

  ::placeholder {
    color: #c8c8c8;
  }
`
const EInputImg = styled.img`
  width: 1.3em;
  justify-self: center;
  position: absolute;
  left: 0.7em;
  font-size: 1.1em;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
`

const EButton = styled.button`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  color: ${(props) => (props.selected ? "#383940" : "#afafb1")};
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  position: relative;
  text-align: initial;
  transition: all 0.2s;
  min-height: 3.2em;
  width: 100%;
  :focus {
    outline: 0;
  }
`

export default ({ show, handleClose, onHide, t, i18n }) => {
  
    useEffect(() => {
    if(i18n)
      i18n.changeLanguage(store.get('lang') || 'en')
  }, [])
  
  return (
    <>
      {show && (
        <>
          <SharedModalBackground />

          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 1101;

              @media (max-width: 640px) {
                font-size: 0.8em;
              }

              @media (max-height: 1000px) {
                font-size: 0.7em;
              }
            `}
          >
            <OutsideClickHandler 
                onOutsideClick={() => {
                    handleClose()
                }}
            >
              <EModal>
                <>
                  <EModalContent className="mb-3">
                    <div
                      css={css`
                        padding: 1.5em 2em;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      `}
                    >
                      <img
                        src={LogoSVG}
                        alt="logo"
                        css={css`
                          width: 15em;
                        `}
                      />
                      <img
                        src={CloseImg}
                        onClick={() => {
                          onHide()
                        }}
                        css={css`
                          height: 1.2em;
                          cursor: pointer;
                        `}
                      />
                    </div>

                    <GreyLine />
                      <>
                        <EModalText
                          css={css`
                            margin: 0.6em 2em 0 2em;
                          `}
                        >
                                {t('startupPopup.description1')}<a style={{ color: '#33ca8f' }} href="mailto:info@feelingbetternow.com" target="_self">info@feelingbetternow.com</a>{t('startupPopup.description2')}
                        </EModalText>
                      </>
                  </EModalContent>
                </>
              </EModal>
            </OutsideClickHandler>
          </div>
        </>
      )}
    </>
  )
}
