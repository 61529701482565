import React, { useContext, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import x2 from "../../assets/images/white-x.svg"
import styled from "@emotion/styled"
import { getRiskStatus } from "../../utils/get-risk.js"
import { StateContext } from "../../store/state/contexts"
import { Link } from "react-router-dom"
import { baseUrl } from "../../lib/constants"
import _map from "lodash/map"
import _get from "lodash/get"
import _reverse from "lodash/reverse"
import _filter from "lodash/filter"
import {
  AccordionAssessmentsHistory,
  AccordionAssessmentsHistoryIncomplete,
} from "./AccordionAssessmentsHistory"
import date from "date-and-time"
import { isArray } from "lodash"
import { render } from "@testing-library/react"
import { distanceAndSkiddingToXY } from "@popperjs/core/lib/modifiers/offset"
import { isMacOs } from "react-device-detect"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"


const EDrawerItself = styled.div`
  background-color: #fff;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 16px 0px 20px rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 50vw;
  max-width: 700px;
  position: relative;
  overflow-y: auto;
  font-size: 0.88em;

  @media (max-width: 1100px) {
    width: 100vw;
    max-width: unset;
    box-shadow: none;
  }
`

const EClose = styled.button`
  overflow: 0;
  padding: 0px;
  width: 0.8em;
  height: 0.8em;
  background: transparent;
  border: 0;
  right: 30px;
  top: 0px;
  outline: 0;
`

const EHeader = styled.h2`
  color: #fff;
  font-size: 1.33em;
  color: #ffffff;
  font-family: "CircularStdBook";
  margin: 0;
`

const AssessmentHistoryNewObj = ({ t, i18n, handleClose } ) => {
  const [inProgress, setInProgress] = useState(false)
  const { state, dispatch } = useContext(StateContext)
  const [isMacos, setIsMacos] = useState(false)

  const getAllData = async () => {
    try {
      var response = await fetch(baseUrl + "/assessment/history", {
        credentials: "include",
        mode: "cors",
      })
      let history = await response.json()
      if (response.status === 200) {
        logEvent("AssessmentHistoryNew /assessment/history successful")
        dispatch({ type: "SET_HISTORY_DATA", payload: history })
      } else {
        logEvent("AssessmentHistoryNew /assessment/history failed")
      }
    } catch (err) {
      logEvent("AssessmentHistoryNew /assessment/history failed", err)
      console.log(err)
    }
  }

  const getCurrentAssessment = async () => {
    try {
      var res = await fetch(baseUrl + "/assessment/inprogress", {
        credentials: "include",
        mode: "cors",
        method: "GET",
      }).then((res) => res.json())
  
      if (res == "") {
        logEvent("getCurrentAssessment /assessment/inprogress failed")
        setInProgress(false)
      } else {
        logEvent("getCurrentAssessment /assessment/inprogress successful")
        dispatch({ type: "SET_CURRENT_ASSESSMENT", payload: res })
        setInProgress(true)
      }
    } catch (err) {
      setInProgress(false)
      console.log(err);
      logEvent("getCurrentAssessment /assessment/inprogress failed", err)
    }
  }

  useEffect(() => {
    getAllData()
    getCurrentAssessment()
    setIsMacos(isMacOs)
  }, [])

  useEffect(() => {
    getCurrentAssessment()
    // console.log("inProgress: " + inProgress)
  }, [inProgress])

  return (
    <>
      <EDrawerItself isMacos={isMacos}>
        <div
          css={css`
            padding: 1.3em 1em;
            background: #33ca8f;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <EHeader>{t("assessmentHistory.title")}</EHeader>

          <EClose
            onClick={() => {
              handleClose()
            }}
          >
            <img src={x2} alt="close btn" />
          </EClose>
        </div>

        <div
          css={css`
            padding: 1.2em 1.2em;
            background: #32b682;
            display: grid;
            grid-template-columns: 10fr 9fr;
            grid-column-gap: 1fr 1fr;
            font-size: 0.66em;
          `}
        >
          <EHeader>{t("assessmentHistory.date")}</EHeader>
          <EHeader>{t("assessmentHistory.status")}</EHeader>
        </div>

        <section
          css={css`
            overflow-y: scroll;
            height: 100%;
          `}
        >
          {_map(state.currentAssessment, (a, b) => {
            const datestr = date.format(
              new Date(state.currentAssessment.insertDate),
              "ddd, MMM DD, YYYY hh:mm A"
            )
            if (inProgress == true && b == "insertDate") {
              return (
                <AccordionAssessmentsHistoryIncomplete
                  headerDate={datestr}
                  isCompleted={false}
                  onClick={() =>handleClose()}
                  key={b}
                >
                  {" "}
                  <Link
                    to={`/assessment`}
                    css={css`
                      padding: 0.7em 1em 0.9em 1em;
                      display: block;
                      color: #505050;
                      font-weight: 500;
                      :hover {
                        color: #505050;
                      }
                    `}
                  >
                    {t("assessmentHistory.clickToContinue")}
                  </Link>
                </AccordionAssessmentsHistoryIncomplete>
              )
            } else {
              return <div key={b}></div>
            }
          })}

          {_map(_reverse(state.historyData && state.historyData.sort((a, b) => new Date(a.completed_at) - new Date(b.completed_at))), (j, index) => {
            const datestr = date.format(
              new Date(j.completed_at),
              "ddd, MMM DD, YYYY hh:mm A"
            )

            return (
              <AccordionAssessmentsHistory
                key={`${j._id}${datestr}${index}`}
                headerDate={datestr}
                isCompleted={j.completed}
                completedAt={j.completed_at}
                insertDate={j.insertDate}
                scores={j.scores}
                id={j._id}
              >
                {_get(
                  _map(_filter(_get(j, "scores"), (oi) => isArray(oi))),
                  "length"
                ) === 0 ? (
                  <Link
                    onClick={() => { handleClose() }}
                    to={`/summary/${j._id}`}
                    css={css`
                      padding: 0.7em 1em 0.9em 1em;
                      display: block;
                      color: #505050;
                      font-weight: 500;
                      :hover {
                        color: #505050;
                      }
                    `}
                  >
                    {t("assessmentHistory.notAtRisk")}
                  </Link>
                ) : (
                  <div
                  onClick={() => { handleClose() }}
                    css={css`
                      padding: 0.7em 1em 0.9em 1em;
                    `}
                  >
                    {_map(
                      _filter(_get(j, "scores"), (i) => isArray(i)),
                      (i, index, arr) => (
                        <Disorder
                          key={index}
                          headerText={i[0]}
                          score={i[1]}
                          isLast={1 + index === arr.length}
                          id={j._id}
                        />
                      )
                    )}
                  </div>
                )}
              </AccordionAssessmentsHistory>
            )
          })}

          {!(state?.historyData?.length > 0) && !(state?.currentAssessment?.insertDate) &&
          <div
          css={css`
            padding: 0.7em 1em 0.9em 1em;
            display: block;
            color: #505050;
            font-weight: 500;
            :hover {
              color: #505050;
            }
          `}
        >
          {t("assessmentHistory.notCompletedAssessment")}
          <p>{t('assessmentHistory.notCompletedAssessmentMeridian')}</p>
        </div>}
        </section>
      </EDrawerItself>
    </>
  )
}

const EAccordionButtonLink = styled.div`
  outline: 0 !important;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr 2fr;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: ${(props) => (props.isLast ? "1px solid #eaeaea" : "unset")};
  padding: 0.3em 1em;
  font-family: "CircularStdBook", Arial, Helvetica, sans-serif;

  h3,
  h4 {
    font-size: 1em;
    margin: 0;
    color: #505050;
    display: block;
    font-weight: 500;
  }

  img {
    transition: all 0.2s;
  }
`

const ECircle = styled.div`
  width: 0.5em;
  height: 0.5em;
  background-color: ${(props) =>
    props.riskStatus === "High Risk"
      ? "#FF5656"
      : props.riskStatus === "Moderate Risk"
      ? "#FFD556"
      : "#3BC653"};
  border-radius: 100%;
  margin-right: 0.6em;
`

const ERiskStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 1em;
    margin: 0;
    color: #505050;
  }
`

const DisorderEle = ({ headerText, score, isLast, id, t}) => {
  const riskStatusStr = getRiskStatus(headerText, 100 * score)

  return (
    <Link to={`/summary/${id}`}>
      <EAccordionButtonLink
        isLast={isLast}
      >
        <h3>{headerText === 'Mania/Hypomania' ? 'Bipolar Disorder' : headerText}</h3>

        {!!riskStatusStr && (
          <ERiskStatusWrapper>
            <ECircle riskStatus={riskStatusStr} />

            <p>{riskStatusStr}</p>
          </ERiskStatusWrapper>
        )}
        {!riskStatusStr && <div />}

        <h4
          css={css`
            font-weight: 300 !important;
            text-align: end;
          `}
        >
          {t("assessmentHistory.score")} {Math.round(score * 100)}%
        </h4>
      </EAccordionButtonLink>
    </Link>
  )
}

const Disorder = withTranslation()(DisorderEle);

export const AssessmentHistoryNew = withTranslation()(AssessmentHistoryNewObj);