import React, { useState, useEffect } from "react"
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap"
import { baseUrl } from "../../../lib/constants"
// import "../css/ClientAccessCode.css"
import "../../../css/privacyPolicy.css"
import { SharedModalBackground } from "../../../utils/shared"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { SupportedOrganizations } from "../../../lib/constants"
import { ClientAccessIdCompany } from "./ClientAccessIdCompany"
import axios from "axios"
import LogoSVG from "../../../assets/images/fbn-logo.svg"
import CloseImg from "../../../assets/images/close.svg"
import LeafsSVG from "../../../assets/images/leafs.svg"
import OutsideClickHandler from "react-outside-click-handler"
import styled from "@emotion/styled"
import { useInput, useFocus } from "react-hookedup"
import MailSVG from "../../../assets/images/mail.svg"
import PhoneSVG from "../../../assets/images/phone.svg"
import { EAngle, EDropDownWrapper, EBtnElement } from "../../form/DropDownInput"
import "../../.."
import { set } from "lodash"
import BuildingSVG from "../../../assets/images/building.svg"
import { logEvent } from "../../../index"
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import clientInfo from '../../../redirects/clientRedirects.json';
const store = require("store")

const EModal = styled.div`
  min-width: 15em;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 7px 20px 2px rgba(0, 0, 0, 0.4)"};
  background-color: #fff;
  border-radius: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 82vh;
  width: 600px;
  position: relative;
  font-family: "CircularStdBook", sans-serif;

  @media (max-width: 640px) {
    width: 90vw;
  }
  @media (max-width:320px){
    max-height: 95vh;
  }
`

const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const GreyLine = styled.div`
  border: 1px solid #ececec;
`

const EModalText = styled.p`
  font-family: "CircularStdBook", sans-serif;
  font-size: 1.2em;
  color: #505050;
  margin: 0;
`

const EInputWrapper = styled.div`
  position: relative;
  padding: 0;
  height: 3.2em;
`

const EInput = styled.input`
  padding-left: 2.4em;
  border: 1px solid #d1d1d1;
  border-radius: 0.4em;
  outline: none;
  font-size: 1.2em;
  z-index: 2;
  background-color: transparent;
  height: 100%;
  width: 100%;

  ::placeholder {
    color: #c8c8c8;
  }
`
const EInputImg = styled.img`
  width: 1.3em;
  justify-self: center;
  position: absolute;
  left: 0.7em;
  font-size: 1.1em;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
`

const EButton = styled.button`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  color: ${(props) => (props.selected ? "#383940" : "#afafb1")};
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  position: relative;
  text-align: initial;
  transition: all 0.2s;
  min-height: 3.2em;
  width: 100%;
  :focus {
    outline: 0;
  }
`

export const ClientAccessId = ({ show, handleClose, onHide, t, i18n, setshowMeridinPopup }) => {
  const [openClientDetails, setOpenClientDetails] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (i18n)
      i18n.changeLanguage(store.get('lang') || 'en')
  }, [])
  // const { t, i18n, customWidth, customMaxWidth, education_id } = props
  const [companyName, setCompanyName] = useState("")
  const codeInput = useInput("")
  const phoneInput = useInput("")
  const companyInput = useInput("")
  const emailInput = useInput("")
  const [companiesDDOpen, setCompaniesDDOpen] = useState(false)
  const [isContactValid, setIsContactValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [currentLink, setCurrentLink] = useState('')
  const [supportedOrganizations, setSupportedOrganizations] = useState(
    SupportedOrganizations.map((i) => ({ caption: i, selected: false }))
  )

  const handleCompanyClick = (caption) => {
    setSupportedOrganizations(
      supportedOrganizations.map((i) => ({
        ...i,
        selected: i.caption === caption ? true : false,
      }))
    )
  }

  const selectedCompany =
    supportedOrganizations.find((i) => !!i.selected) &&
    supportedOrganizations.find((i) => !!i.selected).caption

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onSubmit = async (type) => {
    if (type === 1) {
      if (codeInput.value !== "") {
        try {
          await fetch(`${baseUrl}/user/accesscode/${codeInput.value}`, {
            credentials: "include",
            mode: "cors",
            method: "POST",
          })
        } catch (err) {
          console.log(err);
        }
        logEvent("onSubmit /user/accesscode successful")
        handleClose()
      } else {
        setIsError(true)
      }
    } else if (type === 2) {
      if (
        !companyInput.value ||
        !phoneInput.value ||
        !emailInput.value
      ) {
        return alert("Invalid inputs passed. Please enter all details correctly.")
      }
      if (!isValidPhoneNumber(phoneInput.value)) {
        return setIsContactValid(false)
      } else {
        setIsContactValid(true)
      }

      if (!validateEmail(emailInput.value)) {
        return setIsEmailValid(false)
      } else {
        setIsEmailValid(true)
      }
      try {
        await fetch(`${baseUrl}/clients`, {
          credentials: "include",
          mode: "cors",
          body: JSON.stringify({
            companyName: companyInput.value,
            phone: phoneInput.value,
            email: emailInput.value,
          }),
          method: "POST",
          headers: { "content-type": "application/json" },
        })
          .then(() => {
            logEvent("onSubmit /clients successful")
            alert("Success")
          })
          .catch(() => {
            logEvent("onSubmit /clients failed")
            alert("Error")
          })
      } catch (err) {
        console.log(err);
      }
      handleClose()
    }
  }

  useEffect(() => {
    return () => {
      setCurrentLink('');
    }
  }, [])
  const handleClientAccessCodeSubmit = () => {
    // if (clientAccessIds.includes(codeInput.value)) {
    let clnt = clientInfo.find(client => client.Alias == `/${codeInput.value}`)
    if (!clnt) {
      return alert('Invalid Access Code. Please contact your employer or school organization for the correct code.')
    }
    return setCurrentLink(clnt.URL)
    axios
      .post(`${baseUrl}/user/accesscode/${codeInput.value}`, undefined, {
        withCredentials: true,
        mode: "cors",
      })
      .then(() => {
        logEvent("handleClientAccessCodeSubmit /user/accesscode called")
        // handleClose()
        let clnt = clientInfo.find(client => client.Alias == `/${codeInput.value}`)
        setCurrentLink(clnt.URL)
      }).catch(err => {
        logEvent("handleClientAccessCodeSubmit /user/accesscode error")
        console.log("file======================= handleClientAccessCodeSubmit === popups/accessCode/ClientAccessId.jsx =======")
        console.log(err)
        if (codeInput.value.toLowerCase() == 'meridian') {
          setshowMeridinPopup(true)
        } else {
          alert(
            "Invalid Access Code. Please contact your employer or school organization for the correct code."
          )
        }
        // handleClose()
      })
    // } else {
    //   alert(
    //     "Invalid Access Code. Please contact your employer or school organization for the correct code."
    //   )
    // }
  }

  return (
    <>
      {show && (
        <>
          <SharedModalBackground />

          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 1100;

              @media (max-width: 640px) {
                font-size: 0.8em;
              }

              @media (max-height: 1000px) {
                font-size: 0.7em;
              }
            `}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpenClientDetails(false)
              }}
            >
              <EModal>
                <>
                  <EModalContent>
                    <div
                      css={css`
                        padding: 1.5em 2em;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      `}
                    >
                      <img
                        src={LogoSVG}
                        alt="logo"
                        css={css`
                          width: 15em;
                        `}
                      />
                      <img
                        src={CloseImg}
                        onClick={() => {
                          onHide()
                        }}
                        css={css`
                          height: 1.2em;
                          cursor: pointer;
                        `}
                      />
                    </div>

                    <GreyLine />

                    {!openClientDetails && (
                      <>
                        <EModalText
                          css={css`
                            margin: 1.5em 2em 0 2em;
                          `}
                        >{t("startAssessmentPopup.title")}</EModalText>
                        <EModalText
                          css={css`
                            font-weight: 600;
                            margin: 0.6em 2em 0 2em;
                          `}
                        >
                          {t('startAssessmentPopup.description')}
                        </EModalText>

                        {/* <div
                          css={css`
                            margin-top: 1.8em;
                            padding: 0 2em;
                          `}
                        >
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              setCompaniesDDOpen(false)
                            }}
                          >
                            <EButton
                              onClick={() => {
                                setCompaniesDDOpen(!companiesDDOpen)
                              }}
                              selected={!!selectedCompany}
                            >
                              <EAngle isOpen={companiesDDOpen} />

                              <p
                                css={css`
                                  margin: 0;
                                  font-size: 1.12em;
                                  font-size: #0d0d0d;
                                  font-weight: 500;
                                `}
                              >
                                {selectedCompany ? selectedCompany : t("startAssessmentPopup.companyLabel")}
                              </p>
                              {companiesDDOpen && (
                                <EDropDownWrapper>
                                  {supportedOrganizations.map((i) => (
                                    <EBtnElement
                                      key={`${i.caption}btnkey`}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleCompanyClick(i.caption)
                                        setCompaniesDDOpen(false)
                                      }}
                                      selected={i.selected}
                                    >
                                      {i.caption}
                                    </EBtnElement>
                                  ))}
                                </EDropDownWrapper>
                              )}
                            </EButton>

                          </OutsideClickHandler>
                        </div> */}

                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            handleClientAccessCodeSubmit()
                          }}
                        >
                          <div
                            css={css`
                              display: grid;
                              grid-template-columns: 1fr 9em;
                              margin: 0.8em 2em 2em 2em;
                              min-height: 3.2em;
                              position: relative;

                              @media (max-width: 640px) {
                                grid-template-columns: 1fr 5em;
                              }
                            `}
                          >
                            <img
                              src={LeafsSVG}
                              css={css`
                                width: 1.5em;
                                justify-self: center;
                                position: absolute;
                                left: 0.7em;
                                font-size: 1.1em;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                z-index: 1;
                              `}
                            />
                            <input
                              {...codeInput.bindToInput}
                              placeholder={t('startAssessmentPopup.placeHolder')}
                              type="text"
                              css={css`
                                padding-left: 2.4em;
                                border-left: 1px solid #d1d1d1;
                                border-top: 1px solid #d1d1d1;
                                border-bottom: 1px solid #d1d1d1;
                                border-top-left-radius: 0.4em;
                                border-bottom-left-radius: 0.4em;
                                outline: none;
                                border-right: 0;
                                font-size: 1.2em;
                                z-index: 2;
                                background-color: transparent;
                                min-width: 10em;
                                ::placeholder {
                                  color: #c8c8c8;
                                }
                              `}
                            />
                            <button
                              css={css`
                                background-color: #33ca8f;
                                color: #fff;
                                text-transform: capitalize;
                                font-family: "CircularStdBook", sans-serif;
                                padding: 0;
                                margin: 0;
                                outline: none;
                                border: 0;
                                border-top-right-radius: 0.4em;
                                border-bottom-right-radius: 0.4em;
                                letter-spacing: 0.06em;
                                :focus {
                                  outline: none;
                                }
                              `}
                              type="submit"
                            // onClick={() => {
                            //   handleClientAccessCodeSubmit()
                            // }}
                            >
                              {t('startAssessmentPopup.submit')}
                            </button>
                          </div>
                        </form>
                        {currentLink &&
                          <EModalText
                            css={css`
                            font-weight: 600;
                            margin: 0.6em 2em 1em 2em;
                          `}
                          >
                            {t('startAssessmentPopup.label')} <a style={{ color: '#33ca8f', wordBreak: 'break-word' }} href={currentLink}>{currentLink}</a>
                          </EModalText>
                        }
                      </>
                    )}


                    {openClientDetails && (
                      <>
                        <div
                          css={css`
                            font-weight: 600;
                            margin: 1.7em 2.2em 0em 2.2em;
                          `}
                        ></div>
                        <EModalText
                          css={css`
                            margin: 0.8em 2em 0 2em;
                          `}
                        >
                          FeelingBetterNow is provided through employers,
                          schools and government agencies. Please enter the
                          information for the HR department of your organization and
                          we will contact them and try to get you access. We
                          will not share any information about you or the request
                          with them.
                        </EModalText>

                        <div
                          css={css`
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-row-gap: 1em;
                            padding: 2em 2em 1.5em 2em;
                          `}
                        >
                          <EInputWrapper>
                            <EInputImg src={BuildingSVG} />
                            <EInput
                              type="text"
                              {...companyInput.bindToInput}
                              placeholder="Company"
                              maxLength={50}
                            />
                          </EInputWrapper>

                          <EInputWrapper>
                            <EInputImg src={PhoneSVG} />
                            <EInput
                              type="tel"
                              {...phoneInput.bindToInput}
                              placeholder="Phone Number"
                              maxLength={50}
                            />
                          </EInputWrapper>

                          <EInputWrapper>
                            <EInputImg src={MailSVG} />
                            <EInput
                              type="email"
                              {...emailInput.bindToInput}
                              placeholder="Email Address"
                            />
                          </EInputWrapper>

                          <EInputWrapper>
                            <button
                              css={css`
                                background-color: #33ca8f;
                                color: #fff;
                                text-transform: capitalize;
                                padding: 0;
                                margin: 0;
                                outline: none;
                                border: 0;
                                border-radius: 0.4em;
                                letter-spacing: 0.06em;
                                height: 100%;
                                width: 100%;

                                :focus {
                                  outline: none;
                                }
                              `}
                              onClick={() => {
                                onSubmit(2)
                              }}
                            >
                              SUBMIT
                            </button>
                          </EInputWrapper>
                          {!isContactValid &&
                            <p style={{ color: 'red' }}>Please enter valid phone number</p>
                          }
                          {!isEmailValid &&
                            <p style={{ color: 'red' }}>Please enter valid email</p>
                          }
                        </div>
                      </>
                    )}

                    {/* <GreyLine /> */}


                    {/* <div
                      css={css`
                        margin: 1.5em 2em;
                        align-self: flex-end;
                      `}
                    >
                      <p
                        css={css`
                          font-style: normal;
                          font-weight: normal;
                          font-size: 1.2em;
                          color: #33ca8f;
                          margin: 0;
                          cursor: pointer;
                        `}
                        onClick={() => {
                          setOpenClientDetails(!openClientDetails)
                        }}
                      >
                        {openClientDetails
                          ? "Have a client access code?"
                          : "Don’t have a client access code?"}
                      </p>
                    </div> */}
                  </EModalContent>
                </>
              </EModal>
            </OutsideClickHandler>
          </div>
        </>
      )}
    </>
  )
}
