import React, { Component, useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { baseUrl } from "../../lib/constants"
import "../../css/EducationModule.scss"
// import Navbar from "../../components/navbar/Navbar"
import Navbar from "../../components/navbar/EducationHeader"
import "../../css/main.css"
import Footer from "../../components/footer/Footer"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import EducationCardList from "./EducationCardList"
import "../../css/education.scss"
var store = require("store")
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    background: "white",
    borderRadius: 8,
    fontSize: 18,
    fontFamily: "CircularStdBook",
    padding: 10,
    height: "auto",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  Buttons: {
    padding: "0px !important",
  },
  menuItem: {
    textAlign: "center !important",
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const disorders = [
  // "Meridian",
  "Depression",
  "Bipolar Disorder",
  "Alcohol and Substance Abuse",
  "Panic Disorder",
  "Social Anxiety",
  "PTSD",
  "Obsessive Compulsive Disorder",
  "General Anxiety Disorder",
  "Eating Disorder",
  "ADHD",
  "Anger Problems",
  "Adjustment Disorder",
  "Sleep Disorder (Insomnia)",
  "Covid",
]

const listObject = {
  // "Meridian": "Meridian",
  ADHD: "adhd",
  "Adjustment Disorder": "adjustmentDisorder",
  "Alcohol and Substance Abuse": "alcohol",
  "Anger Problems": "angerProblems",
  "Bipolar Disorder": "bipolarDisorder",
  Depression: "depression",
  "Eating Disorder": "eatingDisorder",
  "General Anxiety Disorder": "gad",
  "Sleep Disorder (Insomnia)": "insomnia",
  "Obsessive Compulsive Disorder": "ocd",
  "Panic Disorder": "panicDisorder",
  PTSD: "ptsd",
  "Social Anxiety": "socialAnxiety",
  "Covid": "covid",
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

function SupportResources(props) {
  const [filters, setFilters] = useState([])
  const [selectedDisorder, setSelectedDisorder] = useState(disorders[0])
  const { t, i18n } = props;
  const [auth, setAuth] = useState(true)
  const classes = useStyles()
  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [resourcesData, setResourcesData] = useState([])
  const [data, setData] = useState([])
  const [bookmarks, setBookmarks] = useState([])
  const [bookmarksIds, setBookmarksIds] = useState([])
  const [renderBookmarks, setRenderBookmarks] = useState(false)
  const [bookmarksData, setBookmarksData] = useState([])
  const [selectFilter, setSelectFilter] = useState('');
  const [bookmarkClickedColour, setBookmarkClickedColour] = useState("#151852")
  const [resources, setResources] = useState([
    "Support Resources",
    // "Video Resources",
    "Websites",
    // "RSS Feeds",
    "Articles",
    "Forum",
    "Support Group",
    // "Games & Apps",
    // "Meridian"
  ])
  const [clientIdCode, setClientIdCode] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    i18n.changeLanguage(store.get('lang') || 'en')
    if (props.location && props.location.state && props.location.state.from == 'covid-bar') {
      setSelectedDisorder('Covid');
    }
    setLoading(true);
    fetch(baseUrl + `/user-profile`, {
      credentials: "include",
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // setResources([...resources, res.accessId])
        setClientIdCode(res.accessId)
        setBookmarksIds(res.bookmarks)
        // resources.unshift((res.organization && res.organization.organization_name) ? res.organization.organization_name : res.accessId)
        // setResources([...resources])
        setClientIdCode((res.organization && res.organization.organization_name) ? res.organization.organization_name : res.accessId)
        setBookmarksIds(res.bookmarks)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
  }, [])

  const handleChange = (event) => {
    setPersonName(event.target.value)
  }

  useEffect(() => {
    setResourcesData([])
    // setRenderBookmarks(false)
    let newData = []
    if (filters) {
      filters.map((filter) => {
        if (filters.includes(resources[0])) {
          console.log("contains accessId!")
          if (filter === "Support Resources") {
            data.supportResources && data.supportResources.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              )
                newData.push(resource)
            })
          }
          if (filter === "Games & Apps") {
            data.gamesApps && data.gamesApps.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              )
                newData.push(resource)
            })
          }
          if (filter === "Video Resources") {
            data.videoResources && data.videoResources.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              )
                newData.push(resource)
            })
          }
          if (filter === "Websites") {
            data.websites && data.websites.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              )
                newData.push(resource)
            })
          }
          if (filter === "RSS Feeds") {
            data.rssFeed && data.rssFeed.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              )
                newData.push(resource)
            })
          }
          if (filter === "Articles") {
            data.academicPapers && data.academicPapers.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              ){
                newData.push(resource)
              }
            })
          }
          if (filter === "Forum") {
            data.forum && data.forum.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              ){
                newData.push(resource)
              }
            })
          }
          if (filter === "Support Group") {
            data.supportGroup && data.supportGroup.map((resource) => {
              if (
                resource &&
                resource.qaulifiers.org === resources[0]
              ){
                newData.push(resource)
              }
            })
          }
          if (filters.length === 1 && filters[0] === clientIdCode) {
            newData = []
            for (const [key, value] of Object.entries(data)) {
              if (
                key === "supportResources" ||
                key === "videoResources" ||
                key === "websites" ||
                key === "rssFeed" ||
                key === "academicPapers" ||
                key === "forum" ||
                key === "supportGroup" || 
                key === "gamesApps"
              ) {
                value.map((resource) => {
                  if (
                    resource &&
                    resource.qaulifiers.org === resources[0]
                  ) {
                    newData.push(resource)
                  }
                })
              }
            }
          }
        } else {
          console.log("does not contain accesId!")
          if (filter === "Support Resources") {
            data.supportResources.map((resource) => newData.push(resource))
          }
          if (filter === "Games & Apps") {
            data.gamesApps.map((resource) => newData.push(resource))
          }
          if (filter === "Video Resources") {
            data.videoResources.map((resource) => newData.push(resource))
          }
          if (filter === "Websites") {
            data.websites.map((resource) => newData.push(resource))
          }
          if (filter === "RSS Feeds") {
            data.rssFeed.map((resource) => newData.push(resource))
          }
          if (filter === "Articles") {
            data.academicPapers.map((resource) => newData.push(resource))
          }
          if (filter === "Forum" && data.forum) {
            data.forum.map((resource) => newData.push(resource))
          }
          if (filter === "Support Group" && data.supportGroup) {
            data.supportGroup.map((resource) => newData.push(resource))
          }
          
        }
      })
    }
    setResourcesData(newData)
  }, [filters])

  const handleChangeMultiple = (event) => {
    const { options } = event.target
    const value = []
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }
    setPersonName(value)
  }

  const postBookmarkIds = async (arr) => {
    setLoading(true)
    fetch(baseUrl + `/user-profile/bookmarks`, {
      credentials: "include",
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bookmarks: arr ?? bookmarks, readOnly: true }),
    })
      .then((res) => res.json())
      .then((res) => {
        setBookmarksData(res)
        setLoading(false)
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      })
  }

  useEffect(() => {
    setRenderBookmarks(false)
    // fetch(baseUrl + `/user-profile/bookmarks`, {
    //   credentials: "include",
    //   mode: "cors",
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ bookmarks: bookmarks }),
    // })
    //   .then((res) => res.json())
    //   .then((res) => setBookmarksData(res))
    setLoading(true);
    fetch(baseUrl + `/education/resources`, {
      credentials: "include",
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        disorder: listObject[selectedDisorder],
      }),
    })
      .then((res) => res.json())
      .then((res) => {

        setData(res)
        setResourcesData([])
        let newData = []
        if (filters) {
          filters.map((filter) => {
            if (clientIdCode && filters.includes(clientIdCode)) {
              console.log("contains accessId!")
              if (filter === "Support Resources") {
                data.supportResources.map((resource) => {
                  if (resource && resource.qaulifiers.org === clientIdCode)
                    newData.push(resource)
                })
              }
              if (filter === "Games & Apps") {
                data.gamesApps.map((resource) => {
                  if (resource && resource.qaulifiers.org === clientIdCode)
                    newData.push(resource)
                })
              }
              if (filter === "Video Resources") {
                data.videoResources.map((resource) => {
                  if (resource && resource.qaulifiers.org === clientIdCode)
                    newData.push(resource)
                })
              }
              if (filter === "Websites") {
                data.websites.map((resource) => {
                  if (resource && resource.qaulifiers.org === clientIdCode)
                    newData.push(resource)
                })
              }
              if (filter === "RSS Feeds") {
                data.rssFeed.map((resource) => {
                  if (resource && resource.qaulifiers.org === clientIdCode)
                    newData.push(resource)
                })
              }
              if (filter === "Articles") {
                data.academicPapers.map((resource) => {
                  if (
                    resource &&
                    resource.qaulifiers.org === resources[0]
                  )
                    newData.push(resource)
                })
              }
              if (filter === "Forum") {
                data.forum.map((resource) => {
                  if (
                    resource &&
                    resource.qaulifiers.org === resources[0]
                  )
                    newData.push(resource)
                })
              }
              if (filter === "Support Group") {
                data.supportGroup.map((resource) => {
                  if (
                    resource &&
                    resource.qaulifiers.org === resources[0]
                  )
                    newData.push(resource)
                })
              }
              if (filters.length === 1 && filters[0] === clientIdCode) {
                for (const [key, value] of Object.entries(data)) {
                  if (
                    key === "supportResources" ||
                    key === "videoResources" ||
                    key === "websites" ||
                    key === "rssFeed" ||
                    key === "academicPapers" ||
                    key === "forum" ||
                    key === "supportGroup" ||
                    key === "gamesApps"
                  ) {
                    value.map((resource) => newData.push(resource))
                  }
                }
              }
            } else {
              console.log("does not contain accesId!")
              if (filter === "Support Resources") {
                data.supportResources.map((resource) => newData.push(resource))
              }
              if (filter === "Games & Apps") {
                data.gamesApps.map((resource) => newData.push(resource))
              }
              if (filter === "Video Resources") {
                data.videoResources.map((resource) => newData.push(resource))
              }
              if (filter === "Websites") {
                data.websites.map((resource) => newData.push(resource))
              }
              if (filter === "RSS Feeds") {
                data.rssFeed.map((resource) => newData.push(resource))
              }
              if (filter === "Articles") {
                data.academicPapers.map((resource) => newData.push(resource))
              }
              if (filter === "Forum") {
                data.forum.map((resource) => newData.push(resource))
              }
              if (filter === "Support Group") {
                data.supportGroup.map((resource) => newData.push(resource))
              }
            }
          })
        }
        setResourcesData(newData)
        setLoading(false)
        setFilters([])
        setBookmarksData([])
        postBookmarkIds()
      })
      .catch((err) => console.log(err))
  }, [selectedDisorder])

  const CheckIsAuthenticate = () => {
    fetch(baseUrl, {
      credentials: "include",
      mode: "cors",
    })
      .then((response) => {
        if (response.status !== 200) {
          props.history.push("/home")
          setAuth(false)
        }
      })
      .catch((err) => {
        setAuth(false)
        alert("You seem like unauthorised, Please Login")
        props.history.push("/home")
        console.log(err);
      })
  }
  const breakPointsColumnsObj = {
    default: 2,
    1200: 1,
    992: 1,
    768: 1,
  }

  // const classes = useStyles();
  const [open, setOpen] = React.useState(true)
  const [lang, setLang] = useState(store.get("lang") ? store.get("lang") : "en");
  const [currentResources, setCurrentResources] = React.useState('normal')
  const [dataFinal, setDataFinal] = React.useState({});
  const handleClick = () => {
    if (document.getElementById("sidebarMenu").className.includes("show")) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const changeLanguage = () => {
    props.i18n.changeLanguage(lang === "en" ? "fr" : "en")
    setLang(lang === "en" ? "fr" : "en")
    store.set("lang", lang === "en" ? "fr" : "en")
  }

  const filterMapping = {
    academicPapers: 'Articles',
    // rssFeed: 'RSS Feeds',
    supportResources: 'Support Resources',
    videoResources: 'Video Resources',
    websites: 'Websites',
    forum: 'Forum',
    supportGroup: 'Support Group',
    // gamesApps: 'Games & Apps',
    // meridian: 'Meridian',
  };

  const finalData = {};
  useEffect(() => {
    if (renderBookmarks) {
      finalData.normal = bookmarksData && bookmarksData.filter(resource => !resource.videoUrl && !resource.recommend);
      finalData.video = bookmarksData && bookmarksData.filter(resource => resource.videoUrl);
      finalData.recommend = bookmarksData && bookmarksData.filter(resource => resource.recommend);
      setDataFinal(finalData);
    } else {
      if(filters && filters.length > 0) {
        finalData.normal = Object.keys(data).filter(key => key !== 'recommended').filter(key => filters.includes(filterMapping[key])).flatMap(key => data[key]).filter(resource => !resource.videoUrl);
        finalData.video = Object.keys(data).filter(key => key !== 'recommended').filter(key => filters.includes(filterMapping[key])).flatMap(key => data[key]).filter(resource => resource.videoUrl);
        finalData.recommend = data['recommended'] //Object.keys(data).filter(key => key === 'recommended').flatMap(key => data[key]);
        setDataFinal(finalData);
      } else {
        finalData.normal = Object.keys(data).filter(key => key !== 'recommended').flatMap(key => data[key]).filter(data => typeof data == 'object').filter(resource => !resource.videoUrl);
        finalData.video = Object.keys(data).filter(key => key !== 'recommended').flatMap(key => data[key]).filter(resource => resource.videoUrl);
        finalData.recommend = data['recommended'] // Object.keys(data).flatMap(key => data[key]).filter(resource => resource.recommend);
        setDataFinal(finalData);
      }
      // console.log('final data to test', finalData, filters, Object.keys(data))
    }
  }, [renderBookmarks, filters, currentResources, bookmarksIds, loading, bookmarksData])

  const removeFilter = (filter) => {
    setFilters(filters.filter((fltr) => fltr !== filter));
  };

  return (
    <div>
      {props.match.params.assessmentId ? (
        <div className="header--alt">
          <Navbar
            auth={auth}
            params={props.match.params.assessmentId}
            history={props.history}
            changeLanguage={changeLanguage}
            lang={lang}
            disorderState={{ selectedDisorder: [selectedDisorder, setSelectedDisorder] }}
            education_id={props.match.params.assessmentId}
          />
        </div>
      ) : (
        <div className="header--alt">
          <Navbar auth={auth} params="COVID" lang={lang} history={props.history} changeLanguage={changeLanguage} education_id={props.match.params.assessmentId} />
        </div>
      )}
      <div className="row m-0 section-p" style={{ zIndex: 9999 }}>

        <main role="main" className="custom-w col-md-12 ml-sm-auto col-lg-12">
          <div className="title-container">

            <h1 className="main-title">
              {t("education.support")}
            </h1>


            <div className="filters">
              {!renderBookmarks &&
                <div className="flex">
                  <span className="mr-3">{t("education.iLikeToSee")}</span>
                  <div className="dropdown custom-dropdown gradient-bg mr-3">

                    <a className="dropdown-toggle" data-toggle="dropdown">
                      {t(selectedDisorder)}
                      <i className="fas fa-chevron-down"></i>
                    </a>
                    <div className="dropdown-menu custom-menu custom-menu--filtered">
                      {
                        disorders.filter(d =>
                          d.toLowerCase().includes(selectFilter.toLowerCase()) || selectFilter === ''
                        )
                          .map(d => (
                            <a
                              className={`custom-item  ${selectedDisorder === d ? "active" : ""}`}
                              onClick={() => {
                                setSelectedDisorder(d);
                              }}
                            >
                              {d}
                              <span className="sr-only">(current)</span>
                            </a>
                          ))
                      }
                    </div>
                  </div>
                </div>

              }
              {!renderBookmarks &&
                <div className="dropdown custom-dropdown mr-3">
                  <a className="dropdown-toggle" data-toggle="dropdown">
                    {t("education.filters")}
                    <i className="fas fa-chevron-down"></i>
                  </a>
                  <div className="dropdown-menu custom-menu custom-responsive-menu">
                    {resources.map((name, key) => (
                      <a
                        key={key}
                        style={getStyles(name, personName, theme)}
                        className={`custom-item ${filters.includes(name) ? "active" : ""
                          }`}
                        data-id={name}
                        onClick={() => {
                          if (filters.includes(name)) {
                            setFilters(
                              filters.filter((filter) => filter !== name)
                            )
                          } else {
                            setFilters([...filters, name])
                          }
                          handleChange({ target: { value: name } })
                        }}
                      >
                        {name}
                      </a>
                    ))}
                  </div>
                </div>
              }
              <div className={`custom-dropdown ${renderBookmarks ? "active" : ""
                } `}>
                <button
                  type="button"
                  onClick={() => {
                    setFilters([])
                    setRenderBookmarks(!renderBookmarks)
                    postBookmarkIds()
                  }}
                >
                  {t("education.bookmarks")}
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <ul className="chips-container">
                {filters.map(filter => (
                  filter == 'Video Resources' ? null :
                  <li className="chip" onClick={() => removeFilter(filter)}>
                    {filter}
                    <i className="fa fa-times ml-2"></i>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className={`nav-link custom-tab-link ${currentResources == 'video' ? 'active' : ``}`} onClick={e => { e.preventDefault(); setCurrentResources('video'); setFilters([...filters, 'Video Resources']); }}>{t("education.videoResources")}</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link custom-tab-link ${currentResources == 'normal' ? 'active' : ``}`} onClick={e => { e.preventDefault(); setCurrentResources('normal'); setFilters(filters.filter(f => f !== 'Video Resources')) }}>{t("education.normalResources")}</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link custom-tab-link ${currentResources == 'recommend' ? 'active' : ``}`} onClick={e => { e.preventDefault(); setCurrentResources('recommend'); setFilters(filters.filter(f => f !== 'Video Resources')) }}>{t("education.recommendResources")}</a>
                </li>
              </ul>
            </div>
          </div>
          <EducationCardList 
              renderBookmarks={renderBookmarks}
              postBookmarkIds={postBookmarkIds}
              selectedDisorder={selectedDisorder}
              bookmarksData={bookmarksData}
              setBookmarks={setBookmarks}
              bookmarksIds={bookmarksIds}
              setBookmarksIds={setBookmarksIds}
              lang={lang}
              t={t}
              filters={filters}
              data={dataFinal[currentResources] || []}
              resourcesData={resourcesData}
              bookmarks={bookmarks}
              loading={loading}
              setBookmarksData={setBookmarksData}
              isVideo={currentResources == 'video'}
              i18n={i18n}
              setLoading={setLoading}
            />
        </main>
      </div>
      <div className="footer">
        <Footer customClassName="custom-w" />
      </div>
    </div>
  )
}

export default withTranslation()(SupportResources)
