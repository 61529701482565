import React from "react"

const ComorbidIllness = {
  Depression: [
    "Bipolar Disorder", 
    "Alcohol and Substance Abuse", 
    "Panic Disorder", 
    "Social Anxiety", 
    "PTSD",
    "General Anxiety Disorder",
    "Eating Disorder",
    "ADHD",
    "Anger Problems",
    "Sleep Disorder (Insomnia)",
    "OCD",
    "Adjustment Disorder"
  ],
  "Mania/Hypomania": ["Depression"],
  "Alcohol and Substance Abuse": [
    "Depression",
    "Bipolar Disorder",
  ],
  "Panic Disorder": ["Depression", "Bipolar Disorder", "Alcohol and Substance Abuse"],
  "Social Anxiety": ["Depression", "Bipolar Disorder", "Panic Disorder"],
  PTSD: ["Depression", "Alcohol and Substance Abuse", "Panic Disorder"],
  "General Anxiety Disorder": [
    "Depression",
    "Bipolar Disorder",
    "Alcohol and Substance Abuse",
    "Panic Disorder",
    "Social Anxiety",
  ],
  "Eating Disorder": ["Depression", "General Anxiety Disorder"],
  ADHD: ["Depression", "Bipolar Disorder", "General Anxiety Disorder"],
  "Anger Problems": ["Depression", "Bipolar Disorder", "PTSD"],
  "Sleep Disorder (Insomnia)": ["Depression", "Bipolar Disorder", "Alcohol and Substance Abuse", "PTSD", "General Anxiety Disorder"],
  OCD: ["Depression", "General Anxiety Disorder"],
  "Adjustment Disorder": ["Depression", "Bipolar Disorder", "Alcohol and Substance Abuse", "Anger Problems"]
}

const Comorbid = ({illness, comorbidDisorderList}) => {
  return (
    <p>
      {Object.keys(ComorbidIllness).map(function (key) {
        if (key === illness) {
            let disorders = ComorbidIllness[key].filter(disorder => comorbidDisorderList.includes(disorder === 'Bipolar Disorder' ? 'Mania/Hypomania' : disorder));
            return disorders.length > 0 ? disorders.map(
              (r, i) => `${r}${ComorbidIllness[key].length !== i + 1 ? ", " : ""}`
            ) : 'N/A'
        }
      })}
    </p>
  )
}

export default Comorbid
