import React, { useReducer, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Forms } from "../form/Forms"
import Header from "../navbar/Header"
import Footer from "../footer/Footer"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { SendAssessmentPdf } from "../popups/shareAssessment/SendAssessmentPdf"
import BgImg from "../../assets/images/top-bg-bottom-gradient.png"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../error/ErrorScreen"
const store = require('store')
const lngs = {
  en: { nativeName: "En" },
  fr: { nativeName: "Fr" },
}

const Profile = (props) => {
  const [showDoctorEmailForm, setShowDoctorEmailForm] = useState(false)

  const {t, i18n} = props;

  useEffect(() => {
    i18n.changeLanguage(store.get('lang') || 'en')
  }, [])

  const toggleDoctorForm = () => {
    setShowDoctorEmailForm(!showDoctorEmailForm)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <section
        css={css`
          background-color: #ecf8f8;
        `}
      >
        <img
          src={BgImg}
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          `}
        />

        <Header />

        <Forms t={t} i18n={i18n} />

        <div
          css={css`
            height: 20vh;
          `}
        />

        {showDoctorEmailForm && (
          <SendAssessmentPdf
            onClose={() => {
              toggleDoctorForm()
            }}
          />
        )}

        <Footer />
        {/* <div className="downButtons" style={{zIndex: "100000"}}>
            {Object.keys(lngs).map((lng) => (
              <button
                key={lng}
                className="lngBtn"
                style={{
                  fontWeight: i18n.language === lng ? "bold" : "normal",
                  outline: 0,
                }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </button>
            ))}
          </div> */}
      </section>
    </ErrorBoundary>
  )
}

export default withTranslation()(Profile)