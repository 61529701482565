/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, {
 useState, useEffect, useRef,
} from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import { baseUrl } from '../../lib/constants';
// import { Navbar } from "../components/Navbar";
import '../../css/main.css';
import '../../css/pap.scss';
import {
 EH2,
  // EBlockWrapper,
  EMainBlock,
  // ETopMainCaption,
} from '../summary/Summary';
import Footer from '../../components/footer/Footer';
import Header from '../../components/navbar/Header';
import EditIcon from '../../assets/images/edit-icon.svg';
import AddChipsIcon from '../../assets/images/add-chips-btn.svg';
import DeleteIcon from '../../assets/images/delete-icon.svg';
import BgImg from '../../assets/images/top-bg-bottom-gradient.png';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorScreen } from '../../components/error/ErrorScreen';
import ReactSlidingSidePanel from '../../components/bottom-slider/ReactSlidingSidePanel';
import NoGoals from './NoGoals';
import Axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

const options = {
  withCredentials: true,
  mode: "cors",
};

const axios = Axios.create(options);

const EBlockWrapper = styled.div`
  font-family: "CircularStdBook";
  margin: 8em auto 0 auto;
  max-width: 83em;
  padding: 0 1em;
  position: relative;
  z-index: 1.5;
  @media (max-width: 1440px) {
    max-width: 90em;
  }
`;
export const EWrapper = styled.div`
  // max-width: 74em;
  margin: 0 auto;
  padding-bottom: 5em;

  @media (max-width: 1080px) {
    font-size: 0.7em;
  }

  @media (max-width: 1180px) {
    font-size: 0.8em;
  }

  @media (max-width: 1280px) {
    font-size: 0.9em;
  }

  @media (min-width: 1480px) {
    font-size: 1.1em;
  }
`;

export const ELoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    transform: scale(5);
  }
  font-family: "CircularStdBook";
`;

export const ESymptomsGrid = styled.ul`
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 1.5em;
  border-radius: 1em !important;
  background-color: #e1f4f4;
  padding: 1.8em 1.4em;
  font-family: "CircularStdBook";
  grid-template-columns: 9fr 10fr;

  i {
    font-size: 1.3em;
    margin-right: 0.5em;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

export const EList = styled.div`
  display: grid;
  grid-row-gap: 1.5em;
  font-family: "CircularStdBook";

  > div {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1em;
  }

  i {
    font-size: 1.3em;
    margin-right: 0.5em;
  }
`;

export const EMedListWrapper = styled.div`
  border: 1px solid #eaeaea;
  background-color: #fafafa;
  margin: 1em 2.3em 0 2.3em;
  border-radius: 0.5em;
`;

export const EP1 = styled.p`
  font-size: 1.1em;
  font-family: "CircularStdBook";
  line-height: 1.8;
  margin: 0;
  color: ${(props) => (props.color ? props.color : '#151852')};
`;

export const EP2 = styled.p`
  font-size: 0.87em;
  font-weight: 500;
  font-family: "CircularStdBook";
  margin: 0;
`;

export const EHeaderWrapper = styled.div`
  min-height: 5em;
  display: grid;
  grid-template-columns: 18fr 10fr;
  grid-column-gap: 2em;
  background-color: #e1f4f4;
  align-items: center;
  padding: 0 2.5em;
  color: #151852;
  font-family: "CircularStdBook";

  .info {
    font-size: 1.44em;
    display: grid;
    grid-template-columns: 1fr max-content;
  }
`;

export const ETipsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.8em;
  grid-row-gap: 2.3em;
  font-family: "CircularStdBook";
  margin: 2.6em 2.3em 0 2.3em;

  > div {
    border: 1px solid #d7d7d7;
    border-radius: 0.5em;
    padding: 2em;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 1.4em;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const ETipsGridImage = styled.div`
  padding: 1.4em;
  background-color: #ebebeb;
  border-radius: 100%;
  img {
    width: 2.2em;
    height: 2.2em;
    object-fit: contain;
  }
`;

export const EPapCardRoot = styled.div`
  border-radius: 0.8em 0.8em 0.8em 0.8em / 0.4em 0.4em 0.4em 0.4em;
  box-shadow: 0px 4px 21px rgba(55, 62, 61, 0.23);
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  font-family: "CircularStdBook";
  padding-bottom: 5em;
`;

export const EPapDisorderCaption = styled.h2`
  font-size: 2em;
  font-weight: 600;
  color: #151852;
  font-family: "CircularStdBook";
  margin: 0;
`;

export const EDescriptionAndSymptoms = styled.div`
  display: grid;
  grid-template-columns: 14fr 7.5fr;
  grid-column-gap: 2em;
  margin: 2.3em 2.3em 0 2.3em;
`;

export const EMedicationAndClinicalSteps = styled.div`
  display: grid;
  grid-template-columns: 14fr 7.5fr;
  grid-column-gap: 2em;
  margin: 2.3em 2.3em 0 2.3em;
`;

export const EH2Orange = styled.h2`
  color: #f3763e;
  font-size: 1.5em;
  margin: 0;
`;

export const MedicationTableHeder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 1.3em;
  grid-column-gap: 1em;

  p {
    color: #8d8ea5;
  }
`;

export const MedicationTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  overflow: hidden;

  > div:nth-of-type(odd) {
    background-color: #e1f4f4;
  }
  > div:nth-of-type(even) {
    background-color: #f8fcff;
  }
`;

export const MedicationTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
  padding: 1.08em 0 1.08em 1.3em;
  p {
    font-weight: 400 !important;
  }
`;

const noAssessmentIdErrorMessage = 'Please, select the assessment you want to view plan for';

const Goals = ({ match, t, i18n }) => (
  <View
    i18n={i18n}
  />
  );

export default withTranslation()(Goals);

const ControlComponent = (props) => (
  <button {...props} onBlur={props.setMenuIsOpen} disabled={props.updateGoalLoading} onClick={props.setMenuIsOpen} className="chips-btn" data-tut="add-symptom-btn">{props.updateGoalLoading ? <i className="fas fa-circle-notch fa-spin"></i> : <img src={AddChipsIcon} className="chips-add" />}</button>
);

const CustomMenu = (props) => (
  <div style={{ minWidth: 200 }}>
    <components.Menu {...props} />
  </div>
);

export const View = ({
  i18n,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [addGoalLoading, setAddGoalLoading] = useState(false);
  const [updateGoalLoading, setUpdateGoalLoading] = useState(false);

  const [error, setError] = useState(null);

  const [goals, setGoals] = useState([]);
  const [filteredGoals, setfilteredGoals] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState('');
  const [symptoms, setSymptoms] = useState([]);
  const [rawSymptoms, setrawSymptoms] = useState([]);
  const [symptomTitle, setSymptomTitle] = useState('');

  const formRef = useRef();
  const symptomsRef = useRef();
  const addGoalInput = useRef();
  const [goalName, setGoalName] = useState('');

  const planFormRef = useRef();
  const [planName, setPlanName] = useState('');

  const [editDescription, setEditDescription] = useState({});
  const [goalDescriptions, setGoalDescriptions] = useState({});
  const [editTitle, setEditTitle] = useState({});
  const [goalTitles, setGoalTitles] = useState({});

  const [taskTitle, settaskTitle] = useState({});
  const [taskDueDate, setTaskDueDate] = useState({});
  const [taskDateToggle, setTaskDateToggle] = useState({});

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [addNewPlan, setAddNewPlan] = useState({});

  const [updateInprogress, setUpdateInprogress] = useState(false);

  const getGoals = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/plans-goals/goals`);
      console.log(response);
      const goalsArray = [];
      for (const key in response.data) {
        goalsArray.push({ ...response.data[key], id: key });
      }
      setGoals(goalsArray);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const getSymptoms = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/plans-goals/symptoms`);
      console.log(response);
      const symptomsArray = [];
      for (const key in response.data) {
        symptomsArray.push({ ...response.data[key], id: key });
      }
      setSymptoms(symptomsArray);
      setrawSymptoms(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const addSymptoms = async () => {
    if(symptomTitle) {

      try {
        setLoading(true);
        const response = await axios.post(`${baseUrl}/plans-goals/symptoms`, {
          title: {
            en: symptomTitle
          }
        });
        console.log(response);
        const symptomsArray = [];
        for (const key in response.data) {
          symptomsArray.push({ ...response.data[key], id: key });
        }
        setSymptoms(symptomsArray);
        setrawSymptoms(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
    }
  };

  const addGoal = async (e) => {
    e.preventDefault();
    if (goalName && !addGoalLoading) {
      try {
        setAddGoalLoading(true)
        const response = await axios.post(`${baseUrl}/plans-goals/goal`, {
          title: {
            en: goalName,
          },
        });
        const goalsArray = [];
        for (const key in response.data) {
          goalsArray.push({ ...response.data[key], id: key });
        }
        setGoals(goalsArray);
        setGoalName('');
        setAddGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setAddGoalLoading(false);
      }
    }
  };
  const addPlan = async (e, goal, index) => {
    e.preventDefault();
    if (planName && !updateGoalLoading) {
      setUpdateGoalLoading(true);
      try {
        const response = await axios.post(`${baseUrl}/plans-goals/plan/${goal.id}`, {
          title: {
            en: planName,
          },
        });
        const goalsArray = [];
        for (const key in response.data) {
          goalsArray.push({ ...response.data[key], id: key });
        }
        setGoals(goalsArray);
        setPlanName('');
        setAddNewPlan({ ...addNewPlan, [index]: !addNewPlan[index] });
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  };
  const addSymptomToGoal = async (symptom, goal) => {
    if(!updateGoalLoading) {
      const newGoal = { ...goal };
      newGoal.symptoms = newGoal.symptoms ? Object.keys(newGoal.symptoms) : [];

      newGoal.symptoms.push(symptom.value);
      try {
        const response = await updateGoal(newGoal);
        if (response) {
          setGoalName('');
        }
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  };
  const updateDescription = async (index, goal) => {
    if (goalDescriptions[index]) {
      try {
        const newGoal = { ...goal };
        newGoal.symptoms = newGoal.symptoms ? Object.keys(newGoal.symptoms) : [];
        newGoal.description = {
          en: goalDescriptions[index],
          fr: goalDescriptions[index],
        };
        const response = await updateGoal(newGoal);
        if (response) {
          setEditDescription({ ...editDescription, [index]: false });
        }
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  };

  const updateGoalTitle = async (index, goal) => {
    if (goalTitles[index]) {
      try {
        const newGoal = { ...goal }
        newGoal.symptoms = newGoal.symptoms ? Object.keys(newGoal.symptoms) : [];
        newGoal.title = {
          en: goalTitles[index],
          fr: goalTitles[index],
        };
        const response = await updateGoal(newGoal);
        if (response) {
          setEditTitle({ ...editTitle, [index]: false });
        }
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  }

  const deletePlan = async (goalId, planId) => {
    if(window.confirm('Are you sure you want to delete this plan?')) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.delete(`${baseUrl}/plans-goals/goal/${goalId}/plan/${planId}`);
        const goalsArray = [];
        for (const key in response.data) {
          goalsArray.push({ ...response.data[key], id: key });
        }
        setGoals(goalsArray);
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  }

  const deleteGoal = async (goalId) => {
    if(window.confirm('Are you sure you want to delete this goal?')) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.delete(`${baseUrl}/plans-goals/goal/${goalId}`);
        const goalsArray = [];
        for (const key in response.data) {
          goalsArray.push({ ...response.data[key], id: key });
        }
        setGoals(goalsArray);
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  }

  const deleteTask = async (goalId, planKey, taskIndex) => {
    if(window.confirm('Are you sure you want to delete this task?')) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.delete(`${baseUrl}/plans-goals/goal/${goalId}/plan/${planKey}/task/${taskIndex}`);
        const goalsArray = [];
        for (const key in response.data) {
          goalsArray.push({ ...response.data[key], id: key });
        }
        setGoals(goalsArray);
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  };

  const addTask = async (e, goalId, planId, goal) => {
    e.preventDefault();
    if (taskTitle[goalId] && taskTitle[goalId][planId] && !updateGoalLoading) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.post(`${baseUrl}/plans-goals/goal/${goal.id}/plan/${planId}/task`, {
          title: {
            en: taskTitle[goalId][planId],
          },
        });
        const goalsArray = [];
        for (const key in response.data) {
          goalsArray.push({ ...response.data[key], id: key });
        }
        setGoals(goalsArray);
        settaskTitle({ ...taskTitle, [goalId]: { ...taskTitle[goalId], [planId]: '' } });
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  };

  const updateTaskStatus = async (goalIndex, planId, taskIndex, status) => {
    // console.log('taks details', goals[goalIndex].plans[planId].tasks[taskIndex], goalIndex, planId, taskIndex) //goals[goalIndex][planId][taskIndex]
    if (
        goals[goalIndex] 
        && goals[goalIndex].plans 
        && goals[goalIndex].plans[planId] 
        && goals[goalIndex].plans[planId].tasks 
        && goals[goalIndex].plans[planId].tasks[taskIndex] 
        && !updateInprogress
        && !updateGoalLoading
      ) {
      setUpdateInprogress(true);
      const task = goals[goalIndex].plans[planId].tasks[taskIndex];
      task.status = status;
      goals[goalIndex].plans[planId].tasks[taskIndex] = task;
      setGoals([...goals]);
      try {
        setUpdateGoalLoading(true);
        const goal = { ...goals[goalIndex] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };

  const getTaskDueDate = (goalIndex, planId, taskIndex) => {
    if (taskDueDate[goalIndex] && taskDueDate[goalIndex][planId] && taskDueDate[goalIndex][planId][taskIndex]) {
      return taskDueDate[goalIndex][planId][taskIndex];
    } else {
      return goals[goalIndex].plans[planId].tasks[taskIndex].due_date && moment(goals[goalIndex].plans[planId].tasks[taskIndex].due_date).toDate();
    }
  }

  const onDateChange = async (date, index, planKey, taskIndex) => {
    if (
      goals[index] 
      && goals[index].plans 
      && goals[index].plans[planKey] 
      && goals[index].plans[planKey].tasks 
      && goals[index].plans[planKey].tasks[taskIndex] 
      && !updateInprogress
    ) {
      setUpdateInprogress(true);
      const task = {
        ...taskDueDate,
        [index]: {
          ...(taskDueDate[index] || {}),
          [planKey]: {
            ...(taskDueDate[index] ? taskDueDate[index][planKey] : {}),
            [taskIndex]: date,
          },
        },
      };
      setTaskDueDate({ ...task });
      goals[index].plans[planKey].tasks[taskIndex].due_date = date;
      setGoals([...goals]);
      try {
        setUpdateGoalLoading(true);
        const goal = { ...goals[index] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  }

  async function updateGoal(goal) {
    try {
      setUpdateGoalLoading(true);
      const response = await axios.put(`${baseUrl}/plans-goals/goal/${goal.id}`, goal);
      const goalsArray = [];
      for (const key in response.data) {
        goalsArray.push({ ...response.data[key], id: key });
      }
      setGoals(goalsArray);
      setUpdateGoalLoading(false);
      return true;
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setUpdateGoalLoading(false);
      return false;
    }
  }

  const onInputChange = (options, { action }) => {
    if (action === 'menu-close') {
      setMenuIsOpen(false);
    }
  };

  const toggleDates = (index, planKey, taskIndex) => {
    let flag = true;
    if (taskDateToggle[index] && taskDateToggle[index][planKey] && taskDateToggle[index][planKey][taskIndex]) {
      flag = false;
    }
    taskDateToggle[index] = {
      ...((taskDateToggle[index]) || {}),
      [planKey]: {
        ...((taskDateToggle[index] && taskDateToggle[index][planKey])),
        [taskIndex]: flag,
      },
    };
    setTaskDateToggle({ ...taskDateToggle });
  };

  useEffect(() => {
    const tx = document.getElementsByTagName('textarea');
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute('style', `height:${ tx[i].scrollHeight }px;overflow:hidden;`);
      tx[i].addEventListener('input', OnInput, false);
    }

    function OnInput() {
      this.style.height = 'auto';
      this.style.height = `${this.scrollHeight }px`;
    }
  }, [editDescription]);

  const toggleTitleInput = (index) => {
    const headingEl = document.getElementById(`goal_title_${index}`);
    let width = headingEl.clientWidth;
    setEditTitle({ ...editTitle, [index]: !editTitle[index] });
    
    setTimeout(() => {
      const inputEl = document.getElementById(`goal_title_input_${index}`);

      if(inputEl) {
        inputEl.addEventListener('input', resizeInput); // bind the "resizeInput" callback on "input" event
        resizeInput.call(inputEl); // immediately call the function

        function resizeInput() {
          if(this) {
            this.style.width = this.value.length + "ch";
          }
        }
        inputEl.style.width = `${width}px`;
        inputEl.focus();
      }
    }, 100)
  };
  const getTaskTitle = (goalIndex, planId) => {
    if (taskTitle[goalIndex]) {
      return taskTitle[goalIndex][planId];
    }
    return '';
  };

  const startup = async () => {
    await getGoals();
    await getSymptoms();
    symptomsRef && symptomsRef.current && symptomsRef.current.click();
    try {
      setCoinsLoading(true)
      let coins = await fetchCoins();
      setCoinData(coins)
    } catch (e) {
      console.log(e, ' <=== error while fetching coins')
    } finally {
      setCoinsLoading(false)
    }
  }

  useEffect(() => {
    startup()
  }, []);

  const filterGoals = async (symptomId = false) => {
    if (symptomId) {
      if (selectedSymptoms === symptomId) {
        setSelectedSymptoms('');
      } else {
        setSelectedSymptoms(symptomId);
      }
    }
    setfilteredGoals(goals.filter((goal) => {
      for (let i = 0; i < selectedSymptoms.length; i++) {
        if (goal.symptoms[selectedSymptoms[i]]) {
          return true;
        }
      }
      return false;
    }));
    // setGoals(allGoals.filter((goal) => {
    //   for (let i = 0; i < selectedSymptoms.length; i++) {
    //     if (goal.symptoms[selectedSymptoms[i]]) {
    //       return true;
    //     }
    //   }
    //   return false;
    // }));
  };
  const updateWeekDays = async (index, planKey, taskIndex, day) => {
    if (goals[index]
      && goals[index].plans[planKey]
      && goals[index].plans[planKey].tasks[taskIndex]
      && !updateGoalLoading
    ) {
      if (!goals[index].plans[planKey].tasks[taskIndex].week_days) {
        goals[index].plans[planKey].tasks[taskIndex].week_days = [];
      }
      if (goals[index].plans[planKey].tasks[taskIndex].week_days.includes(day)) {
        goals[index].plans[planKey].tasks[taskIndex].week_days = goals[index].plans[planKey].tasks[taskIndex].week_days.filter((d) => d !== day);
      } else {
        goals[index].plans[planKey].tasks[taskIndex].week_days.push(day);
      }
      setGoals([...goals]);
      try {
        setUpdateGoalLoading(true);
        const goal = { ...goals[index] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };

  const lang = i18n.language;
  return <></>
};
