import React, { useContext, useEffect, useState } from "react"
import FollowUp from "../FollowUp"
import { StateContext } from "../../../store/state/contexts"

export default function FollowUpQuestionsTab(props) {
  const { state, answerFollowUp } = useContext(StateContext)

  const [questions,setQuestions] = useState([])
  useEffect(()=> {
    try {
      let _questions = state.questionsToShow.questions.follow_up_questions.questions
      setQuestions(_questions)
    } catch (err) {}
  },[JSON.stringify(state.questionsToShow)])
  // console.log(questions, ' <==== i am questions...')
  return (
    <div>
      {questions.map((data, index) => (
        <FollowUp
          answerFollowUp={(choice, flag)=>answerFollowUp(index,choice, flag)}
          key={data.id}
          id={data.id}
          index={index + 1}
          lang={props.lang}
          progressBarMethod={props.progressBarMethod} 
          onQuestionIsNotPending={props.onQuestionIsNotPending}
          onQuestionIsPending={props.onQuestionIsPending}
          firstUnansweredQuestion={state.firstUnansweredQuestion}
          highlightFirstUnansweredQuestion={
            state.highlightFirstUnansweredQuestion
          }
        />
      ))}
    </div>
  )
}
