import React, { Component } from 'react';
import clients from './clientRedirects.json';
import axios from 'axios';
import { baseUrl } from '../lib/constants';

const recordHits = async (type) => {
  await axios.post(`${baseUrl}/web-visit`, { type });
};
export default class OldSiteRedirect extends Component {
  async componentDidMount() {
    const url = this.props.match.params.client;
    if (url === 'facebook' || url === 'instagram' || url === 'linkedin') {
      await recordHits(url);
    }
    const client = clients.find((c) => c.Alias == `/${this.props.match.params.client}`);
    if (client) {
      window.open(client.URL, '_self');
    } else {
      window.open('/', '_self');
    }
    // window.open("https://feelingbetternow.com/", "_self")
  }

  render() {
    return <></>;
  }
}
