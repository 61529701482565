import React, { useContext, useState, useEffect, useCallback } from "react"
import "../../css/App.css"
import { DefaultButton } from "../../components/buttons/Button"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { baseUrl } from "../../lib/constants"
import _get from "lodash/get"
import axios from "axios"
import { StateContext } from "../../store/state/contexts"
/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"
import AssessmentCard from "./AssessmentCard"
import ScrollToID from "./ScrollToCard"
import { logEvent } from "../../index"

const axiosoptions = {
  withCredentials: true,
  mode: "cors",
}

const animatedComponents = makeAnimated()

const Component = React.memo(
  ({
    lang,
    caption,
    id,
    options,
    response,
    firstUnansweredQuestionId,
    onQuestionIsNotPending,
    progressBarMethod,
    onQuestionIsPending,
    answerLife,
    highlightFirstUnansweredQuestion,
  }) => {
    const { state, dispatch } = useContext(StateContext)

    const [selectedValue, setSelectedValue] = useState(null)
    const [selectedValueFormatted, setSelectedValueFormatted] = useState(null)
    const [loadSubmitBtn, setLoadSubmitBtn] = useState(false)
    const [openCard, setOpenCard] = useState(false)
    const [yesClicked, setYesClicked] = useState(false)
    const [prepareOptions, setPrepareOptions] = useState([])

    const optionsCallback = useCallback((a) => {
      setPrepareOptions(
        a.map((i) => ({
          value: i.text[lang],
          label: i.text[lang],
        }))
      )
    }, [])

    useEffect(() => {
      optionsCallback(options)
    }, [JSON.stringify(options), lang])

    useEffect(() => {
      if (firstUnansweredQuestionId === id) {
        setOpenCard(true)
        // ScrollToID(id)
      }
    }, [firstUnansweredQuestionId, JSON.stringify(state.questions)])

    useEffect(() => {
      if (selectedValue) {
        setSelectedValueFormatted(
          options.find((i) => i.text[lang] === selectedValue.value)
        )
      }
    }, [selectedValue])

    useEffect(() => {
      let tryfind = null
      try {
        tryfind = options.find((i) => i.text[lang] === response.text[lang]).text
      } catch {}

      if (response && tryfind) {
        setSelectedValue({ value: tryfind[lang], label: tryfind[lang] })
      }

      if (response === -1) {
        setSelectedValue(-1)
      }
    }, [JSON.stringify(response), lang])

    const yesClick = (e) => {
      e.stopPropagation()
      setOpenCard(true)
      setYesClicked(true)
    }

    const noClick = (e) => {
      e.stopPropagation()
      setSelectedValue(null)
      onQuestionIsPending()
      setYesClicked(false)

      axios
        .post(
          `${baseUrl}/assessment/life-functions`,
          {
            id: id,
            response: -1,
          },
          axiosoptions
        )
        .then((res) => {
          if (res) {
            logEvent("noClick /assessment/life-functions successful")
            answerLife(-1)
            progressBarMethod()
          }
          onQuestionIsNotPending()
          setOpenCard(false)
        })
        .catch(() => {
          logEvent("noClick /assessment/life-functions failed")
          alert(
            "Something Went Wrong, Please resubmit the question or check your Internet Connection"
          )
          onQuestionIsNotPending()
          setOpenCard(false)
        })
    }

    const handleSubmitClick = () => {
      if (!selectedValueFormatted || selectedValue === -1) {
        alert("Please select option")
        return
      }

      onQuestionIsPending()

      setLoadSubmitBtn(true)

      axios
        .post(
          `${baseUrl}/assessment/life-functions`,
          {
            id: id,
            response: selectedValueFormatted,
          },
          axiosoptions
        )
        .then((res) => {
          logEvent("noClick /assessment/life-functions successful")
          if (res) {
            answerLife(selectedValueFormatted)
            setLoadSubmitBtn(false)
          }
          onQuestionIsNotPending()
          progressBarMethod()
          setOpenCard(false)
        })
        .catch(() => {
          logEvent("noClick /assessment/life-functions failed")
          alert(
            "Something Went Wrong, Please resubmit the question or check your Internet Connection"
          )
          onQuestionIsNotPending()
          progressBarMethod()
          setLoadSubmitBtn(false)
          setOpenCard(false)
        })
    }

    const redHighlight =
      firstUnansweredQuestionId === id && highlightFirstUnansweredQuestion

    return (
      <AssessmentCard
        question={caption}
        redHighlight={redHighlight}
        loading={loadSubmitBtn}
        answer={
          response === -1 ? "No" : selectedValue ? selectedValue.value : ""
        }
        open={openCard}
        setOpen={setOpenCard}
        answered={response !== null}
        id={id}
      >
        <div>
          <div
            css={css`
              margin-bottom: 0.5rem;
              margin-top: 0.5rem;
            `}
          >
            <Select
              options={prepareOptions}
              components={animatedComponents}
              value={selectedValue}
              onChange={(e) => {
                setSelectedValue(e)
              }}
            />
          </div>
          <button className="btn SubmitAnswerBtn" onClick={handleSubmitClick}>
            Submit
          </button>
        </div>
      </AssessmentCard>
    )
  },
  (prev, next) =>
    JSON.stringify(prev.response) === JSON.stringify(next.response) &&
    prev.firstUnansweredQuestionId === next.firstUnansweredQuestionId &&
    prev.lang === next.lang
)

export default (props) => {
  const { state } = useContext(StateContext)

  const lf = _get(state, ["questions", props.id])
  return (
    <Component
      lang={props.lang}
      caption={lf[props.lang].text}
      id={lf.id}
      isAnswered={lf.isAnswered}
      options={lf.options}
      questionType={lf.question_type}
      response={lf.response}
      answerIsPending={state.answerIsPending}
      firstUnansweredQuestionId={
        state.firstUnansweredQuestion && state.firstUnansweredQuestion.id
      }
      onQuestionIsNotPending={props.onQuestionIsNotPending}
      progressBarMethod={props.progressBarMethod}
      onQuestionIsPending={props.onQuestionIsPending}
      answerLife={props.answerLife}
      highlightFirstUnansweredQuestion={state.highlightFirstUnansweredQuestion}
    />
  )
}
