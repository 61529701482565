import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import Footer from "../footer/Footer"
import "../../css/AboutUs.css"
import Header from "../navbar/Header"
import "../../css/PrivacyPolicyPage.css"
import "../../css/privacyPolicy.css"    
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../error/ErrorScreen"
import { withTranslation } from "react-i18next"
const store = require("store")
const TermAndCondition = ({ t, i18n }) => {
    useEffect(() => {
        i18n.changeLanguage(store.get('lang') || 'en')
    }, [])
  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <>
        <div style={{background:"#ecf8f8"}}>
            <div className="container">
                <Header />
            </div>
                <div className="privacy-policy-text" style={{background:"#dffff3", overflowWrap: 'break-word'}}>
                    <h2 style={{textDecoration:"underline"}}>{t('footerSection.terms')}</h2>
                    <div className="panel-inner plain-text">
                        <h2>{t('termsPage.section1.mainTitle')}</h2>
                        
                        <br /> {t('termsPage.section1.description1')}
                        <br /> 
                        <br /> {t('termsPage.section1.description2')}
                        <br /> 
                        <br /> {t('termsPage.section1.description3')}
                        <br /> <br /> 
                        <h2>{t('termsPage.section2.mainTitle')}</h2>
                        <h3>{t('termsPage.section2.subTitle')}</h3>
                        <p>
                            {t('termsPage.section2.description')}
                        </p>
                        <ol>
                            {Array(9).fill().map((i, index) => (
                                <>
                                <li>
                                    <p>
                                        <strong>{t(`termsPage.section2.list.${index}.title`)}</strong>
                                    </p>
                                    <p>
                                    {t(`termsPage.section2.list.${index}.description`)}
                                    </p>
                                    <p>
                                    {t(`termsPage.section2.list.${index}.description2`, '')}
                                    </p>
                                </li>
                                {index === 3 && (
                                    <>
                                        <ol className="alpha ml-4">
                                            {Array(4).fill().map((i, key) => (
                                                <li key={key}>
                                                    <p>
                                                        {t(`termsPage.section2.list.${index}.descriptions.${key}.description1`, '')}
                                                    </p>
                                                    <p>
                                                        {t(`termsPage.section2.list.${index}.descriptions.${key}.description2`, '')}
                                                    </p>
                                                    <p>
                                                        {t(`termsPage.section2.list.${index}.descriptions.${key}.description3`, '')}
                                                    </p>
                                                    <p>
                                                        {t(`termsPage.section2.list.${index}.descriptions.${key}.description4`, '')}
                                                    </p>
                                                </li>
                                            ))}
                                        </ol>
                                        <hr />
                                        <p className="ml-4">
                                            {t(`termsPage.section2.list.${index}.description2`, '')}
                                        </p>
                                        <p className="ml-4">
                                            {t(`termsPage.section2.list.${index}.description3`, '')}
                                        </p>
                                    </>
                                )}
                                </>
                            ))}
                        </ol>
                        <div className="ContentPadding">
                            <h2>
                            <a name="_Toc476225394" />
                                {t(`termsPage.section3.title`, '')}
                            </h2>
                            
                            <br /> {t(`termsPage.section3.description`, '')}
                            <br /> <br /> <br /> 
                        </div>
                        <h2>
                            <a name="_Toc476225395" />
                            {t(`termsPage.section4.title`, '')}
                        </h2>
                        
                        <br /> {t(`termsPage.section4.description`, '')}
                        <br /> 
                        <br /> {t(`termsPage.section4.description2`, '')}
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225396" />
                            {t(`termsPage.section5.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section5.description`)}
                        <br /> 
                        <ul>
                            {Array(10).fill().map((i, index) => (
                                <li>{t(`termsPage.section5.list.${index}`)}</li> 
                            ))}
                        </ul>
                        
                        <br />{" "}
                        <HashLink to="/#features">
                            {/* <a target="_blank" href="https://www.feelingbetternowv2.com/Website/Features.aspx"> */}
                                <u>{t(`termsPage.section5.link`)}</u>
                            {/* </a> */}
                        </HashLink>
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225397" />
                            {t(`termsPage.section6.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section6.description`)}
                        <br /> 
                        <br />{" "}
                        <HashLink to="/about#contact" target={"_blank"}>
                            <u>{t(`termsPage.section6.link`)}</u>
                        </HashLink>
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225398" />
                            {t(`termsPage.section7.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section7.desctription1`)}
                        <br /> 
                        <br /> <HashLink to="/about#advisoryBoard" target={"_blank"}><u>{t(`termsPage.section7.description2`)}</u> </HashLink>
                        <br /> 
                        <br /> {t(`termsPage.section7.description3`)}
                        <br /> 
                        <br /> {t(`termsPage.section7.description4`)}
                        <br /> 
                        <br /> {t(`termsPage.section7.description5`)}
                        <br /> 
                        <br />{" "}
                        <HashLink to="/about#timeline">
                            <u>{t(`termsPage.section7.link`)}</u>
                        </HashLink>
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225399" />
                            {t(`termsPage.section8.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section8.description1`)}
                        <br /> 
                        <br /> {t(`termsPage.section8.description2`)}
                        <br /> 
                        <br />{t(`termsPage.section8.description3`)}
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225400" />
                            {t(`termsPage.section9.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section9.description1`)}
                        <br /> 
                        <br /> {t(`termsPage.section9.description2`)}
                        <br /> 
                        <br /> {t(`termsPage.section9.description3`)}
                        <br /> 
                        <br /> {t(`termsPage.section9.description4`)}
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225401" />
                            {t(`termsPage.section10.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section10.description1`)}
                        <br /> <br /> {t(`termsPage.section10.description2`)}
                        <br /> 
                        <br /> <br /> <br /> 
                        <h2>
                            <a name="_Toc476225402" />
                            {t(`termsPage.section11.title`)}
                        </h2>
                        
                        <br /> {t(`termsPage.section11.description`)}{" "}  
                        <a href="mailto:info@mensante.com">info@mensante.com</a>.
                        <br /> <br /> 
                    </div>
                </div>
            <Footer />
        </div>
      </>
    </ErrorBoundary>
  )
}

export default withTranslation()(TermAndCondition)
