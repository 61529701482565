import React from 'react';
import ResourcesCard from './ResourcesCard';
import '../../css/education.scss';

class EducationCardList extends React.Component {
  render() {
    const {
      renderBookmarks,
      postBookmarkIds,
      selectedDisorder,
      bookmarksData,
      setBookmarks,
      bookmarksIds,
      setBookmarksIds,
      lang,
      filters,
      data,
      resourcesData,
      bookmarks,
      loading,
      setBookmarksData,
      isVideo,
      i18n,
      setLoading,
      t
    } = this.props;
    return (
      <div className="cards-row">
        {loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ width: '100vw' }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          (data.length > 0 ? data.map(resource => (
            <div
              className={`resources-card ${
                    isVideo ? 'resource-card-video' : ''
                  } button-bottom-grid ${
                    resource?.qaulifiers?.type?.resourceType
                    || 'default'
                  }-icon`}
            >
              <ResourcesCard
                i18n={i18n}
                isVideo={isVideo}
                postBookmarkIds={postBookmarkIds}
                resource={resource}
                disorder={selectedDisorder}
                bookmarks={bookmarks}
                setBookmarks={setBookmarks}
                bookmarksIds={bookmarksIds}
                setBookmarksIds={setBookmarksIds}
                lang={lang === 'en' ? 'en' : 'french'}
                setLoading={setLoading}
              />
            </div>
          ))
          :
          <div
            className="d-flex justify-content-center"
            style={{ width: '100vw' }}
          >
            <h3>{t('education.noResources')}</h3>
          </div>
          )
        )}
      </div>
    )
  }
}

export default EducationCardList;
