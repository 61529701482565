const AssessmentReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_ASSESSMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case "FETCH_ASSESSMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
        questions: action.payload,
      }

    case "FETCH_ASSESSMENT_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        questions: null,
      }

    default:
      return state
  }
}

const AssessmentAttemptReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_ASSESSMENT_ATTEMPT_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case "FETCH_ASSESSMENT_ATTEMPT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
        questions: action.payload,
      }

    case "FETCH_ASSESSMENT_ATTEMPT_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        questions: null,
      }

    default:
      return state
  }
}

const QuestionsToShowReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUESTIONS_TO_SHOW":
      return {
        ...state,
        questions: action.payload,
      }

    default:
      return state
  }
}

const FirstUnansweredReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIRST_UNANSWERED_QUESTION":
      return action.payload

    default:
      return state
  }
}

const AnswerIsPendingReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUESTION_IS_PENDING":
      return true
    case "SET_QUESTION_IS_NOT_PENDING":
      return false
    default:
      return state
  }
}

const highlightFirstUnansweredQuestionReducer = (state, action) => {
  switch (action.type) {
    case "HIGHLIGHT_FIRST_UNANSWERED_QUESTION":
      return true
    case "STOP_HIGHLIGHT_FISRT_UNANSWERED_QUESTION":
      return false
    default:
      return state
  }
}

const QuestionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUESTIONS":
      return action.payload

    default:
      return state
  }
}

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_AUTH_SUCCESS":
      return {
        ...state,
        isLoggedIn: false,
        authInitialized: true,
        email: action.payload,
      }

    case "HANDLE_AUTH_ERROR":
      return {
        ...state,
        isLoggedIn: false,
        authInitialized: true,
        email: null,
      }

    case "HANDLE_AUTH_LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        authInitialized: false,
        email: null,
      }
    default:
      return state
  }
}

const PercentageReducer = (state, action) => {
  switch (action.type) {
    case "SET_PERCENTAGE":
      return action.payload

    default:
      return state
  }
}

const AssessmentControls = (state, action) => {
  switch (action.type) {
    case "SET_STEP":
      return {
        ...state,
        tab: action.payload,
      }

    default:
      return state
  }
}

const HistoryData = (state, action) => {
  switch (action.type) {
    case "SET_HISTORY_DATA":
      return action.payload

    default:
      return state
  }
}

const CurrentAssessment = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_ASSESSMENT":
      return action.payload

    default:
      return state
  }
}

const UserData = (state, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return action.payload

    default:
      return state
  }
}

const DetailsData = (state, action) => {
  switch (action.type) {
    case "SET_DETAILS_DATA":
      return action.payload

    default:
      return state
  }
}
const DetailsDataLoading = (state, action) => {
  switch (action.type) {
    case "SET_DETAILS_DATA_LOADING":
      return action.payload

    default:
      return state
  }
}
export default function appReducer(state, action) {
  return {
    assessment: AssessmentReducer(state.assessment, action),
    assessmentAttempt: AssessmentAttemptReducer(
      state.assessmentAttempt,
      action
    ),
    questionsToShow: QuestionsToShowReducer(state.questionsToShow, action),
    firstUnansweredQuestion: FirstUnansweredReducer(
      state.firstUnansweredQuestion,
      action
    ),

    answerIsPending: AnswerIsPendingReducer(state.answerIsPending, action),
    highlightFirstUnansweredQuestion: highlightFirstUnansweredQuestionReducer(
      state.highlightFirstUnansweredQuestion,
      action
    ),
    questions: QuestionsReducer(state.questions, action),
    percentage: PercentageReducer(state.percentage, action),
    auth: AuthReducer(state.auth, action),
    assessmentControls: AssessmentControls(state.assessmentControls, action),

    historyData: HistoryData(state.historyData, action),
    currentAssessment: CurrentAssessment(state.currentAssessment, action),

    userData: UserData(state.userData, action),
    detailsDataLoading: DetailsDataLoading(state.detailsDataLoading, action),
    detailsData: DetailsData(state.detailsData, action),
  }
}
