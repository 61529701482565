import React from "react"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import "../../css/AboutUs.css"
import fbnlogo from "../../assets/images/FeelingBetterNowLogo.svg"
import leftellipse from "../../assets/images/leftEllipse.png"
import rightellipse from "../../assets/images/rightEllipse.png"

const ClinicalAdvisoryCard = (props) => {
  console.log(props)
  return (
    <div>
      {/* <img src={props.imgSource} alt="" style={{ borderRadius: "6px" }} /> */}
      {/* <img
        src={require("../assets/images/doctors/doctor4.svg")}
        alt=""
        style={{ borderRadius: "6px" }}
      />
      <p className="doctor-name">{props.name}</p>

      <button
        className="bio-btn"
        data-toggle="modal"
        data-target="#exampleModal"
        type="button"
      >
        <h6 className="bio">See bio</h6>
        <ArrowRightAltIcon style={{ fill: "#33CA8F" }} />
      </button> */}

      <div
        className="modal fade"
        id={`doctor${props.id}`}
        // tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="container d-flex justify-content-center">
            <div className="modal-content main-card main-info-card">
              <img src={fbnlogo} className="fbn-logo" />
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-5 col-sm-5">
                    <img
                      className="doctor-img"
                      src={require(`../../assets/images/${props.imgSource}`)}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 col-sm-7 col-7 name-center">
                    <h5
                      className="modal-title doctor-name-card"
                      id="exampleModalLabel"
                    >
                      {props.name}
                    </h5>

                    <h5 className="doctor-accreditations">
                      {props.accreditations}
                    </h5>
                  </div>
                  <div className="doctor-bio" style={{overflowY:"scroll",overflowX:"hidden", paddingRight:"00px"}}>{props.bio}</div>
                  <button
                    type="button"
                    className="btn close-btn-advisory-card advisory-card-btn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <img src={rightellipse} className="right-ellipse" />
                  <img src={leftellipse} className="left-ellipse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClinicalAdvisoryCard
