import React, { useState, useEffect, useContext } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { withTranslation } from "react-i18next"
import _get from "lodash/get"
import _map from "lodash/map"
import _filter from "lodash/filter"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import { baseUrl } from "../../lib/constants"
// import { Navbar } from "../components/Navbar";
import "../../css/main.css"
import logo from "../../assets/images/FeelingBetterNowLogo.svg"
import layer9 from "../../assets/images/Layer9.svg"
import "../../css/pap.scss"
import { Pap } from "../../api/pap"
import date from "date-and-time"
import { SummaryHealthHistory } from "../summary/SummaryHealthHistory"
import { SummaryLifeFunctions } from "../summary/SummaryLifeFunctions"
import { EH2 } from "../summary/Summary"
import Select, { components } from "react-select"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import Tour from "reactour"
import Joyride, { STATUS } from 'react-joyride';

import {
  // EBlockWrapper,
  EMainBlock,
  // ETopMainCaption,
  EDateCaption,
} from "../summary/Summary"
import Medication from "../summary/Medication"
import { PapAssessmentDisordersMagicGrid } from "./PapAssessmentDisordersMagicGrid"
import {
  SharedDisorderBackground,
  SharedDisorderSimpleText,
} from "../../utils/shared"
import { FloatingActionButton } from "../../components/buttons/floatingActionButton"
import { NotificationScheduler } from "../../components/popups/notificationScheduler/NotificationScheduler"
import { ProgressTracker } from "../../components/progressTracker/ProgressTracker"
import { id } from "chartjs-plugin-zoom"
import Footer from "../../components/footer/Footer"
import Comorbid from "./Comorbid"
import Header from "../../components/navbar/Header"
import img1 from "../../assets/images/pap-img1.png"
import BgImg from "../../assets/images/top-bg-bottom-gradient.png"
import {
  SharedDivider,
  SharedDisorderMatrix,
  ComorbidityMatrix,
} from "../../utils/shared"
import { makepdf } from "./PAPMake"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"
import { logEvent } from "../../index"
import { StateContext } from "../../store/state/contexts"
import { useNavigate } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import generatePassword from '../../utils/generatePassword';

const customDropDownStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
  container: (provided, state) => ({
    ...provided,
    maxWidth: '100%',
  }),
  menu: (provided, state) => ({
    ...provided,
    minWidth: 300,
  }),
  control: (provided, state) => ({
    ...provided,
    //width: 200,
    // marginRight:25,
    // marginLeft:10,
    fontSize: "1.7rem",
    color: "#151852",
    border: "none",
    background: "transparent",
    maxWidth: '100%'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#151852",
    position: "static",
    transform: "translateY(0%)",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#151852",
    position: "static",
    transform: "translateY(0%)",
    maxWidth: "auto",
    whiteSpace: "nowrap !important"
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: "none",
  })
}

const customDropDownStylesPAP = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
  container: (provided, state) => ({
    ...provided,
    maxWidth: '100%',
  }),
  menu: (provided, state) => ({
    ...provided,
    minWidth: 300,
  }),
  control: (provided, state) => ({
    ...provided,
    //width: 200,
    // marginRight:25,
    // marginLeft:10,
    fontSize: "1.7rem",
    color: "#151852",
    border: "none",
    background: "transparent",
    maxWidth: '100%'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#151852",
    position: "static",
    transform: "translateY(0%)",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#151852",
    position: "static",
    transform: "translateY(0%)",
    maxWidth: "auto",
    whiteSpace:"wrap !important",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: "none",
  })
}

const loremIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eleifend quam adipiscing vitae proin sagittis nisl. Aliquet risus feugiat in ante metus dictum at tempor. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Neque volutpat ac tincidunt vitae semper quis lectus. Auctor eu augue ut lectus arcu bibendum at varius. Pulvinar neque laoreet suspendisse interdum. Orci eu lobortis elementum nibh tellus. Tellus in hac habitasse platea. Et sollicitudin ac orci phasellus egestas. Odio ut enim blandit volutpat maecenas volutpat blandit."
const ETopMainCaption = styled.div`
  background-color: #f7f7f7;
  border-bottom: 2px solid #eee;
  margin: 0;
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-item: center;
  h1 {
    color: #151852;
    font-size: 1.7em;
    font-weight: 600;
    margin: 0;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`
const EBlockWrapper = styled.div`
  font-family: "CircularStdBook";
  margin: 8em auto 0 auto;
  max-width: 83em;
  padding: 0 1em;
  position: relative;
  z-index: 1.5;
  @media (max-width: 1440px) {
    max-width: 90em;
  }
`

export const EWrapper = styled.div`
  // max-width: 74em;
  margin: 0 auto;
  padding-bottom: 5em;

  @media (max-width: 1080px) {
    font-size: 0.7em;
  }

  @media (max-width: 1180px) {
    font-size: 0.8em;
  }

  @media (max-width: 1280px) {
    font-size: 0.9em;
  }

  @media (min-width: 1480px) {
    font-size: 1.1em;
  }
`

export const ELoadingWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    transform: scale(5);
  }
  font-family: "CircularStdBook";
`

export const ESymptomsGrid = styled.ul`
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 1.5em;
  border-radius: 1em !important;
  background-color: #e1f4f4;
  padding: 1.8em 1.4em;
  font-family: "CircularStdBook";
  grid-template-columns: 9fr 10fr;

  i {
    font-size: 1.3em;
    margin-right: 0.5em;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

export const EList = styled.div`
  display: grid;
  grid-row-gap: 1.5em;
  font-family: "CircularStdBook";

  > div {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1em;
  }

  i {
    font-size: 1.3em;
    margin-right: 0.5em;
  }
`

export const EMedListWrapper = styled.div`
  border: 1px solid #eaeaea;
  background-color: #fafafa;
  margin: 1em 2.3em 0 2.3em;
  border-radius: 0.5em;
`

export const EP1 = styled.p`
  font-size: 1.1em;
  font-family: "CircularStdBook";
  line-height: 1.8;
  margin: 0;
  color: ${(props) => (props.color ? props.color : "#151852")};
`

export const EP2 = styled.p`
  font-size: 0.87em;
  font-weight: 500;
  font-family: "CircularStdBook";
  margin: 0;
`

export const EHeaderWrapper = styled.div`
  min-height: 5em;
  display: grid;
  grid-template-columns: 18fr 10fr;
  grid-column-gap: 2em;
  background-color: #e1f4f4;
  align-items: center;
  padding: 0 2.5em;
  color: #151852;
  font-family: "CircularStdBook";

  .info {
    font-size: 1.44em;
    display: grid;
    grid-template-columns: 1fr max-content;
  }
`

export const ETipsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.8em;
  grid-row-gap: 2.3em;
  font-family: "CircularStdBook";
  margin: 2.6em 2.3em 0 2.3em;

  > div {
    border: 1px solid #d7d7d7;
    border-radius: 0.5em;
    padding: 2em;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 1.4em;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export const ETipsGridImage = styled.div`
  padding: 1.4em;
  background-color: #ebebeb;
  border-radius: 100%;
  img {
    width: 2.2em;
    height: 2.2em;
    object-fit: contain;
  }
`

export const EPapCardRoot = styled.div`
  border-radius: 0.8em 0.8em 0.8em 0.8em / 0.4em 0.4em 0.4em 0.4em;
  box-shadow: 0px 4px 21px rgba(55, 62, 61, 0.23);
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  font-family: "CircularStdBook";
  padding-bottom: 5em;
`

export const EPapDisorderCaption = styled.h2`
  font-size: 2em;
  font-weight: 600;
  color: #151852;
  font-family: "CircularStdBook";
  margin: 0;
`

export const EDescriptionAndSymptoms = styled.div`
  display: grid;
  grid-template-columns: 14fr 7.5fr;
  grid-column-gap: 2em;
  margin: 2.3em 2.3em 0 2.3em;
`

export const EMedicationAndClinicalSteps = styled.div`
  display: grid;
  grid-template-columns: 14fr 7.5fr;
  grid-column-gap: 2em;
  margin: 2.3em 2.3em 0 2.3em;
`

export const EH2Orange = styled.h2`
  color: #f3763e;
  font-size: 1.5em;
  margin: 0;
`

export const MedicationTableHeder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 1.3em;
  grid-column-gap: 1em;

  p {
    color: #8d8ea5;
  }
`

export const MedicationTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  overflow: hidden;

  > div:nth-of-type(odd) {
    background-color: #e1f4f4;
  }
  > div:nth-of-type(even) {
    background-color: #f8fcff;
  }
`

export const MedicationTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
  padding: 1.08em 0 1.08em 1.3em;
  p {
    font-weight: 400 !important;
  }
`

const noAssessmentIdErrorMessage =
  "Please, select the assessment you want to view plan for"

const lngs = {
  en: { nativeName: "En" },
  fr: { nativeName: "Fr" },
}

const PAP = ({ match, t, i18n, location }) => {
  const [assessmentDisorders, setAssessmentDisorders] = useState([])
  const [comorbidDisorderList, setComorbidDisorderList] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [loadingDetails, setLoadingDetails] = useState(true)
  const [detailsData, setDetailsData] = useState([])
  const [showNotificationSettings, setShowNotificationSettings] =
    useState(false)
  const [showFloatingButton, setShowFloatingbutton] = useState(false)
  const [showCheckList, setShowCheckList] = useState(false)
  const [fullHistory, setFullHistory] = useState([])
  const [open, setOpen] = React.useState(false);
  const history = useNavigate();
  const { state, dispatch } = useContext(StateContext)

  const getDetails = async (link) => {
    await fetch(link, { credentials: "include", mode: "cors" })
      .then(async (response) => {
        logEvent("getDetails /assessment/details successful")
        if (response.status >= 200 && response.status <= 299) {
          let _detailsData = await response.json()
          setDetailsData(_detailsData)
        } else {
          setOpen(true);
          setTimeout(() => {
            history.goBack();
          }, 5000);
        }
      })
      .catch((response) => {
        setOpen(true);
        setTimeout(() => {
          history.goBack();
        }, 5000);
        logEvent("getDetails /assessment/details failed")
        console.log("error getting details", response)
      })
    setLoadingDetails(false)
  }

  const getHistory = async () => {
    try {
      var response = await fetch(baseUrl + "/assessment/history", {
        credentials: "include",
        mode: "cors",
      })
      let history = await response.json()
      setFullHistory(history)
    } catch (err) {
      console.log("PAP.jsx", err);
    }
  }

  useEffect(() => {
    setLoading(true)
    const id = _get(match, ["params", "id"])

    if (!id) {
      setError(noAssessmentIdErrorMessage)
      setLoading(false)
      return
    }

    

    //use json disorder while endpoint not implemented
    // Disorder.get(disorderFiles[3])
    //   .then((data) => {
    //     if (data && data.status && data.status === 200) {
    //       setAssessmentDisorders([data.data]);
    //     } else {
    //       throw "Error";
    //     }

    //     if (data.length === 0) {
    //       setError("Assessment list is empty");
    //     }

    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     if (
    //       err &&
    //       err.response &&
    //       err.response.data &&
    //       err.response.data.message
    //     ) {
    //       setError(err.response.data.message);
    //     } else {
    //       setError("Error");
    //     }

    //     setLoading(false);
    //   });

    getHistory()

    getDetails(`${baseUrl}/assessment/details/${id}`)
    Pap.get(id)
      .then((data) => {
        // console.log('chiraggg', data)
        if (data && data.status && data.status === 200) {
          setAssessmentDisorders(data.data.map(d => d.title === 'Mania/Hypomania' ? { ...d, title: 'Bipolar Disorder' } : d))
          let newComorbidDisorderList = []
          data.data.map((disorder) => {
            if (disorder.riskStatus === "High Risk") {
              newComorbidDisorderList = [
                ...newComorbidDisorderList,
                disorder.title,
              ]
              setComorbidDisorderList(newComorbidDisorderList)
            }
          })
        } else {
          throw "Error"
        }

        if (data.length === 0) {
          setError("Assessment list is empty")
        }

        setLoading(false)
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          setError(err.response.data.message)
        } else {
          setError("Error")
        }

        setLoading(false)
      })
  }, [_get(match, ["params", "id"])])

  const showNotificationSettingsHandler = (isOpen) => {
    console.log(isOpen, "HERE")
    setShowNotificationSettings(isOpen)
    console.log(showNotificationSettings, "ASD")
    return showNotificationSettings
  }

  const showCheckListHandler = (isOpen) => {
    console.log(isOpen)
    setShowCheckList(isOpen)
    return showCheckList
  }

  const toggleNotifSettings = () => {
    setShowNotificationSettings(!showNotificationSettings)
    return showNotificationSettings
  }

  const progressTrackerSignUpNotifsHandler = async () => {
    try {
      const id = _get(match, ["params", "id"])
      await fetch(`${baseUrl}/progress-tracker/signup-notifs/${id}`, {
        credentials: "include",
        mode: "cors",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      logEvent(
        "progressTrackerSignUpNotifsHandler /progress-tracker/signup-notifs successful"
      )
    } catch (err) {
      console.log("PAP.jsx", err);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <View
        assessmentDisorders={assessmentDisorders}
        comorbidDisorderList={comorbidDisorderList}
        isLoading={isLoading}
        error={error}
        detailsData={detailsData}
        loadingDetails={loadingDetails}
        fullHistory={fullHistory}
        showFloatingButton={showFloatingButton}
        showCheckList={showCheckList}
        showNotificationSettings={showNotificationSettings}
        showNotificationSettingsHandler={showNotificationSettingsHandler}
        showCheckListHandler={showCheckListHandler}
        toggleNotifSettings={toggleNotifSettings}
        signUpNotifsHandler={progressTrackerSignUpNotifsHandler}
        state={state}
        t={t}
        i18n={i18n}
        location={location}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">You're not authorized to access this page</Alert>
      </Snackbar>
    </>
  )
}

export default withTranslation()(PAP)

let disorders = [
  {
    title: "Depression",
    riskStatus: 'N/A'
  },
  {
    title: "General Anxiety Disorder",
    riskStatus: 'N/A'
  },
  {
    title: "ADHD",
    riskStatus: 'N/A'
  },
  {
    title: "Alcohol and Substance Abuse",
    riskStatus: 'N/A'
  },
  {
    title: "Anger Problems",
    riskStatus: 'N/A'
  },
  {
    title: "Eating Disorder",
    riskStatus: 'N/A'
  },
  {
    title: "Sleep Disorder (Insomnia)",
    riskStatus: 'N/A'
  },
  {
    title: "Bipolar Disorder",
    riskStatus: 'N/A'
  },
  {
    title: "Panic Disorder",
    riskStatus: 'N/A'
  },
  {
    title: "PTSD",
    riskStatus: 'N/A'
  },
  {
    title: "Social Anxiety",
    riskStatus: 'N/A'
  },
  {
    title: "OCD",
    riskStatus: 'N/A'
  },
  {
    title: "Adjustment Disorder",
    riskStatus: 'N/A'
  }
]

export const View = ({
  assessmentDisorders,
  comorbidDisorderList,
  isLoading,
  loadingDetails,
  error,
  detailsData,
  showFloatingButton,
  showCheckList,
  showNotificationSettings,
  showNotificationSettingsHandler,
  showCheckListHandler,
  toggleNotifSettings,
  signUpNotifsHandler,
  fullHistory,
  state,
  t,
  i18n,
  location
}) => {
  const defaultOverviewTitle = 'Personal Action Plan (PAP)'
  const [selectedDisorder, setDisorder] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(null);
  const [tourOpen, setTourOpen] = useState(false);
  const [anchor, setAnchor] = useState('');
  const [ run, setRun ] = useState(false);

  const closeTour = () => {
    if(menuIsOpen) closeSelect();
    enableBodyScroll();
    setTourTooltipViewed();
    setTourOpen(false);
  };
 
  const openSelect = () => {
    setMenuIsOpen(true);
  };
  const closeSelect = () => {
    setMenuIsOpen(false);
  };
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);


  const anchorSelector = (el) => {
    if(el == anchor || !document.querySelector(el)) return false;

    setAnchor(el);
  
    let currentEl = document.querySelector(el);
    let rect = currentEl.getBoundingClientRect();

    if(rect.top + rect.height > window.innerHeight / 1.5 || rect.top < 50) {
      document.scrollingElement.scrollTop += rect.top - 50;
    }
  }

  const tourConfig = [
    {
      disableBeacon: true,
      target: '[data-tut="tooltip-select"]',
      content: t("papPage.tour.step1"),
      action: () => {},
    },
    // {
    //   target: '[data-tut="tooltip-select"] [class*="-option"]',
    //   content: t("papPage.tour.step2"),
    //   action: () => {
    //     // setMenuIsOpen(true);
    //     // anchorSelector('[data-tut="tooltip-select"] [class*="-option"]');
    //   },
    //   position: 'right'
    // },
    {
      disableBeacon: true,
      target: '[data-tut="tooltip-print"]',
      content: t("papPage.tour.step3"),
      action: () => {},
    },
  ];

  const showTourTooltipBool = () => {
    let tourTooltipCount = localStorage.getItem('__FBN_PAP_TOURTOOLTIP_SHOW__');
    if(tourTooltipCount){
      tourTooltipCount = parseInt(tourTooltipCount);
      if(tourTooltipCount < 4 && tourTooltipCount != 3){
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  useEffect(() => {
    setTourOpen(true);
    if (showTourTooltipBool()) {
      setMenuIsOpen(true);
      setRun(true);
    }
    let disord = null
    if (location && location.state && location.state.name) {
      disord = assessmentDisorders.find(d => {
        return d.title == location.state.name
      })
      // console.log('selected disorder', location.state.name, disord, ' <== selected')
    }
    setDisorder(disord)
  }, [location, assessmentDisorders])
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      if(location && location.state && location.state.name){
        location.state.name = null
      }
    }
  }, [])
  const select = (_selectedDisorder) => {
    setDisorder(_selectedDisorder)
    setMenuIsOpen(false);
  }
  const language = i18n.language;

  const setTourTooltipViewed = () => {
    let tourTooltipCount = localStorage.getItem('__FBN_PAP_TOURTOOLTIP_SHOW__');
    if(tourTooltipCount){
      tourTooltipCount = parseInt(tourTooltipCount);
      localStorage.setItem('__FBN_PAP_TOURTOOLTIP_SHOW__',tourTooltipCount + 1);
    } else {
      localStorage.setItem('__FBN_PAP_TOURTOOLTIP_SHOW__',1);
    }
  }

  const handleJoyrideCallback = async (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      if (menuIsOpen) closeSelect();
      setTourTooltipViewed();
    }
    if (type === 'step:before') {
      // setMenuIsOpen(true);
      // data.step.action();
    }
    // console.log('step occured', type);
    // console.groupCollapsed(type);
    // console.log('callback', data);
    // console.groupEnd();
  };
  let naAssessmentDisorders = disorders.map(disorder => assessmentDisorders.find(ad => ad.title == disorder.title) || disorder )

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <>
        <div
          css={css`
            background-color: #ecf8f8;
            font-family: "CircularStdBook";
          `}
        >
          <img
            className="no-print"
            src={BgImg}
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            `}
          />

          {/* <SharedDivider count={2} /> */}
          <div className="no-print">
            <Header
              customWidth="95%"
              customMaxWidth="1440px"
              education_id={detailsData._id}
              fromPAP={!!true}
              setRun={setRun}
            />
          </div>
          {console.log('debug loading : ', isLoading, loadingDetails)}
          { (!!isLoading ||
            !!loadingDetails)  && (
          <ELoadingWrapper>
            <div className="text-center my-5">
              <div
                className="spinner-border assessmentSpinner papSpinner"
                role="status"
              ></div>
              <span style={{ fontSize: '0.5rem' }} className="h1 ml-1">{t("summaryPage.loading")}</span>
            </div>
          </ELoadingWrapper>
            )}

          {!!error && !isLoading && (
            <ELoadingWrapper>
            <div className="text-center my-5">
              <div
                className="spinner-border assessmentSpinner"
                role="status"
              ></div>
              <span style={{ fontSize: '0.8rem' }} className="h1 ml-2">{t("summaryPage.loading")}</span>
            </div>
          </ELoadingWrapper>
            // <div className="papCard" style={{ minHeight: '70vh', display: 'flex' }}>
            //   <div
            //     className="card shadow radius"
            //     style={{ overflow: "hidden" }}
            //   >
            //     <div className="card-header d-flex papCard-header align-items-center wow fadeInUp border-0">
            //       <div className="p-2 pl-3 flex-grow-1 papHeadingDisorder">
            //         {error}
            //       </div>

            //       {!!error && error === noAssessmentIdErrorMessage && (
            //         <div
            //           css={css`
            //             margin: auto;
            //           `}
            //         >
            //           <Link
            //             to="/about"
            //             className="btn btn-primary btn-lg active"
            //             role="button"
            //             aria-pressed="true"
            //           >
            //             Go home
            //           </Link>
            //         </div>
            //       )}
            //     </div>
            //   </div>
            // </div>
          )}

          {!error && !loadingDetails && (!selectedDisorder || (selectedDisorder && selectedDisorder.title === defaultOverviewTitle)) && (
            <EWrapper>
              <EBlockWrapper>
                <EMainBlock>
                  <ETopMainCaption
                    id="section-to-print"
                    css={css`
                      grid-template-columns: max-content 1fr;
                      @media (max-width: 1200px) {
                        grid-template-columns: max-content 1fr;
                      }
                      display: flex !important;
                      justify-content: space-between !important;
                    `}
                  >
                    <div data-tut="tooltip-select" style={{ wordBreak: 'break-word', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                      <Select
                        components={{ IndicatorSeparator: null, clearIndicator: null,
                          Control: ({ children, ...rest }) => (<div onClick={() => setMenuIsOpen(!menuIsOpen)}>
                              <components.Control {...rest}>
                                {children}
                              </components.Control>
                          </div>
                          )
                        }}
                        placeholder={t("papPage.headline")}
                        className="basic-single"
                        isClearable
                        menuIsOpen={menuIsOpen}
                        onFocus={openSelect}
                        onBlur={closeSelect}
                        isSearchable={false}
                        options={[{ title: defaultOverviewTitle, value: null }, ...assessmentDisorders]}
                        onChange={select}
                        value={selectedDisorder}
                        styles={customDropDownStylesPAP}
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.title}
                      />
                      <span className="text-muted">{t('papPage.dropDownExplainer')}</span>
                    </div>
                    {/* <h1>Personalized Action Plan (PAP)</h1> */}
                    <button
                      data-tut="tooltip-print"
                      css={css`
                        border: none;
                        background-color: inherit;
                        display: flex !important;
                        align-items: center !important;
                      `}
                      onClick={() =>{
                        let passwordChanged = false;
                        if(!detailsData.user || !detailsData.user.pdfPassword) {
                          let autoGeneratedPassword = generatePassword();
                          detailsData.user.pdfPassword = autoGeneratedPassword;
                          passwordChanged = true;
                        }
                        makepdf(
                          detailsData,
                          assessmentDisorders,
                          fullHistory,
                          "print",
                          state,
                          passwordChanged
                        )
                      }}
                    >
                      <img
                        className="no-print"
                        src={require("../../assets/images/printerIcon.svg").default}
                        alt=""
                      />
                      <h1
                        className="no-print"
                        css={css`
                          font-size: 16px !important;
                          color: #69696f !important;
                          padding-left: 8px !important;
                        `}
                      >
                        {t("papPage.print")}
                      </h1>
                    </button>
                  </ETopMainCaption>
                  <div
                    css={css`
                      display: flex;
                      grid-template-columns: 2fr 1fr;
                      margin: 2.3em 2em 0 2em;

                      @media (min-width: 900px) {
                        display: grid;
                        grid-column-gap: 6em;
                      }
                    `}
                  >
                    <div>
                      <h2
                        css={css`
                          color: #151852;
                          font-size: 1.9em;
                          font-weight: 600;
                          margin: 0 0 0.5em 0;
                          border-radius: 8px;
                        `}
                      >
                        {t("papPage.about")}
                      </h2>
                      <p
                        css={css`
                          color: #505050;
                          font-size: 1.15em;
                        `}
                      >
                        {t("papPage.description1")}
                      </p>
                      <p
                        css={css`
                          color: #505050;
                          font-size: 1.15em;
                        `}
                      >
                        {t("papPage.description2")}
                      </p>
                      <p
                        css={css`
                          color: #505050;
                          font-size: 1.15em;
                        `}
                      >
                        {t("papPage.description3")}
                      </p>
                      <p
                        css={css`
                          color: #505050;
                          font-size: 1.15em;
                        `}
                      >
                        {t("papPage.description3_1")}
                      </p>

                      <div
                        css={css`
                          color: #505050;
                          border: 1px solid #dcf0f0;
                          background: #f6fefe;
                          padding: 1.2em;
                          margin-top: 1.4em;
                          border-radius: 8px;
                          @media (min-width: 1000px) {
                            padding-right: 3.2em;
                          }
                        `}
                      >
                        <EH2
                          css={css`
                            margin: 0 0 0.7em 0;
                          `}
                        >
                          {t("papPage.source")}
                        </EH2>

                        <p
                          css={css`
                            color: #505050;
                            font-size: 1.15em;
                            margin: 0;
                          `}
                        >
                          {t("papPage.description4")}
                        </p>
                        <p
                          css={css`
                            color: #505050;
                            font-size: 1.15em;
                            margin: 0;
                          `}
                        >
                          {t("papPage.description5")}
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        src={img1}
                        css={css`
                          max-width: 100%;
                          display: none;
                          @media (min-width: 900px) {
                            padding-top: 2em;
                            padding-right: 2em;
                            display: block;
                          }
                        `}
                      />
                    </div>
                  </div>

                  <div
                    css={css`
                      width: 100%;
                      background-color: #eee;
                      height: 1px;
                      margin: 1.4em 0;
                    `}
                  ></div>

                  <div
                    css={css`
                      margin: 2em;
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-row-gap: 1em;
                      grid-column-gap: 1em;

                      @media (max-width: 900px) {
                        grid-template-columns: 1fr;
                      }
                    `}
                  >
                    {/* <div>
                      <EH2
                        css={css`
                          margin-bottom: 0.5em;
                        `}
                      >
                        Comorbidity groups
                      </EH2>

                      <div
                        css={css`
                          display: grid;
                          grid-row-gap: 1em;
                          grid-template-columns: 1fr;
                        `}
                      >
                        <div
                          css={css`
                            background: #fff9f5;
                            border-radius: 4px;
                            padding: 1em;
                          `}
                        >
                          <EH2
                            css={css`
                              margin-bottom: 0.5em;
                            `}
                          >
                            Substance Abuse & Anxiety
                          </EH2>

                          <p
                            css={css`
                              font-size: 1.2em;
                            `}
                          >
                            You have to treat the underlying mood disorder
                            before treating the substance abuse; for which the
                            patient is self-medicating.
                          </p>
                        </div>
                        <div
                          css={css`
                            background: #f8f8f7;
                            border-radius: 4px;
                            padding: 1em;
                          `}
                        >
                          <EH2
                            css={css`
                              margin-bottom: 1em;
                            `}
                          >
                            Lorem ipsum dolor sit amet
                          </EH2>

                          <p
                            css={css`
                              font-size: 1.2em;
                            `}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                          </p>
                        </div>
                      </div>
                    </div> */}

                    <div>
                      <EH2
                        css={css`
                          margin-bottom: 1em;
                        `}
                      >
                        {t("papPage.mentalRisk")}
                      </EH2>

                      <SharedDisorderMatrix>
                        <div>
                          <p>{t("papPage.disorder")}</p>
                          <p>{t("papPage.riskLevel")}</p>
                        </div>
                        {naAssessmentDisorders.map((j, index) => (
                          <div
                          key={index}
                          css={css`
                          display: flex;
                          align-items: center;
                          `}
                          >
                            <p>{_get(j, "title")}</p>
                            <p>
                              <span
                                css={css`
                                  font-size: 0.5em;
                                  margin-right: 0.8em;
                                `}
                              >
                                {j.riskStatus === "High Risk" ? (
                                  <i className="fas fa-circle text-danger"></i>
                                ) : j.riskStatus === "Moderate Risk" ? (
                                  <i className="fas fa-circle text-warning"></i>
                                ) : j.riskStatus === 'N/A' ? <></> : (
                                  <i className="fas fa-circle text-success"></i>
                                )}
                              </span>
                              {_get(j, "riskStatus")}
                            </p>
                          </div>
                        ))}
                      </SharedDisorderMatrix>
                    </div>
                    <div>
                      <EH2
                        css={css`
                          margin-bottom: 1em;
                        `}
                      >
                        {t("papPage.comorbidityGroups")}
                      </EH2>
                      <ComorbidityMatrix>
                        <div>
                          <p>{t("papPage.comorbidityIllness")}</p>
                          <p>{t("papPage.relatedIllness")}</p>
                        </div>
                        {comorbidDisorderList.length > 0 && (
                          <>
                            {comorbidDisorderList.map((j) => (
                              <div
                                key={`${j}comorbidmatrix`}
                                css={css`
                                  display: flex;
                                  align-items: center;
                                `}
                              >
                                <p>{j == 'Mania/Hypomania' ? 'Bipolar Disorder' : j}</p>
                                <Comorbid illness={j} comorbidDisorderList={comorbidDisorderList} />
                              </div>
                            ))}
                          </>
                        )}
                      </ComorbidityMatrix>
                      <p className="mt-2">
                      {t("papPage.comorbidDescription")}
                      </p>
                    </div>
                  </div>

                  <div
                    css={css`
                      width: 100%;
                      background-color: #eee;
                      height: 1px;
                      margin: 1.4em 0;
                    `}
                  ></div>

                  <div
                    css={css`
                      margin: 0 2em 2em 2em;
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-column-gap: 2em;
                      @media (max-width: 1200px) {
                        grid-template-columns: 1fr;
                      }
                    `}
                  >
                    {detailsData && detailsData.health_history_responses && (
                      <div>
                        <EH2
                          css={css`
                            margin: 0.5em 0 0.5em 0;
                          `}
                        >
                          {t("papPage.healthHistory")}
                        </EH2>
                        <SummaryHealthHistory
                          health_history_responses={
                            detailsData.health_history_responses
                          }
                          lang={i18n.language}
                        />
                      </div>
                    )}

                    {detailsData && detailsData.life_functions_responses && (
                      <div>
                        <EH2
                          css={css`
                            margin: 0.5em 0 0.5em 0;
                          `}
                        >
                          {t("papPage.lifeFunction")}
                        </EH2>

                        <SummaryLifeFunctions
                          life_functions_responses={
                            detailsData.life_functions_responses
                          }
                          lang={i18n.language}
                        />
                      </div>
                    )}
                  </div>
                </EMainBlock>
              </EBlockWrapper>
            </EWrapper>
          )}

          {!isLoading &&
            !error &&
            (selectedDisorder && selectedDisorder.title !== defaultOverviewTitle) &&
            _map(
              selectedDisorder ? [selectedDisorder] : [],
              (j, index) => (
                <React.Fragment key={`disorders-pap${index}`}>
                  <EWrapper>
                    <EBlockWrapper>
                      <EMainBlock>
                        <ETopMainCaption
                          className="row"
                          css={css`
                            grid-template-columns: 3fr 1fr 1fr;
                            @media (max-width: 1200px) {
                              grid-template-columns: 3fr 1fr 1fr;
                            }
                          `}
                        >
                          {/* <h1>{j.title}</h1> */}
                          <Select
                            components={{ IndicatorSeparator: null }}
                            placeholder={j.title}
                            className="basic-single"
                            isClearable
                            isSearchable={false}
                            options={[{title: defaultOverviewTitle, value: null}, ...assessmentDisorders]}
                            onChange={select}
                            value={selectedDisorder}
                            styles={customDropDownStyles}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.title}
                          />

                          <EDateCaption
                            css={css`
                              font-size: 1.1em;
                              @media (min-width: 1201px) {
                                justify-self: center;
                              }
                            `}
                          >
                            {/* {t("papPage.score")}: {j.score}% */}
                          </EDateCaption>

                          <EDateCaption
                            css={css`
                              justify-self: flex-end;
                            `}
                          >
                            {t("papPage.riskLevel")}:
                            {j.riskStatus === "High Risk" ? (
                              <i className="fas fa-circle text-danger circle-margin"></i>
                            ) : j.riskStatus === "Moderate Risk" ? (
                              <i className="fas fa-circle text-warning circle-margin"></i>
                            ) : (
                              <i className="fas fa-circle text-success circle-margin"></i>
                            )}
                            {j.riskStatus === "High Risk" ? (
                              <>{t("papPage.high")}</>
                            ) : j.riskStatus === "Moderate Risk" ? (
                              <>{t("papPage.moderate")}</>
                            ) : (
                              <>{t("papPage.low")}</>
                            )}
                          </EDateCaption>
                        </ETopMainCaption>

                        <div
                          css={css`
                            margin: 1.15em 0.5em;
                          `}
                        >
                          <PapAssessmentDisordersMagicGrid
                            selectedDisorder={selectedDisorder}
                            description={j.description}
                            symptoms={j.symptoms}
                            tips={j.tips}
                            // clinical_steps={j.clinical_steps}
                            medicationadvice={j.medicationadvice}
                            talk_therapy={j.therapy || []}
                            talk_therapy_point_ids={[]} // j.talk_therapy_point_ids
                            lang={i18n.language}
                            support={j.support || []}
                            t={t}
                            i18n={i18n}
                          />

                          {/* {!!(j.medication && j.medication.length) && (
                            <Medication
                              medication={j.medication}
                              name={assessmentDisorders.title}
                              lang={i18n.language}
                              t={t}
                              i18n={i18n}
                            />
                          )} */}

                          {!!j.pap_cards && (
                            <ETipsGrid>
                              {_map(j.pap_cards, (i, index) => (
                                <div key={`pap_cards${index}`}>
                                  <div>
                                    <ETipsGridImage>
                                      <img src={layer9} />
                                    </ETipsGridImage>
                                  </div>

                                  <EP1
                                    css={css`
                                      font-size: 1em;
                                    `}
                                  >
                                    {i.text.language}
                                  </EP1>
                                </div>
                              ))}
                            </ETipsGrid>
                          )}
                        </div>
                      </EMainBlock>
                    </EBlockWrapper>
                  </EWrapper>
                </React.Fragment>
              )
            )}
          {/* <FloatingActionButton
            showFloatingButton={showFloatingButton}
            showNotificationSettings={showNotificationSettingsHandler}
            showCheckList={showCheckListHandler}
          /> */}

          {showNotificationSettings && (
            <NotificationScheduler
              t={t}
              i18n={i18n}
              onClose={toggleNotifSettings}
              signUpNotifsHandler={signUpNotifsHandler}
            />
          )}

          {showCheckList && (
            <ProgressTracker
              closeCheckList={showCheckListHandler}
              assessment_id={detailsData.id}
            />
          )}
        </div>
        {/* <div className="downButtons">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              className="lngBtn"
              style={{
                fontWeight: i18n.language === lng ? "bold" : "normal",
                outline: 0,
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng].nativeName}
            </button>
          ))}
        </div> */}
        {(!isLoading && !loadingDetails) &&
        (!selectedDisorder || (selectedDisorder && selectedDisorder.title === defaultOverviewTitle)) &&
        // <Tour
        //     onRequestClose={closeTour}
        //     steps={tourConfig}
        //     isOpen={tourOpen}
        //     disableInteraction={true}
        //     maskClassName="tour-mask"
        //     className="helper"
        //     rounded={5}
        //     accentColor='#151852'
        //     // onAfterOpen={disableBody}
        //     // onBeforeClose={enableBody}
        //     update={anchor}
        //   />
        <Joyride
          callback={handleJoyrideCallback}
          continuous={!!true}
          run={run}
          autoStart
          scrollToFirstStep={!!true}
          showProgress={!!false}
          showSkipButton={!!true}
          steps={tourConfig}
          floaterProps={{ options: { disableAnimation: true }, disableAnimation: true }}
          styles={{
            options: {
              zIndex: 10000,
              backgroundColor: '#e3ffeb',
              primaryColor: '#33CA8F'
            },
          }}
        />
        }
        <div className="no-print">
          <Footer customClass="custom-w" />
        </div>
      </>
    </ErrorBoundary>
  )
}
