import React, { useEffect, useState, useRef } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import _get from "lodash/get"
import { WindowDimentions } from "../../utils/WindowDimentions"
import CircleAngleTop from "../../assets/images/circle-angle-top.svg"

const EAccordionWrapper = styled.div`
  border: 2px solid #dfefef;
  border-radius: 0.3em;
  overflow: hidden;
  max-height: ${(props) => `${props.maxHeight}px`};
  transition: all 0.5s;
  margin: 1em 0;
`

const EAccordionButton = styled.div`
  outline: 0 !important;
  background-color: #f2fafa;
  width: 100%;
  padding: 1.2em 0.8em;
  display: grid;
  grid-template-columns: 18em 1fr 1.8em;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-bottom: 2px solid #dfefef;
  cursor: pointer;

  @media (max-width: 700px) {
    display: flex;
  }

  h3 {
    font-size: 1.2em;
    font-family: "CircularStdBook", Arial, Helvetica, sans-serif;
    margin: 0;
    color: #383940;
    display: block;
    font-weight: 500;
  }
  p {
    @media (max-width: 700px) {
      display: none;
    }
  }
  img {
    transition: all 0.2s;
    transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
    @media (max-width: 700px) {
      display: none;
    }
  }
`

const EAccordionContent = styled.div`
  padding: 1.3em 0;
`

const ECircle = styled.div`
  width: 0.8em;
  height: 0.8em;
  background-color: ${(props) =>
    props.riskStatus === "High Risk"
      ? "#FF5656"
      : props.riskStatus === "Moderate Risk"
      ? "#FFD556"
      : "#3BC653"};
  border-radius: 100%;
  margin-right: 0.6em;
`

const ERiskStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 1.2em;
    margin: 0;
    color: #505050;
  }
`

const Component = ({ headerText, children, isOpen, setOpen, riskStatus, lang }) => {
  const [contentHeight, setContentHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const contentRef = useRef(null)
  const headerRef = useRef(null)

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight)
  }, [lang])

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight)
  }, [lang])
  return (
    <EAccordionWrapper
      maxHeight={isOpen ? contentHeight + headerHeight + 1000 : headerHeight}
    >
      <EAccordionButton
        tabIndex={0}
        ref={headerRef}
        onClick={() => {
          setOpen(!isOpen)
        }}
        isOpen={isOpen}
      >
        <h3>{headerText != 'Mania/Hypomania' ? headerText : 'Bipolar Disorder'}</h3>

        {!!riskStatus && (
          <ERiskStatusWrapper>
            <ECircle riskStatus={riskStatus} />

            <p>{riskStatus}</p>
          </ERiskStatusWrapper>
        )}
        {!riskStatus && <div />}

        <img
          src={CircleAngleTop}
          css={css`
            width: 1.5em;
          `}
        />
      </EAccordionButton>

      <EAccordionContent ref={contentRef}>{children}</EAccordionContent>
    </EAccordionWrapper>
  )
}

export const Accordion = ({
  headerText,
  children,
  openByDefault = false,
  id,
  riskStatus,
  lang,
}) => {
  const [isOpen, setOpen] = useState(openByDefault)

  return (
    <WindowDimentions
      render={({ width }) => (
        <Component
          key={`refreshonwidth${width}${id}`}
          headerText={headerText}
          children={children}
          isOpen={isOpen}
          setOpen={setOpen}
          riskStatus={riskStatus}
          lang={lang}
        />
      )}
    />
  )
}

{
  /* <AccordionComponent
heading={heading}
id={id}
target={target}
iconId={iconId}
data={data}
onClickThirdCollapse={this.handleClickThirdCollapse}
key={`refreshonwidth${width}${id}`}
printView={printView}
/> */
}
