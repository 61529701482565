import React, { useState, useEffect } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  SharedDisorderBackground,
  SharedDisorderSimpleText,
  SharedDisorderHeaderText,
} from "../../utils/shared"
import Language from "@material-ui/icons/Language"

export const Description2 = ({ selectedDisorder, texts, language, caption = "Description" }) => {
  let [ showMore, setShowMore ] = useState(false)
  useEffect(() => {
    setShowMore(false)
    window.dispatchEvent(new Event('resize'))
  }, [selectedDisorder])
  return (
  <SharedDisorderBackground id="descripbox">
    <SharedDisorderHeaderText> {caption} </SharedDisorderHeaderText>
    <div
      className={ showMore ? "show-more" : "description-hidden" }
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0.6em;
      `}
    >
      {texts.map((i, index) => (
          (Array.isArray(i)) ?
          i.map((innerList, innerIndex) => (
              (innerList['object']) ? 
                <>
                  <div style={{backgroundColor: "white"}}>
                    <div className="descriptionList">
                      <strong>{innerList['object']['title'][language]}</strong>
                      {innerList['object']['list'].map((innerListParagraph, innerParaIndex) => (
                        <SharedDisorderSimpleText key={`${innerListParagraph.text[language].substring(0, 8)}d2${innerParaIndex}`}>  
                            {innerListParagraph.text[language]}
                        </SharedDisorderSimpleText>
                      ))}
                    </div>
                  </div>
                </>
              :
                <SharedDisorderSimpleText key={`${innerList.text[language].substring(0, 8)}d2${innerIndex}`}>
                  {innerList.text[language]}
                </SharedDisorderSimpleText>
            ))
          :
            (i['object']) ? 
              <SharedDisorderSimpleText key={`${i.text[language].substring(0, 8)}d2${index}`}>
                {/* {i.text[language]} */}
              </SharedDisorderSimpleText>
            :
              <SharedDisorderSimpleText key={`${i.text[language].substring(0, 8)}d2${index}`}>
                {i.text[language]}
              </SharedDisorderSimpleText>
      ))}
    </div>
    <a className="mt-2" onClick={e => { e.preventDefault();setShowMore(!showMore); window.dispatchEvent(new Event('resize'));
 }}>{showMore ? 'show less' : 'show more'}</a>
  </SharedDisorderBackground>
)
}