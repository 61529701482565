import React, { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { baseUrl, loginUrl } from "../../lib/constants"
import TopAlert from "../banners/topAlert"
import { AssessmentsHistoryWrapper } from "../assessmentHistory/AssessmentsHistoryWrapper"
import { StateContext } from "../../store/state/contexts"
import { Auth } from "../../api/Auth"
import { Route, Routes } from "react-router"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { Modal, Button, ListGroup, ButtonGroup } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ClientAccessId } from "../popups/accessCode/ClientAccessId"
import { logEvent } from "../../index"
import { useLocation } from "react-router";

const ENavbarButtonWrapper = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`

const EMobileNavbarButtonWrapper = styled.button`
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  span {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 641px) {
    display: none;
  }
`

const Elink = styled(Link)``

const NavbarMobal = (props) => {
  return (<>

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered6
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body
        css={css`
          display: flex;
          align-items: baseline;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 6px;
            margin: 1em 0;
          `}
        >
          <Link to="/assessment">
            <Button>Start Assessmentsssss</Button>
          </Link>

          <Button
            onClick={() => {
              props.onHide()
              props.openAssessmentsHistory(true)
            }}
          >
            Assessments
          </Button>

          <Button
            onClick={() => {
              props.changeLanguage()
              props.onHide()
            }}
          >
            {props.lang
              ? props.lang === "en"
                ? "French"
                : "English"
              : "English"}
          </Button>

          <Link
            to="/profile"
            css={css`
              > button {
                width: 100%;
              }
            `}
          >
            <Button>Profile</Button>
          </Link>

          <Button
            onClick={() => {
              props.logOut()
            }}
          >
            Logout
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

const Navbar = ({ params, changeLanguage, lang, from, t, i18n, disorderState }) => {
  const { state, dispatch } = useContext(StateContext);
  const [modalOpen, setModalOpen] = useState(false)
  const [navbarModalOpen, setNavbarModalOpen] = useState(false)

  const location = useLocation()

  const [showClientAccessPopUp, setShowClientAccessPopUp] = useState(false)
  const [hasClient, setHasClient] = useState(false)
  const checkClientID = async () => {
    const res = await fetch(baseUrl + "/user/accesscode", {
      credentials: "include",
      mode: "cors",
      method: "GET",
    })
    const assementCode = await res.json()
    if (!assementCode || (typeof assementCode === 'object' && Object.keys(assementCode).length === 0)) {
      logEvent("checkClientID /user/accesscode successful")
      setHasClient(false)
    } else {
      setHasClient(true)
      logEvent("checkClientID /user/accesscode failed")
    }
  }

  useEffect(() => {
    checkClientID()
  }, []);

  const logOut = () => {
    try {
      Auth.logout().then((res) => {
        dispatch({
          type: "HANDLE_AUTH_LOGOUT",
        })
        window.location.replace("/")
      })
    } catch (err) {
      console.log({ err })
    }
  }

  const handleClosePopup = () => {
    setShowClientAccessPopUp(false)
  }

  return (
    <>
      <ClientAccessId
        i18n={i18n}
        t={t}
        show={showClientAccessPopUp}
        handleClose={handleClosePopup}
        onHide={handleClosePopup}
      />
      <NavbarMobal
        show={navbarModalOpen}
        onHide={() => setNavbarModalOpen(false)}
        openAssessmentsHistory={setModalOpen}
        changeLanguage={changeLanguage}
        lang={lang}
        logOut={logOut}
      />
      <div className="navbar-top">
        <TopAlert disorderState={disorderState} params={params} />

        <div className={
          from === 'summary' ?
            "navbar-summary-page "
            : (
              from === 'assessment' ?
                'navbar-assessment-page main-container '
                :
                "container-fluid "
            )
            + " navbar-container"}>
          <nav className="navbar navbar-expand-lg hamb-icon">
            <div className="wow fadeInUp">
              <Link to="/">
                <img
                  src={require("../../assets/images/FeelingBetterNowLogo.svg").default}
                  alt=""
                  className="align-item-center img-fluid img-responsive inner-logo"
                />
              </Link>
            </div>
            <ENavbarButtonWrapper>
              <button
                className="navbar-toggler navbar-light"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </ENavbarButtonWrapper>
            <EMobileNavbarButtonWrapper
              onClick={() => setNavbarModalOpen(true)}
            >
              <span className="navbar-toggler-icon"></span>
            </EMobileNavbarButtonWrapper>
            <div
              className="collapse navbar-collapse justify-content-end hamburger-menu"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav assestment wow fadeInUp"
                style={{ fontSize: "20vw" }}
              >
                {location.pathname.includes("assessment") ? <></> :
                  <li className="active" tabIndex="0">
                    {hasClient ?
                      <Link to="/assessment" className="nav-link">
                        {t("navbar.start")}<span className="sr-only">(current)</span>
                      </Link>
                      :
                      <a className="nav-link" onClick={(e) => { e.preventDefault(); setShowClientAccessPopUp(true) }}>
                        {t("navbar.start")}<span className="sr-only">(current)</span>
                      </a>
                    }
                  </li>
                }

                <li className="nav-item" tabIndex="0">
                  <button
                    type="button"
                    className="btn assestmentNavBtn"
                    onClick={() => {
                      setModalOpen(true)
                    }}
                  >
                    {t("navbar.assessment")}
                  </button>
                </li>

                {
                  <Routes>
                    <Route exact path="/assessment" render={() => null} />
                    <Route
                      path="/"
                      render={() => (
                        <li className="nav-item" tabIndex="0">
                          <Link
                            to={`/Education/${params}`}
                            className="nav-link"
                          >
                            {t("navbar.education")}
                          </Link>
                        </li>
                      )}
                    />
                  </Routes>
                }

                <li className="nav-item" tabIndex="0">
                  <Link to="/profile" className="nav-link">
                    {t("navbar.profile")}
                  </Link>
                </li>

                <li className="nav-item" tabIndex="0">
                  <button
                    className="btn assestmentNavBtn"
                    onClick={changeLanguage}
                    style={{ boxShadow: "none" }}
                  >
                    {lang ? (lang === "en" ? "French" : "English") : "English"}
                  </button>
                </li>

                <li className="nav-item" tabIndex="0">
                  {state.auth.isLoggedIn ? (
                    <button
                      className="btn signinBtn assestmentNavBtn"
                      style={{
                        padding: "14px 38px",
                        fontSize: "14px",
                        color: "var(--cyan_800)",
                        backgroundColor: "var(--white)",
                        borderRadius: "5vw",
                      }}
                      onClick={logOut}
                    >
                      {t("navbar.logout")}
                    </button>
                  ) : (
                    <button
                      className="btn signinBtn"
                      onClick={() => window.open(loginUrl, "_self")}
                    >
                      Sign In/Up
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <AssessmentsHistoryWrapper
        open={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen)
        }}
        close={() => {
          setModalOpen(false)
        }}
      />
    </>
  )
}

export default withTranslation()(Navbar)