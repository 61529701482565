import { jsx, css } from "@emotion/react"
import logo from "../../../assets/images/FeelingBetterNowLogo.svg"
import closeX from "../../../assets/images/closeX.png"
// import { withTranslation } from "react-i18next"
import React, { useState } from "react"
import OutsideClickHandler from "react-outside-click-handler"
import { FormInput } from "../notificationScheduler/NotificationScheduler"
import "../../../css/NotificationScheduler.scss"
import { emailDetails } from "../../printable/PrintableDetails"
import { emailAssessment } from '../../../components/printable/PrintablePAP';

export const ShareAssessment = ({
  onClose,
  userData,
  detailsData,
  assessment_id,
  shareAssessmentHandler,
  t,
  i18n
}) => {
  const [email, setEmail] = useState("")

  // const t = t;
  
  const [isEmailValid, setEmailIsValid] = useState(undefined)
  const [isDoctorEmailValid, setIsDoctorEmailValid] = useState(undefined)

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleShareSend = async () => {
    if(!email || !validateEmail(email)) {
      return setEmailIsValid(false)
    }
    emailAssessment({
      userData,
      email,
      assessment_id,
      detailsData,
    })
    // emailDetails({ userData: userData, email: email, assessment_id })
    onClose()
  }

  return (
    <>
      <div className="modal-background" />

      <div className="modal-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => {
            onClose()
          }}
        >
          <div
            className={"modal-form"}
           
            style={{
              border:
                isEmailValid === false || isDoctorEmailValid === false
                  ? "2px solid red"
                  : "none",
            }}
          >
            <div className="logo-line">
              <img src={logo} className="logo" alt="" />
              <img
                src={closeX}
                className="close-btn-send-action-plan"
                onClick={onClose}
                alt=""
              />
            </div>
            <div className="modal-scroll">
              {/* <img src={shareActionPlan} className="share-action-plan"/> */}
              <h2 className="share-action-plan">
                {/* Share Assessment Details */}
                {t("summaryPage.shareAssessmentModal.title")}
              </h2>
              <p className="words-above-box">
                {t("summaryPage.shareAssessmentModal.text")}
                {/* You can share the individual responses to your assessment with your doctor or mental health care practioner */}
              </p>

              <form className="modal-form-wrapper" onSubmit={(e) => {
                e.preventDefault();
                handleShareSend();
              }}>
                <FormInput
                  type="text"
                  caption= {t("summaryPage.shareAssessmentModal.placeHolder")}
                  value={email}
                  onChange={setEmail}
                />

                <button
                  className="send-button-top"
                  style={{  }}
                  type="submit"
                  onClick={() => {
                    handleShareSend()
                  }}
                >
                  {t("summaryPage.shareAssessmentModal.sendButton")}
                </button>
              </form>

              {isEmailValid === false && (
                <p style={{color: 'red'}}>
                  {t("summaryPage.shareAssessmentModal.validationMsg")}
                </p>
              )}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

const styles = {
  form: {
    paddingTop: 100,
    paddingBottom: 120,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
  },
  button: {
    padding: "2 10",
  },
}

export default ShareAssessment
