import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { Description } from "./Description"
import { ResourceDescription } from './ResourceDescription'
import Medication from "./Medication"
import { DisorderList } from "./DisorderList"
import MagicGrid from "magic-grid-react"
import { withTranslation } from "react-i18next"

const DisorderModule = ({
  description,
  medication,
  name,
  questions,
  riskStatus,
  score,
  support,
  symptomes,
  therapy,
  tips,
  t,
  lang,
  handleTherapyModalClick,
  resources
}) => {
  let gridItems = []
  if (description) {
    gridItems = [...gridItems, { descriptionFlag: true }]
  }

  let resourcesFlag = false
  if(riskStatus === "Low Risk" && support && support.length > 0){
    resourcesFlag = true
    gridItems = [...gridItems, { list: support.map(res => ({ text: res.title, link: res.link })), caption: t("summaryPageDisorder.support") }]
  }

  // if ((riskStatus === "High Risk" || riskStatus === "Moderate Risk") && !!symptomes) {
  //   gridItems = [...gridItems, { list: symptomes || [], caption: "Symptoms" }]
  // }

  if ((riskStatus === "High Risk" || riskStatus === "Moderate Risk") && !!support) {
    gridItems = [...gridItems, { list: support.map(res => ({ text: res.title, link: res.link })), caption: t("summaryPageDisorder.support") }]
  }

  if ((riskStatus === "High Risk" || riskStatus === "Moderate Risk" || riskStatus === "Low Risk") && !!therapy && therapy.length > 0) {
    gridItems = [...gridItems, { list: therapy || [], caption: t("summaryPageDisorder.therapy") }]
  }

  // if ((riskStatus === "High Risk" || riskStatus === "Moderate Risk") && !!tips) {
  //   gridItems = [...gridItems, { list: tips || [], caption: "Self Care" }]
  // }
  return (
    <div>
      <MagicGrid
        maxColumns={2}
        gutter={16}
        items={gridItems.length}
        useMin={true}
        animate={false}
        useTransform={false}
      >
        {gridItems.map((i, key) => {
          if (i.descriptionFlag) {
            return (
              <Description
                lang={lang}
                description={description}
                riskStatus={riskStatus}
                key={`disorder${name + key}listitem`}
              />
            )
          } 
          else {
            return (
              <DisorderList
                list={i.list}
                caption={i.caption}
                key={`${i.caption}${name + key}listitem`}
                lang={lang}
                handleTherapyModalClick={handleTherapyModalClick}
              />
            )
          }
        })}
        {/* {
          (riskStatus === "Low Risk" && resources && resources.map((res, id) => {
              return (
                <ResourceDescription
                  lang={lang}
                  description={res.description}
                  riskStatus={riskStatus}
                  title={res.title}
                  link={res.link}
                  key={`disorder-res${name + id + ''}listitem`}
                />
              )
            })) || []
        } */}
      </MagicGrid>

      {medication &&
        medication.length > 0 &&
        riskStatus === "High Risk" &&
        name !== "Eating Disorder" && (
        <Medication
          medication={medication}
          riskStatus={riskStatus}
          name={name}
          lang={lang}
        />
      )}
    </div>
  )
}
export const Disorder = withTranslation()(DisorderModule);
